import React, { FC, memo } from "react";
import classnames from "classnames/bind";
import styles from "./ButtonNavigation.module.scss";

const cx = classnames.bind(styles);

export interface ButtonNavigationProps {
  items?: any[];
  align?: "left" | "center";
  className?: string;
  children: React.ReactNode;
}

const ButtonNavigation: FC<ButtonNavigationProps> = memo(
  ({ align = "center", children, className }) => {
    return (
      <div
        className={cx(
          styles.categoryNav,
          {
            left: align === "left",
            center: align === "center",
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);

export { ButtonNavigation };

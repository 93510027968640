/**
 * Barrel of renderings
 */
const Renderings: any = {};

// specific for detail pages
// specific for library pages
Renderings.activities = require("./Activities/Activities").default;
Renderings.activityBreakdowns =
  require("./ActivityBreakdowns/ActivityBreakdowns").default;
Renderings.callToActionBanner =
  require("./CallToActionBanner/CallToActionBanner").default;
Renderings.callToActionImagesGrid =
  require("./CallToActionImagesGrid/CallToActionImagesGrid").default;
Renderings.faq = require("./FAQ/FAQ").default;
Renderings.genericTexTAndImage =
  require("./GenericTextAndImage/GenericTextAndImage").default;
Renderings.introComponent = require("./IntroComponent/IntroComponent").default;
Renderings.jumbotron = require("./Jumbotron/Jumbotron").default;
Renderings.jumbotronHome = require("./JumbotronHome/JumbotronHome").default;
Renderings.learningOutcomes =
  require("./LearningOutcomes/LearningOutcomes").default;
Renderings.partners = require("./Partners/Partners").default;
Renderings.qualifications = require("./Qualifications/Qualifications").default;
Renderings.curriculumAreas =
  require("./Qualifications/QualificationsContentful").default;
Renderings.resourceHub = require("./ResourceHub/ResourceHub").default;
Renderings.resourcePacks = require("./ResourcePacks/ResourcePacks").default;
Renderings.resourcesList = require("./ResourcesList/ResourcesList").default;
Renderings.supportComponent =
  require("./SupportComponent/SupportComponent").default;
Renderings.testimonials = require("./Testimonials/Testimonials").default;
Renderings.textPanel = require("./TextPanel/TextPanel").default;
Renderings.signUp = require("./SignUp/SignUp").default;
Renderings.trackingSheets = require("./TrackingSheets/TrackingSheets").default;
Renderings.whyChooseAchieve =
  require("./WhyChooseAchieve/WhyChooseAchieve").default;
Renderings.regionalComponent =
  require("./RegionalComponent/RegionalComponent").default;
Renderings.caseStudyVideo = require("./CaseStudyVideo/CaseStudyVideo").default;
Renderings.video = require("./Video/Video").default;
Renderings.featuredResources =
  require("./FeaturedResources/FeaturedResources").default;

export default Renderings;

import React, { FC, memo } from "react";
import { Typography } from "@material-ui/core";
import { Variants, TypographyCustomProps } from "@app/types/typographyTypes";
import classnames from "classnames/bind";
import styles from "./Text.module.scss";

const cx = classnames.bind(styles);

export interface TextProps extends TypographyCustomProps {
  lead?: boolean;
  small?: boolean;
  large?: boolean;
  strong?: boolean;
  faded?: boolean;
}
const Text: FC<TextProps> = memo(
  ({
    lead,
    small,
    large,
    strong,
    faded,
    tag,
    children,
    className,
    ...rest
  }) => {
    let variant: Variants = tag !== "label" ? tag : undefined;
    if (lead) variant = "subtitle1";
    if (large) variant = "subtitle2";

    return (
      <Typography
        {...rest}
        variant={variant}
        className={cx(
          styles.text,
          {
            lead,
            small,
            strong,
            faded,
          },
          className
        )}
        component={tag}
      >
        {children}
      </Typography>
    );
  }
);

export { Text };

import React, { FC, memo } from "react";
import { CenterColumn } from "@app/components";
import classnames from "classnames/bind";
import styles from "./Container.module.scss";

const cx = classnames.bind(styles);

interface ContainerProps extends React.HTMLAttributes<HTMLElement> {
  fullwidth?: boolean;
  /**
   * @indentStep number; 1 | 2 | 3;
   * determine how large the containers center column should be, 1 is largest.
   */
  indentStep?: number;
  tag?: "div" | "section";
  noOverflow?: boolean;
}

const Container: FC<ContainerProps> = memo(
  ({
    className,
    children,
    indentStep = 1,
    fullwidth,
    tag = "div",
    noOverflow,
  }) => {
    const SectionElement = `${tag}` as React.ElementType;

    const RenderInner = () =>
      fullwidth ? (
        <>{children}</>
      ) : (
        <CenterColumn indentStep={indentStep}>{children}</CenterColumn>
      );

    return (
      <SectionElement
        className={cx(
          {
            [`indent${indentStep}`]: !fullwidth,
            noOverflow,
          },
          className
        )}
      >
        <RenderInner />
      </SectionElement>
    );
  }
);

export { Container };

import React, { FC, memo } from "react";
import { Section, Container, Heading, MediaWrapper } from "@app/components";
import { Grid } from "@material-ui/core";
import styles from "./Partners.module.scss";
import Partner, { PartnerProps } from "./components/Partner/Partner";

export interface PartnersProps {
  title: string;
  isDetailedView?: boolean;
  partners: PartnerProps[];
}

const Partners: FC<PartnersProps> = memo(
  ({ title, isDetailedView, partners = [] }) => {
    return (
      <Section className={styles.section} background fullWidth>
        <Container indentStep={3}>
          {!!title && (
            <Heading size="h2" align="center" className={styles.title}>
              {title}
            </Heading>
          )}
          {partners.length > 0 &&
            (isDetailedView ? (
              partners.map(item => (
                <Grid
                  className={styles.row}
                  container
                  justify="center"
                  alignItems="center"
                  key={item?.sys?.id}
                >
                  <Partner {...item} />
                </Grid>
              ))
            ) : (
              <div className={styles.logosWrapper}>
                {partners.map(item => (
                  <div className={styles.logo} key={item.fields.name}>
                    <MediaWrapper cfImage={item.fields.logoImage} natural />
                  </div>
                ))}
              </div>
            ))}
        </Container>
      </Section>
    );
  }
);

export default Partners;

import React, { memo } from "react";

const SvgBook = memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 38V11M24 38C24 38 20.1242 35.781 17 35C13.8758 34.219 8 34 8 34V7C8 7 13.8758 7.21895 17 8C20.1242 8.78105 24 11 24 11M24 38C24 38 27.8758 35.781 31 35C34.1242 34.219 40 34 40 34V7C40 7 34.1242 7.21895 31 8C27.8758 8.78105 24 11 24 11"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M4 10V37C4 37 11.0948 37.5237 15 38.5C18.9052 39.4763 24 42 24 42C24 42 29.0948 39.4763 33 38.5C36.9052 37.5237 44 37 44 37V10"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  );
});

export default SvgBook;

import React, { FC, memo, useState, useEffect } from "react";
import { Text } from "@app/components";
import { IconFile } from "@app/components/atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import styles from "./FileLink.module.scss";

interface FileLinkProps {
  file: { url: string; fileName: string };
  title?: string;
  isAuthenticated?: boolean;
  onClick?: () => void;
  isMosaicLibrary?: boolean;
}

const FileLink: FC<FileLinkProps> = memo(
  ({ file, title, isAuthenticated, onClick, isMosaicLibrary }) => {
    const { t } = useTranslation();

    const content = (
      <Text large title={title} className={styles.title}>
        <IconFile className={styles.icon} />
        {file.fileName}
      </Text>
    );

    const storedCustomAttributes = JSON.parse(
      localStorage.getItem("customAttributes") || "{}"
    );

    const [isDisabled, setIsDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      const handleCheck = () => {
        const disabledCondition =
          storedCustomAttributes.mosaic && storedCustomAttributes.achieve
            ? false
            : (isMosaicLibrary && !storedCustomAttributes.mosaic) ||
              (!isMosaicLibrary && !storedCustomAttributes.achieve);

        setIsDisabled(disabledCondition);
      };

      handleCheck();
    }, [storedCustomAttributes, isMosaicLibrary]);

    if (!isAuthenticated) {
      return (
        <div
          tabIndex={-1}
          role="button"
          aria-pressed="false"
          onClick={onClick}
          onKeyPress={onClick}
          className={styles.downloadLink}
        >
          {content}
        </div>
      );
    }

    const onHandler = (
      event: React.MouseEvent<HTMLButtonElement>,
      url: string
    ) => {
      if (!isDisabled) {
        event.stopPropagation();
        window.open(url);
      } else {
        setShowModal(true);
      }
    };

    const closeModal = () => {
      setShowModal(false);
    };

    const handleModalKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    return (
      <>
        <div className={styles.wrapper}>
          <button
            className={styles.downloadLink}
            onClick={event => onHandler(event, file.url)}
          >
            {content}
          </button>
        </div>
        {showModal && (
          <div
            className={styles.customModalBackdrop}
            onClick={closeModal}
            onKeyDown={handleModalKeyDown}
            role="button"
            tabIndex={0}
          >
            <div
              className={styles.customModal}
              onClick={e => e.stopPropagation()}
              onKeyDown={() => {
                /* Intentionally empty */
              }}
              role="button"
              tabIndex={0}
            >
              <p>{t("components.documentsRestrictedAccess")}</p>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default FileLink;

import React, { FC, memo } from "react";
import cx from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./Image.module.scss";

interface ImgProps extends React.HTMLAttributes<HTMLImageElement> {
  cfImage?: ContentfulImage;
  alt?: string; // TODO: use image attributes
  title?: string;
  src?: string;
  className?: string;
  activeLoad?: boolean;
  visibleByDefault?: boolean;
  loadEffectInWrapper?: boolean;
  maxWidth?: number;
}

// can be optimized with blur up functionality, requires a low quality image as src in combi with lazyload and data-src

const Image: FC<ImgProps> = memo(
  ({
    cfImage,
    alt,
    title,
    src,
    className,
    activeLoad = false,
    visibleByDefault,
    loadEffectInWrapper,
    maxWidth = 1400,
  }) => {
    /**
     * map properties from contentFul image to new object.
     * Properties like alt and title set directly on the component
     * will override output from contentful
     * If image is from other source or used in a different way,
     * then use direct props.
     * */
    const imgProps = cfImage
      ? {
          alt: alt || cfImage.fields.description || "",
          // title: title || cfImage.fields.title,
          src: cfImage.fields.file.url,
        }
      : {
          alt: alt || title || src || "",
          // title: title || alt,
          src,
        };

    return !activeLoad ? (
      <LazyLoadImage
        {...imgProps}
        src={`${imgProps.src}?q=80&w=${maxWidth}`}
        className={cx(className, styles.image)}
        visibleByDefault={visibleByDefault}
        effect="blur"
        wrapperClassName={loadEffectInWrapper ? styles.imageEffect : undefined}
        threshold={100}
        delayTime={200}
        placeholderSrc={`${imgProps.src}?w=200`}
      />
    ) : (
      <img
        alt={imgProps.alt}
        className={cx(className, styles.image)}
        src={imgProps.src}
        // title={imgProps.title}
      />
    );
  }
);

export { Image };

import * as React from "react";

function SvgFitnessDumbbell(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M95.188 6.936a8.75 8.75 0 000 12.372l25.498 25.498a8.75 8.75 0 0012.378-12.373L107.561 6.936a8.75 8.75 0 00-12.373 0z"
          strokeWidth={8.749995}
        />
        <path
          d="M70.437 6.93a8.75 8.75 0 000 12.378l50.255 50.254a8.757 8.757 0 1012.372-12.395L82.834 6.93a8.75 8.75 0 00-12.397 0zM6.936 95.188a8.75 8.75 0 0112.378 0l25.498 25.498a8.75 8.75 0 01-12.373 12.372L6.936 107.561a8.75 8.75 0 010-12.373z"
          strokeWidth={8.749995}
        />
        <path
          d="M6.936 70.432a8.75 8.75 0 0112.372 0l50.254 50.254a8.759 8.759 0 01-12.395 12.378L6.936 82.834a8.75 8.75 0 010-12.402z"
          strokeWidth={8.749995}
        />
        <path
          d="M38.247 89.374l51.126-51.127 12.375 12.375-51.127 51.127z"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgFitnessDumbbell = React.memo(SvgFitnessDumbbell);
export default MemoSvgFitnessDumbbell;

import React, { memo } from "react";

/* "Pen" is referenced as "Activity" in Figma / system */

const SvgPen = memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11 2L11.3536 1.64645C11.1583 1.45118 10.8417 1.45118 10.6464 1.64645L11 2ZM13 4L13.3536 4.35355C13.5488 4.15829 13.5488 3.84171 13.3536 3.64645L13 4ZM6 11V11.5C6.13261 11.5 6.25979 11.4473 6.35355 11.3536L6 11ZM4 11H3.5C3.5 11.2761 3.72386 11.5 4 11.5V11ZM4 9L3.64645 8.64645C3.55268 8.74021 3.5 8.86739 3.5 9H4ZM2 12.5C1.72386 12.5 1.5 12.7239 1.5 13C1.5 13.2761 1.72386 13.5 2 13.5V12.5ZM14 13.5C14.2761 13.5 14.5 13.2761 14.5 13C14.5 12.7239 14.2761 12.5 14 12.5V13.5ZM10.6464 2.35355L12.6464 4.35355L13.3536 3.64645L11.3536 1.64645L10.6464 2.35355ZM12.6464 3.64645L5.64645 10.6464L6.35355 11.3536L13.3536 4.35355L12.6464 3.64645ZM6 10.5H4V11.5H6V10.5ZM4.5 11V9H3.5V11H4.5ZM4.35355 9.35355L11.3536 2.35355L10.6464 1.64645L3.64645 8.64645L4.35355 9.35355ZM2 13.5H14V12.5H2V13.5Z"
      />
    </svg>
  );
});

export default SvgPen;

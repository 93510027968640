import { createClient } from "contentful";
import { ENV } from "./contentful.config";

const client = createClient({
  space: ENV.SPACE_ID,
  accessToken: ENV.ACCESS_TOKEN,
  environment: ENV.ENVIRONMENT,
});

export const getEntry = (entryId: string, query?: unknown) => {
  return client.getEntry(entryId, query);
};

export const getEntriesByContentModel = (
  contentType: string,
  locale: string
) => {
  return client.getEntries({
    // eslint-disable-next-line
    content_type: contentType,
    locale,
  });
};

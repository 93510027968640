import React, { FC, ReactNode, memo } from "react";
import classnames from "classnames/bind";
import styles from "./CenterColumn.module.scss";

const cx = classnames.bind(styles);

export interface ColumnProps {
  indentStep?: number;
  className?: string;
  fullWidth?: boolean;
  children?: ReactNode;
}

const CenterColumn: FC<ColumnProps> = memo(
  ({ children, indentStep = 1, className, fullWidth }) => {
    return (
      <div
        className={cx(
          styles.centerColumn,
          {
            [`indent${indentStep}`]: !fullWidth,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);

export { CenterColumn };

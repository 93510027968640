import { useFetchSettings } from "@app/features/contentful/contentful";
import { useEffect, useState } from "react";
import {
  getLocale,
  getTranslation,
  setLocalization,
  updateLocalization,
} from "../helpers/localization.helpers";
import { LocalesEnum } from "../localization";

function useInitLocalization(currentLocale?: string) {
  const [loading, setLoading] = useState(true);
  const { settings, loading: settingsLoading } = useFetchSettings();
  const locale = getLocale();
  // FIRST
  // Set localization if already stored
  useEffect(() => {
    if (locale) {
      const existingLocalization = getTranslation();
      if (existingLocalization) {
        setLocalization(locale, existingLocalization);
        setLoading(false);
      }
    }
  }, [locale]);

  // SECOND
  // While setting existing localization
  // the newest localization is fetched in background
  // and added to i18next when done
  useEffect(() => {
    if (settings) {
      // TODO: Add to localStorage and only fetch when new localization is available
      updateLocalization(currentLocale ?? LocalesEnum.ENGLISH, settings); // TODO: en-gb?
    }
  }, [currentLocale, settings]);

  return { loading: locale != null ? loading : settingsLoading };
}

export default useInitLocalization;

import React, { FC, memo } from "react";
import { Section, Container, Heading, Text, Button } from "@app/components";
import { Grid } from "@material-ui/core";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { IconBoxAndText } from "@app/components/molecules/molecules";
import _kebabCase from "lodash/kebabCase";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import styles from "./CallToActionImagesGrid.module.scss";
import ImagesGrid from "./components/ImagesGrid/ImagesGrid";
import ImagesCarousel from "./components/ImagesCarousel/ImagesCarousel";

interface ItemDef {
  fields: {
    icon: string;
    title: string;
    description: string;
  };
  sys?: { id: string };
}

export interface CallToActionImagesGridProps {
  title: string;
  description: string;
  ctaLabel: string;
  ctaLink: string;
  items: ItemDef[];
  isPdfPreview?: boolean;
  hideForAuthenticated?: boolean;
  images: { fields: { componentName: string; image: ContentfulImage } }[];
}

const CallToActionImagesGrid: FC<CallToActionImagesGridProps> = memo(
  ({
    title,
    description,
    images = [],
    items = [],
    ctaLabel,
    ctaLink,
    isPdfPreview,
    hideForAuthenticated,
  }) => {
    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    return isAuthenticated && hideForAuthenticated ? null : (
      <Section background fullWidth className={styles.section}>
        {isPdfPreview ? null : <div className={styles.bottomBorder} />}
        <Container indentStep={isPdfPreview ? 1 : 3}>
          <Grid
            container
            spacing={3}
            direction={isPdfPreview ? "row-reverse" : "row"}
            justify="space-between"
          >
            <Grid item xs={12} md={5}>
              {!!title && <Heading size="h2">{title}</Heading>}
              {!!description && <Text>{description}</Text>}
              {items.length > 0 && (
                <div className={styles.itemsWrapper}>
                  {items.map(item => (
                    <IconBoxAndText
                      key={item.sys?.id}
                      className={styles.boxWrapper}
                      title={item.fields.title}
                      description={item.fields.description}
                      icon={item.fields.icon}
                      iconBoxSize={72}
                      smallTitle
                    />
                  ))}
                </div>
              )}
              {!!ctaLabel && !!ctaLink && (
                <Button label={ctaLabel} to={ctaLink} arrow tabIndex={-1} />
              )}
            </Grid>
            <Grid item xs={12} md={isPdfPreview ? 6 : 7}>
              {isPdfPreview ? (
                <ImagesCarousel title={_kebabCase(title)} images={images} />
              ) : (
                <ImagesGrid images={images} />
              )}
            </Grid>
          </Grid>
        </Container>
      </Section>
    );
  }
);

export default CallToActionImagesGrid;

import React from "react";
import { Section, Container } from "@app/components";
import { useLocation } from "react-router-dom";

const NoMatch = () => {
  const location = useLocation();

  return (
    <Section>
      <Container>
        <h3>
          <br />
          No match for <code>{location.pathname}</code>
        </h3>
      </Container>
    </Section>
  );
};

export default NoMatch;

import React, { FC, useEffect } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import Routes from "@app/routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import { createBaseMuiTheme } from "@app/helpers/muiThemeHelper";
import { Notification } from "@app/features/notification/notification";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useMount } from "react-use";
import CookieConsent from "react-cookie-consent";
import { getTokens, getUser, useGetAuthConfig } from "./features/auth/auth";
import { RootState } from "./redux/rootReducer";
import { getLocalization } from "./features/localization/localization";
import { FullscreenLayout } from "./components/layouts/FullscreenLayout/FullscreenLayout";
import { LoadingSpinner } from "./components/atoms/atoms";
import { updateLocalization } from "./features/localization/helpers/localization.helpers";

const App: FC = () => {
  const theme = createBaseMuiTheme();

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const { t } = useTranslation();

  const {
    locale,
    localization: localizationData,
    loading: localizationLoading,
  } = useSelector((state: RootState) => state.localization);

  const dispatch = useDispatch();
  const { accessToken } = getTokens();

  const history = useHistory();

  useMount(() => {
    dispatch(getLocalization(locale));
    if (accessToken) {
      dispatch(getUser());
    }
  });

  useEffect(() => {
    if (localizationData) updateLocalization(locale, localizationData);
  }, [locale, localizationData, localizationLoading]);

  const { configFromService } = useGetAuthConfig();

  if (!configFromService) return null;

  const { clientId, issuer } = configFromService;

  const oktaAuth = new OktaAuth({
    clientId,
    issuer,
    pkce: true,
    services: {
      autoRenew: true,
    },
  });

  // Show loading while getting user or while localization is loading
  if ((accessToken && !isAuthenticated) || localizationLoading) {
    return (
      <FullscreenLayout>
        <LoadingSpinner isFullscreen />
      </FullscreenLayout>
    );
  }

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
          <CookieConsent
            location="bottom"
            buttonText={t("components.cookieCloseButton")}
            cookieName="cookiesConsent"
            style={{ background: "#1e1964" }}
            buttonStyle={{
              color: "#ffffff",
              fontSize: "14px",
              background: "#b0073a",
            }}
          >
            {t("components.cookieText")}
          </CookieConsent>
          <Routes />
        </Security>
      </Router>
      <Notification />
    </ThemeProvider>
  );
};

export default App;

/* eslint-disable */
import {
  Section,
  Container,
  Heading,
  Text,
  MediaWrapper,
} from "@app/components";
import React, { memo, useRef, useEffect } from "react";
import { ReactComponent as CornerDraw } from "@app/assets/images/corner-alt.svg";
import { Grid } from "@material-ui/core";
import {
  SwiperBreakpointsDef,
  SwiperCarousel,
  SwiperSlide,
} from "@app/components/molecules/SwiperCarousel/SwiperCarousel";
import { ScreenSizes } from "@app/constants/breakpoints";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./CaseStudyVideo.module.scss";

interface SlidesProps {
  fields: {
    title: string;
    subtitle?: string;
    videoLink?: string;
    image?: ContentfulImage;
  };
  sys: {
    id: string;
  };
}
interface CaseStudyVideoProps {
  slides?: SlidesProps[];
}

const CaseStudyVideo = ({ slides = [] }: CaseStudyVideoProps) => {
  const swiperBreakpoints: SwiperBreakpointsDef = {
    [ScreenSizes.XS]: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const setupYouTubePlayer = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      }
    };

    window.addEventListener("beforeunload", setupYouTubePlayer);

    return () => {
      window.removeEventListener("beforeunload", setupYouTubePlayer);
    };
  }, []);

  return (
    <Section fullWidth className={styles.section}>
      <CornerDraw className={styles.corner} />
      <Container indentStep={3} className={styles.swiperWrapper}>
        <SwiperCarousel
          swiperBreakpoints={swiperBreakpoints}
          enableLoop={false}
          showNavArrows={slides.length > 1}
          showPagination={slides.length > 1}
          spaceBetween={30}
          navBtnsClassName={styles.navBtnsWrapper}
        >
          {slides.length > 0 &&
            slides.map(slide => (
              <SwiperSlide key={slide?.sys?.id}>
                <Grid container>
                  <Grid item xs={12}>
                    {slide?.fields?.image ? (
                      <MediaWrapper cfImage={slide.fields.image} natural />
                    ) : (
                      <>
                        {!!slide.fields?.videoLink && (
                          <div className={styles.videoWrapper}>
                            <iframe
                              ref={iframeRef}
                              className={styles.iframe}
                              title={slide.fields?.title}
                              src={slide.fields.videoLink}
                              loading="lazy"
                              scrolling="no"
                              allow="autoplay; encrypted-media"
                              allowFullScreen
                              frameBorder="0"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    {!!slide.fields?.title && (
                      <Heading className={styles.title} size="h2">
                        {slide.fields.title}
                      </Heading>
                    )}
                    {!!slide.fields?.subtitle && (
                      <Text className={styles.subtitle}>
                        {slide.fields.subtitle}
                      </Text>
                    )}
                  </Grid>
                </Grid>
              </SwiperSlide>
            ))}
        </SwiperCarousel>
      </Container>
    </Section>
  );
};

export default memo(CaseStudyVideo);

import React, { FC, memo, useState, useCallback } from "react";
import { Section, Container } from "@app/components";
import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RootState } from "@app/redux/rootReducer";
import { useTranslation } from "react-i18next";
import { ReactComponent as AnniversaryBadge } from "@app/assets/images/anniversary_badge_desktop.svg";
import MobileBadge from "@app/assets/images/anniversary_badge_mobile.png";
import styles from "./NavigationMain.module.scss";
import NavigationLogo from "./components/NavigationLogo/NavigationLogo";
import NavigationLinks from "./components/NavigationLinks/NavigationLinks";
import NavigationMobile from "./components/NavigationMobile/NavigationMobile";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import NavigationProfile from "./components/NavigationProfile/NavigationProfile";
import NavigationButtons from "./components/NavigationButtons/NavigationButtons";
import DropdownMenu from "./components/DropdownMenu/DropdownMenu";

export interface NavigationProps {
  title?: string;
  description?: string;
}
/**
 * Navigation in header
 * TODO:
 * - use contentful settings for login/signup texts on links and button
 * - (consider) refactor navbar and profile (or maybe just link items so they can have their class) into separate components
 * -
 */

const NavigationMain: FC<NavigationProps> = memo(() => {
  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const toggleMenu = useCallback(() => {
    setMobileMenuOpen(prevMobileMenuOpen => !prevMobileMenuOpen);
  }, []);

  const navItems = [
    {
      name: t("components.navigationQualificationHubLabel"),
      path: t("components.navigationQualificationHubLink"),
      isPrivate: true,
    },
  ];

  const dropdownData = [
    {
      sys: { id: "dropdown-1-id" },
      fields: {
        name: t("components.navigationProgramsLabel"),
        subpages: [
          {
            sys: { id: "1" },
            fields: {
              name: t("components.navigationProgramsEnterpriseChallengeLabel"),
              path: "/programs/enterprise-challenge",
              isPrivate: false,
            },
          },
          {
            sys: { id: "2" },
            fields: {
              name: t("components.navigationProgramsAchieveLabel"),
              path: "/programs/achieve",
              isPrivate: false,
            },
          },
          {
            sys: { id: "3" },
            fields: {
              name: t("components.navigationProgramsMosaicLabel"),
              path: "/programs/mosaic",
              isPrivate: false,
            },
          },
        ],
      },
    },
    {
      sys: { id: "dropdown-2-id" },
      fields: {
        name: t("components.navigationBrowseLibraryLabel"),
        subpages: [
          {
            sys: { id: "1" },
            fields: {
              name: t("components.navigationSubpageEnterpriseChallengeLabel"),
              path: "/enterprise-challenge-library",
              isPrivate: false,
            },
          },
          {
            sys: { id: "2" },
            fields: {
              name: t("components.navigationSubpageAchieveLabel"),
              path: ContentfulPathsEnum.LIBRARY,
              isPrivate: false,
            },
          },
          {
            sys: { id: "3" },
            fields: {
              name: t("components.navigationSubpageMosaicLabel"),
              path: ContentfulPathsEnum.MOSAIC_LIBRARY,
              isPrivate: false,
            },
          },
        ],
      },
    },
  ];

  const anniversaryPageLink = ContentfulPathsEnum.ANNIVERSARY_PAGE; // Define the anniversary page link

  return (
    <>
      <header className={styles.navigationMain}>
        <a href={anniversaryPageLink} style={{ display: "block" }}>
          {matchesDesktop ? (
            <AnniversaryBadge style={{ width: "100%" }} />
          ) : (
            <img
              src={MobileBadge}
              alt="Mobile Badge"
              style={{ width: "100%", display: "block" }}
            />
          )}
        </a>
        <Section flush>
          <Container>
            <div className={styles.row} role="navigation">
              <NavigationLogo />
              {matchesDesktop && (
                <>
                  <NavigationLinks navLinks={navItems} />
                  {dropdownData.map(dropdown => (
                    <DropdownMenu key={dropdown.sys.id} dropdown={dropdown} />
                  ))}

                  {isAuthenticated ? (
                    <NavigationProfile />
                  ) : (
                    <NavigationButtons />
                  )}
                </>
              )}
              {!matchesDesktop && (
                <NavigationMobile
                  menuOpen={mobileMenuOpen}
                  toggleMenu={toggleMenu}
                />
              )}
            </div>
          </Container>
        </Section>
      </header>

      {!matchesDesktop && (
        <MobileMenu
          isVisible={mobileMenuOpen}
          toggleMenu={toggleMenu}
          dropdowns={dropdownData}
          navLinks={navItems}
        />
      )}
    </>
  );
});

export default NavigationMain;

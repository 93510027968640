import * as React from "react";

function SvgDropdown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 5L5 -4.37114e-07L0 5L10 5Z" fill="currentColor" />
    </svg>
  );
}

const MemoSvgDropdown = React.memo(SvgDropdown);
export default MemoSvgDropdown;

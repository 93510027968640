import { RouteItemDef } from "@app/types/routeDef";
import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
// import { determineContentfulPageKey } from "@app/helpers/router.helpers";
import TemplateScreen from "../screens/TemplateScreen/TemplateScreen";
import DetailScreen from "../screens/DetailScreen/DetailScreen";

const HOME_SCREEN: RouteItemDef = {
  id: "home",
  path: ContentfulPathsEnum.HOME,
  component: TemplateScreen,
  contentfulPageKey: "3jCgqAawOYQMB9L5J9B83E",
};

const COLLECTIONS_DETAIL_SCREEN: RouteItemDef = {
  id: "collectionsDetail",
  path: `${ContentfulPathsEnum.COLLECTIONS_DETAIL}/:id`,
  component: DetailScreen,
};

const UNIT_DETAIL_SCREEN: RouteItemDef = {
  id: "unitsDetail",
  path: `${ContentfulPathsEnum.UNIT_DETAIL}/:id`,
  component: DetailScreen,
};

const ACTIVITY_DETAIL_SCREEN: RouteItemDef = {
  id: "activityDetail",
  path: `${ContentfulPathsEnum.ACTIVITY_DETAIL}/:id`,
  component: DetailScreen,
};

const HOW_ACHIEVE_WORKS_SCREEN: RouteItemDef = {
  id: "howAchieveWorks",
  path: ContentfulPathsEnum.HOW_ACHIEVE_WORKS,
  component: TemplateScreen,
  contentfulPageKey: "2QqZ9jovC9UhxEhwQ19WfP",
};

const FUNDERS: RouteItemDef = {
  id: "funders",
  path: ContentfulPathsEnum.FUNDERS,
  component: TemplateScreen,
  contentfulPageKey: "5vbS8rlJMt4iNTjeSCKPKX",
};

const ENGLAND: RouteItemDef = {
  id: "england",
  path: ContentfulPathsEnum.ENGLAND,
  component: TemplateScreen,
  contentfulPageKey: "2y7UDsjo7RY9RlNMrRo9ZT",
};

const SCOTLAND: RouteItemDef = {
  id: "scotland",
  path: ContentfulPathsEnum.SCOTLAND,
  component: TemplateScreen,
  contentfulPageKey: "7HiSQoLcXkddlVOLOl15e9",
};

const WALES: RouteItemDef = {
  id: "wales",
  path: ContentfulPathsEnum.WALES,
  component: TemplateScreen,
  contentfulPageKey: "552zdylx4eQoD8qxxH7Dm",
};

const NORTHERN_IRELAND: RouteItemDef = {
  id: "northernIreland",
  path: ContentfulPathsEnum.NORTHERN_IRELAND,
  component: TemplateScreen,
  contentfulPageKey: "4LpqGxdDPW83qfrnB9bJN4",
};

const FAQ: RouteItemDef = {
  id: "faq",
  path: ContentfulPathsEnum.FAQ,
  component: TemplateScreen,
  contentfulPageKey: "5ZSo8evdQtCRUkF7TYNX5d",
};

const TERMS_AND_CONDITIONS: RouteItemDef = {
  id: "TermsAndConditions",
  path: ContentfulPathsEnum.TERMS_AND_CONDITIONS,
  component: TemplateScreen,
  contentfulPageKey: "26SFCzKyWRm2C1QiCiSZTz",
};

const PRIVACY_POLICY: RouteItemDef = {
  id: "PrivacyPolicy",
  path: ContentfulPathsEnum.PRIVACY_POLICY,
  component: TemplateScreen,
  contentfulPageKey: "5olc2W58QJbGFuqCHFpusz",
};

const SITEMAP: RouteItemDef = {
  id: "Sitemap",
  path: ContentfulPathsEnum.SITEMAP,
  component: TemplateScreen,
  contentfulPageKey: "5VAXQxAofqXgUyk1YGKZN7",
};

const DISCLAIMER: RouteItemDef = {
  id: "Disclaimer",
  path: ContentfulPathsEnum.DISCLAIMER,
  component: TemplateScreen,
  contentfulPageKey: "vuWua7ItboR7JRqGjIHX9",
};

const DELIVERY_GUIDANCE: RouteItemDef = {
  id: "DeliveryGuidance",
  path: ContentfulPathsEnum.DELIVERY_GUIDANCE,
  component: TemplateScreen,
  contentfulPageKey: "6YQmHW87TA1sW592u2GYx7",
};

const ACCESSIBILITY_STATEMENT: RouteItemDef = {
  id: "AccessibilityStatement",
  path: ContentfulPathsEnum.ACCESSIBILITY_STATEMENT,
  component: TemplateScreen,
  contentfulPageKey: "5VOzbhVA7pSct0D5oXbmVQ",
};

const ANNIVERSARY_PAGE: RouteItemDef = {
  id: "AnniversaryPage",
  path: ContentfulPathsEnum.ANNIVERSARY_PAGE,
  component: TemplateScreen,
  // contentfulPageKey: "1aJmJRXpPKw7YDsMjCA7F6", // staging key
  contentfulPageKey: "1ohKjAnWF26HkmITVaSQTH", // production key
};

const ACHIEVE_LANDING: RouteItemDef = {
  id: "AchieveLanding",
  path: ContentfulPathsEnum.ACHIEVE_LANDING,
  component: TemplateScreen,
  // contentfulPageKey: "3vArdaMXpXA8vaDrFR0qIa", // staging key
  contentfulPageKey: "4KyXEMMj7ijK28GWwcNw7P", // production key
};

const MOSAIC_LANDING: RouteItemDef = {
  id: "MosaicLanding",
  path: ContentfulPathsEnum.MOSAIC_LANDING,
  component: TemplateScreen,
  // contentfulPageKey: "74nfkTp1SdoXt0GmmXlGrD", // staging key
  contentfulPageKey: "34jOSgeEuIUxbhZiOPFGBW", // production key
};

const ENTERPRISE_CHALLENGE_LANDING: RouteItemDef = {
  id: "EnterpriseChallengeLanding",
  path: ContentfulPathsEnum.ENTERPRISE_CHALLENGE_LANDING,
  component: TemplateScreen,
  // contentfulPageKey: "3MLRlFgBSjeYyIkg1V8kSR", // staging key
  contentfulPageKey: "7w3y4KlqJceqLc582QSlXA", // production key
};

const ENTERPRISE_CHALLENGE: RouteItemDef = {
  id: "EnterpriseCHallenge",
  path: ContentfulPathsEnum.ENTERPRISE_CHALLENGE,
  component: TemplateScreen,
  // contentfulPageKey: "4NSbIGyfZx3rYjrHJBMnAj", // staging key
  contentfulPageKey: "ZS8Fpt9bTIXYinxUvYCGB", // production key
};

const OUR_IMPACT: RouteItemDef = {
  id: "OurImpact",
  path: ContentfulPathsEnum.OUR_IMPACT,
  component: TemplateScreen,
  contentfulPageKey: "KuXSnnd1TKQA6wd0IIIKp", // staging key
};

const FINANCIAL_HEALTH: RouteItemDef = {
  id: "FinancialHealth",
  path: ContentfulPathsEnum.FINANCIAL_HEALTH,
  component: TemplateScreen,
  // contentfulPageKey: "5tMJvU9dKJW3VCeJhYh4Uc", // staging key
  contentfulPageKey: "56SaW67eAX9u6l7I8P0IE8", // production key
};

const SUSTAINABILITY: RouteItemDef = {
  id: "Sustainability",
  path: ContentfulPathsEnum.SUSTAINABILITY,
  component: TemplateScreen,
  contentfulPageKey: "6Fkc8MFJTv6qpjAcoZ8dWx", // production key
};

export const CONTENTFUL_ROUTES = [
  ACTIVITY_DETAIL_SCREEN,
  COLLECTIONS_DETAIL_SCREEN,
  DISCLAIMER,
  ENGLAND,
  FAQ,
  FUNDERS,
  HOME_SCREEN,
  HOW_ACHIEVE_WORKS_SCREEN,
  NORTHERN_IRELAND,
  PRIVACY_POLICY,
  SCOTLAND,
  SITEMAP,
  TERMS_AND_CONDITIONS,
  UNIT_DETAIL_SCREEN,
  WALES,
  DELIVERY_GUIDANCE,
  ACCESSIBILITY_STATEMENT,
  ANNIVERSARY_PAGE,
  ACHIEVE_LANDING,
  MOSAIC_LANDING,
  ENTERPRISE_CHALLENGE_LANDING,
  ENTERPRISE_CHALLENGE,
  OUR_IMPACT,
  FINANCIAL_HEALTH,
  SUSTAINABILITY,
];

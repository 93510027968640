import React, { FC, memo } from "react";
import classnames from "classnames/bind";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import styles from "./MobileMenu.module.scss";
import NavigationLinks, {
  NavigationLinksProps,
} from "../NavigationLinks/NavigationLinks";
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import NavigationProfile from "../NavigationProfile/NavigationProfile";
import DropdownMenu, { Dropdown } from "../DropdownMenu/DropdownMenu";

interface MobileMenuProps extends NavigationLinksProps {
  isVisible: boolean;
  toggleMenu: () => void;
  dropdowns?: Dropdown[];
}

const MobileMenu: FC<MobileMenuProps> = memo(
  ({ isVisible, toggleMenu, navLinks, dropdowns }) => {
    const cx = classnames.bind(styles);

    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    const onBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
      const element = e.target as HTMLDivElement;
      if (element.getAttribute("role") === "presentation") {
        toggleMenu();
      }
    };

    return (
      <div
        role="presentation"
        className={cx(styles.wrapper, {
          isVisible,
        })}
        hidden={!isVisible}
        onClick={onBackdropClick}
      >
        <div
          className={cx(styles.menu, {
            isVisible,
          })}
        >
          {dropdowns && (
            <div className={styles.dropdowns}>
              {dropdowns.map(dropdown => (
                <DropdownMenu key={dropdown.sys.id} dropdown={dropdown} />
              ))}
            </div>
          )}
          <NavigationLinks
            navLinks={navLinks}
            onNavItemClick={toggleMenu}
            isMobileLinks
          />

          <div className={styles.container}>
            {isAuthenticated ? <NavigationProfile /> : <NavigationButtons />}
          </div>
        </div>
      </div>
    );
  }
);

export default MobileMenu;

import React, { FC, memo } from "react";
import { Document } from "@contentful/rich-text-types";
import { ContentfulImage } from "@app/types/contentfulTypes";
import {
  Section,
  Container,
  Heading,
  RichText,
  MediaWrapper,
} from "@app/components";
import { Grid } from "@material-ui/core";
import { Button, IconBox } from "@app/components/atoms/atoms";
import { IconCircle } from "@app/components/atoms/Icon/Icon";
import styles from "./GenericTextAndImage.module.scss";

export interface GenericTextAndImageProps {
  ctaLabel?: string;
  ctaLink?: string;
  image: ContentfulImage;
  text?: Document;
  title: string;
  isMapComponent?: boolean;
}

const GenericTextAndImage: FC<GenericTextAndImageProps> = memo(
  ({ ctaLabel, ctaLink, title, text, image, isMapComponent = false }) => {
    return (
      <Section className={styles.section}>
        <Container indentStep={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <div className={styles.titleWrapper}>
                {isMapComponent && (
                  <IconBox className={styles.iconBox} icon={<IconCircle />} />
                )}
                <div>
                  {!!title && (
                    <Heading size={isMapComponent ? "subtitle2" : "h2"}>
                      {title}
                    </Heading>
                  )}
                  {text && (
                    <RichText className={styles.text} cfDocument={text} />
                  )}
                  {!!ctaLabel && !!ctaLink && (
                    <div className={styles.buttonWrapper}>
                      <Button
                        className={styles.button}
                        label={ctaLabel}
                        to={ctaLink}
                        tabIndex={-1}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>

            {image.fields && (
              <Grid item xs={12} md={6}>
                <MediaWrapper
                  className={styles.image}
                  cfImage={image}
                  natural
                />
              </Grid>
            )}
          </Grid>
        </Container>
        {image.fields && (
          <MediaWrapper
            className={styles.mobileImage}
            cfImage={image}
            natural
          />
        )}
      </Section>
    );
  }
);

export default GenericTextAndImage;

import * as React from "react";

function SvgListBullets(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M48.125 21.863h87.5M48.125 74.363h87.5M48.125 126.863h87.5M4.375 4.363h26.25v26.25H4.375zM4.375 56.863h26.25v26.25H4.375zM4.375 109.363h26.25v26.25H4.375z"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgListBullets = React.memo(SvgListBullets);
export default MemoSvgListBullets;

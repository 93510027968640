import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiStatusCodes } from "@app/constants/api.constants";
import {
  PermissionEnum,
  setPermissions,
  clearPermissions,
} from "@app/features/permissions/permissions";
import { AppThunk } from "@app/redux/store";
import {
  UpdateUserDef,
  UserDef,
  UpdatePasswordDef,
  ForgotPasswordDef,
  ResetPasswordDef,
} from "@app/types/api.types";
import { clearBookmarks } from "@app/features/bookmarks/bookmarks";
import { authApi } from "../api/auth.api";
import { clearTokens } from "../helpers/auth.helpers";
import { LoginResponseDef } from "../types/auth-login.types";

interface AuthState {
  user: UserDef | null;
  isAuthenticated: boolean;
  error: boolean;
  customAttributes: {
    achieve?: boolean;
    mosaic?: boolean;
  };
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  error: false,
  customAttributes: {
    achieve: false,
    mosaic: false,
  },
};

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (values: UpdateUserDef, { rejectWithValue }) => {
    try {
      const response = await authApi.updateUser(values);
      return response.data;
    } catch (err: any) {
      // Explicitly specify the type of err
      return rejectWithValue(err.response?.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (values: UpdatePasswordDef, { rejectWithValue }) => {
    try {
      const response = await authApi.updatePassword(values);
      return response.data;
    } catch (err: any) {
      // Explicitly specify the type of err
      return rejectWithValue(err.response?.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (values: ForgotPasswordDef, { rejectWithValue }) => {
    try {
      const response = await authApi.forgotPassword(values);
      return response.data;
    } catch (err: any) {
      // Explicitly specify the type of err
      return rejectWithValue(err.response?.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (values: ResetPasswordDef, { rejectWithValue }) => {
    try {
      const response = await authApi.resetPassword(values);
      return response.data;
    } catch (err: any) {
      // Explicitly specify the type of err
      return rejectWithValue(err.response?.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserDef>) {
      const user = action.payload;
      setPermissions([PermissionEnum.USER]);
      state.user = user;
      state.isAuthenticated = true;
      state.error = false;
    },
    setUserFailed(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.error = true;
      clearTokens();
    },
    setCustomAttributes: (
      state,
      action: PayloadAction<{ achieve?: boolean; mosaic?: boolean }>
    ) => {
      state.customAttributes = action.payload;
    },
    clearUser: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(
      updateUser.fulfilled,
      (state, action: PayloadAction<UserDef>) => {
        state.user = action.payload;
        state.error = false;
      }
    );
    builder.addCase(updateUser.rejected, state => {
      state.error = true;
    });
    builder.addCase(updatePassword.fulfilled, state => {
      state.error = false;
    });
    builder.addCase(updatePassword.rejected, state => {
      state.error = true;
    });
    builder.addCase(forgotPassword.fulfilled, state => {
      state.error = false;
    });
    builder.addCase(forgotPassword.rejected, state => {
      state.error = true;
    });
    builder.addCase(resetPassword.fulfilled, state => {
      state.error = false;
    });
    builder.addCase(resetPassword.rejected, state => {
      state.error = true;
    });
  },
});

export const { setUser, setUserFailed, clearUser, setCustomAttributes } =
  authSlice.actions;

export default authSlice.reducer;

export const getUser = (): AppThunk => async dispatch => {
  let response;
  try {
    response = await authApi.getUser();
  } catch (err) {
    dispatch(setUserFailed());
    return;
  }

  if (
    response?.status === ApiStatusCodes.SUCCESS ||
    response?.status === ApiStatusCodes.CREATED
  ) {
    const { data } = response;
    dispatch(setUser(data));
  }
};

export const loginOktaSuccess =
  (
    response: LoginResponseDef,
    customAttributes: { achieve?: boolean; mosaic?: boolean }
  ): AppThunk =>
  async dispatch => {
    if (response) {
      dispatch(setCustomAttributes(customAttributes));
      await dispatch(getUser());
    }
  };

export const loginOktaError = (err: any) => {
  console.warn("ERROR login okta slice dispatch", err, err.response);
};

export const logout = (): AppThunk => async dispatch => {
  let response;
  try {
    response = await authApi.logout();
  } catch (err) {
    dispatch(setUserFailed());
    return;
  }
  if (response?.status === ApiStatusCodes.NO_CONTENT) {
    clearTokens();
    clearPermissions();
    dispatch(clearUser());
    dispatch(clearBookmarks());
  }
};

export enum ContentfulPathsEnum {
  ACTIVITIES = "/activities",
  ACTIVITY_DETAIL = "/activities/detail",
  COLLECTIONS = "/collections",
  COLLECTIONS_DETAIL = "/collections/detail",
  DETAIL = "/detail",
  DISCLAIMER = "/disclaimer",
  ENGLAND = "/region/england",
  FAQ = "/faq",
  FUNDERS = "/funders",
  HOME = "/",
  HOW_ACHIEVE_WORKS = "/how-achieve-works",
  LIBRARY = "/library",
  LOGIN = "/login", // TODO: this is not a contentful page, it lives in feature auth
  NORTHERN_IRELAND = "/region/northern-ireland",
  PRIVACY_POLICY = "/privacy-policy",
  SCOTLAND = "/region/scotland",
  // SIGNUP = "/sign-up",
  SITEMAP = "/sitemap",
  TEMPLATE = "/template",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  UNITS = "/units",
  UNIT_DETAIL = "/units/detail",
  WALES = "/region/wales",
  DELIVERY_GUIDANCE = "/delivery-guidance",
  ACCESSIBILITY_STATEMENT = "/accessibility-statement",
  ANNIVERSARY_PAGE = "/anniversary",
  ACHIEVE_LANDING = "/programs/achieve",
  MOSAIC_LANDING = "/programs/mosaic",
  ENTERPRISE_CHALLENGE_LANDING = "/programs/enterprise-challenge",
  MOSAIC_LIBRARY = "/mosaic-library",
  ENTERPRISE_LIBRARY = "/enterprise-challenge-library",
  ENTERPRISE_CHALLENGE = "/enterprise-challenge-library",
  OUR_IMPACT = "/our-impact",
  FINANCIAL_HEALTH = "/financial-health",
  SUSTAINABILITY = "/sustainability",
}

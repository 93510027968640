import { RootState } from "@app/redux/rootReducer";
import { Snackbar } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "../../notification";
import styles from "./Notification.module.scss";

const Notification = () => {
  const { message, show } = useSelector(
    (state: RootState) => state.notification
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearNotification());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={show}
      message={message}
      autoHideDuration={3000}
      onClose={handleClose}
      className={styles.notification}
    />
  );
};

export default Notification;

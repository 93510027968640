import React, { FC, memo, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { DefaultLayout } from "@app/components/layouts/DefaultLayout/DefaultLayout";
import { Permission } from "@app/features/permissions/permissions";
import { RouteItemDef } from "@app/types/routeDef";
import { ThemeEnum } from "@app/constants/themeConst";
import { UserPathsEnum } from "@app/features/users/users";
import {
  getLocalization,
  LocalesEnum,
  setLocale,
} from "@app/features/localization/localization";
import { PUBLIC_LIST, PRIVATE_LIST } from "./Routes.config";
import Restricted from "./components/Restricted/Restricted";

const Routes: FC = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const { locale } = useSelector((state: RootState) => state.localization);

  const history = useHistory();

  const location = useLocation();

  const dispatch = useDispatch();

  const hasLocale =
    location.pathname.startsWith("/en") || location.pathname.startsWith("/cy");

  useEffect(() => {
    if (hasLocale) {
      const currentLocale = location.pathname.substring(1, 3);

      if (currentLocale !== locale.substring(0, 2)) {
        if (location.pathname.startsWith("/cy")) {
          dispatch(setLocale(LocalesEnum.WELSH));
          dispatch(getLocalization(LocalesEnum.WELSH));
        } else {
          dispatch(setLocale(LocalesEnum.ENGLISH));
          dispatch(getLocalization(LocalesEnum.ENGLISH));
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const localeInURL = locale.substring(0, 2);

    if (!hasLocale) {
      history.push({
        pathname: `/${localeInURL}${location.pathname}`,
        search: location.search,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const routeWrapper = (route: RouteItemDef) => {
    return (
      <Route
        key={route.id}
        exact={route.exact ?? true}
        path={`/:lang${route.path}`}
        render={(props): React.ReactElement => {
          const Component = route.component;
          const Layout = route.layout || DefaultLayout;
          const renderComponent = (
            <Layout theme={ThemeEnum.DEFAULT}>
              <Component
                {...props}
                contentfulPageKey={route.contentfulPageKey}
              />
            </Layout>
          );

          return (
            (route.permissions && (
              <Permission
                fallback={<Restricted />}
                requiredPermissions={route.permissions}
              >
                {renderComponent}
              </Permission>
            )) ||
            renderComponent
          );
        }}
      />
    );
  };

  return (
    <Switch>
      {isAuthenticated ? (
        <Redirect exact from="/" to={UserPathsEnum.DASHBOARD} />
      ) : (
        <Redirect exact from={UserPathsEnum.DASHBOARD} to="/" />
      )}
      {isAuthenticated
        ? PRIVATE_LIST.map(route => routeWrapper(route))
        : PUBLIC_LIST.map(route => routeWrapper(route))}
    </Switch>
  );
};

export default memo(Routes);

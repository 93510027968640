import React, { FC, memo } from "react";
import { DocumentDownload } from "@app/components";
import { useTranslation } from "react-i18next";

export interface TrackingSheetsProps {
  data?: never[];
}

const TrackingSheets: FC<TrackingSheetsProps> = memo(({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      {data && (
        <DocumentDownload data={data} title={t("components.trackingSheets")} />
      )}
    </>
  );
});

export default TrackingSheets;

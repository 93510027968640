import React, { FC, memo } from "react";

import {
  Heading,
  Text,
  Modal,
  ModalProps,
  Button,
} from "@app/components/atoms/atoms";

import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { useTranslation } from "react-i18next";
import styles from "./MissingAccessModal.module.scss";

type MissingAccessModalProps = Omit<
  ModalProps,
  "children" | "centerContent"
> & {
  currentLibrary: "mosaic" | "achieve";
};

const MissingAccessModal: FC<MissingAccessModalProps> = memo(
  ({ currentLibrary, ...rest }) => {
    const { t } = useTranslation();

    let accessMessage = "";
    if (currentLibrary === "mosaic") {
      accessMessage = t("missingAccess.mosaicText");
    } else if (currentLibrary === "achieve") {
      accessMessage = t("missingAccess.text");
    }

    return (
      <Modal centerContent {...rest}>
        <div className={styles.container}>
          <Heading className={styles.text} size="subtitle1">
            {t("missingAccess.title")}
          </Heading>
          <Text small className={styles.text}>
            {accessMessage}
          </Text>
          <Button
            to={ContentfulPathsEnum.LOGIN}
            label={t("components.navigationLoginButton")}
            inverted
            removeBorder
          />
          <Button
            to={t("components.navigationSignUpButtonLink")}
            label={t("components.navigationSignUpButtonLabel")}
            primary
          />
        </div>
      </Modal>
    );
  }
);

export { MissingAccessModal };

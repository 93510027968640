import React, { FC, memo, useState, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/swiper-react";
import SwiperCore from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import classnames from "classnames/bind";
import styles from "./SwiperCarousel.module.scss";
import "swiper/swiper.scss";
// import 'swiper/swiper-bundle.min.css';

// import "swiper/components/scrollbar/scrollbar.scss";
// import "swiper/components/navigation/navigation.scss";

const cx = classnames.bind(styles);

SwiperCore.use([Navigation, Pagination, Scrollbar]);

export interface SwiperBreakpointsDef {
  [key: string]: { slidesPerView?: number; slidesPerGroup?: number };
}

export interface SwiperCarouselProps {
  enableLoop?: boolean;
  centered?: boolean;
  showNavArrows?: boolean;
  navBtnsClassName?: string;
  showPagination?: boolean;
  swiperBreakpoints: SwiperBreakpointsDef;
  spaceBetween?: number;
  hideExcessMode?: boolean;
  slidesPerGroup?: number;
  showScrollbar?: boolean;
  children: React.ReactNode;
}

const SwiperCarousel: FC<SwiperCarouselProps> = memo(
  ({
    enableLoop = false,
    centered = false,
    swiperBreakpoints,
    showNavArrows = false,
    showPagination = false,
    showScrollbar = false,
    children,
    spaceBetween,
    hideExcessMode,
    slidesPerGroup,
    navBtnsClassName,
  }) => {
    const [swiperElem, setSwiperElem] = useState<SwiperCore>();
    const paginationOptions = {
      clickable: true,
      currentClass: ".paginationBar",
    };
    const prevSlide = useCallback(() => {
      if (swiperElem) {
        swiperElem.slidePrev();
      }
    }, [swiperElem]);

    const nextSlide = useCallback(() => {
      if (swiperElem) {
        swiperElem.slideNext();
      }
    }, [swiperElem]);

    return (
      <div
        className={cx(styles.swiperWrapper, {
          hideExcessMode,
        })}
      >
        <Swiper
          slidesPerView="auto"
          loop={enableLoop}
          centeredSlides={centered}
          breakpoints={swiperBreakpoints}
          pagination={showPagination ? paginationOptions : false}
          onSwiper={(swiper: any) => setSwiperElem(swiper)}
          spaceBetween={spaceBetween}
          scrollbar={showScrollbar ? { draggable: true } : false}
          navigation
          slidesPerGroup={slidesPerGroup}
        >
          {children}
        </Swiper>
        {showNavArrows && (
          <div className={cx(styles.navButtons, navBtnsClassName)}>
            <button
              className={styles.navButton}
              onClick={prevSlide}
              aria-label="Previous slide"
            >
              <ArrowBackIos />
            </button>
            <button
              className={styles.navButton}
              onClick={nextSlide}
              aria-label="Next slide"
            >
              <ArrowForwardIos />
            </button>
          </div>
        )}
      </div>
    );
  }
);

export { SwiperCarousel };
export { SwiperSlide };

import React, { FC, useState, useRef } from "react";
import { Link } from "@app/components";
import { useTranslation } from "react-i18next";
import { isURL } from "@app/helpers/utilHelper";
import { TiArrowSortedDown } from "react-icons/ti";
import styles from "./DropdownMenu.module.scss";

// Dropdown Menu that contains the programs and browse libraries in the navigation

interface SubPage {
  sys: {
    id: string;
  };
  fields: {
    name: string;
    path: string;
    isPrivate: boolean;
  };
}

export interface Dropdown {
  sys: {
    id: string;
  };
  fields: {
    name: string;
    subpages: SubPage[];
  };
}

export interface DropdownMenuProps {
  dropdown: Dropdown;
  onNavItemClick?: () => void;
  isMobileLinks?: boolean;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
  dropdown,
  onNavItemClick,
  isMobileLinks,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const arrowRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = () => {
    setIsButtonClicked(!isButtonClicked);
    toggleDropdown();
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={styles.dropdown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        className={`${styles["dropdown-toggle"]} btn btn-secondary ${
          isButtonClicked ? styles.buttonClicked : ""
        }`}
        type="button"
        onClick={handleButtonClick}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <span className={styles.overlay}>{t(`${dropdown.fields.name}`)}</span>
        <div
          ref={arrowRef}
          className={styles.arrow}
          onClick={e => {
            e.stopPropagation();
            toggleDropdown();
          }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              toggleDropdown();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <TiArrowSortedDown
            className={styles.icon}
            style={{
              transform: `rotate(${isOpen ? "180deg" : "0"})`,
            }}
          />
        </div>
      </button>
      {isOpen && (
        <div className={`${styles["dropdown-menu"]} show`}>
          {dropdown.fields.subpages.map(subpage => (
            <Link
              key={subpage.sys.id}
              to={subpage.fields.path}
              className={`${styles.navItem} ${styles.customLink}`}
              clickHandler={isMobileLinks ? onNavItemClick : () => null}
              showExternalIcon={
                !!(!!subpage.fields.path && isURL(subpage.fields.path))
              }
            >
              {subpage.fields.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;

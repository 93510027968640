import React, { FC, memo } from "react";
import {
  Section,
  Container,
  ItemList,
  Heading,
  MediaWrapper,
} from "@app/components";
import { GenericItemDef, ContentfulImage } from "@app/types/contentfulTypes";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import styles from "./ActivityBreakdowns.module.scss";

export interface ActivityBreakdownProps {
  data: {
    fields: {
      image: ContentfulImage;
      breakdownItems: GenericItemDef[];
    };
  };
}

const ActivityBreakdowns: FC<ActivityBreakdownProps> = memo(({ data }) => {
  const { image, breakdownItems } = data.fields;

  const { t } = useTranslation();
  const title = t("components.activityBreakdownHeading");
  const hasBackgroundColor = !!t(
    "uiSettings.activityBreakdown.backgroundColor"
  );

  const mappedActivities = (activitiesList: GenericItemDef[]) => {
    const remappedActivities = activitiesList?.map(activity => {
      const { name, duration } = activity.fields;

      return {
        fields: {
          title: name,
          text: duration,
        },
        sys: activity.sys,
      };
    });

    return remappedActivities;
  };

  return breakdownItems ? (
    <Section background={hasBackgroundColor} withSeparatorSpacing fullWidth>
      <Container indentStep={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            {title && (
              <Heading tag="h2" spacing="large">
                {title}
              </Heading>
            )}
            {breakdownItems.length > 0 && (
              <ItemList
                items={mappedActivities(breakdownItems)}
                listHorizontal={false}
                itemsHorizontal
                numbers
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={styles.imageCell}>
            {image?.fields && <MediaWrapper cfImage={image} fillContainer />}
          </Grid>
        </Grid>
      </Container>
    </Section>
  ) : null;
});

export default ActivityBreakdowns;

import React, { FC, memo } from "react";

import FooterLinkItem, {
  FooterLinkItemProps,
} from "../FooterLinkItem/FooterLinkItem";
import styles from "./FooterLinks.module.scss";

interface FooterLinksProps {
  links: FooterLinkItemProps[];
}
const FooterLinks: FC<FooterLinksProps> = memo(({ links }) => {
  return (
    <ul className={styles.footerLinks}>
      {links?.map(item => (
        <FooterLinkItem key={item.label} label={item.label} link={item.link} />
      ))}
    </ul>
  );
});

export default FooterLinks;

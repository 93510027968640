import React, { FC, memo } from "react";
import classNames from "classnames";
import {
  Section,
  Container,
  Heading,
  RichText,
  Button,
  Text,
  MediaWrapper,
} from "@app/components";
import { Document } from "@contentful/rich-text-types";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./TextPanel.module.scss";

export interface TextPanelProps {
  title?: string;
  titleAlignment?: string;
  subtitle?: string;
  text: Document;
  plainText?: string;
  ctaLabel?: string;
  ctaLink?: string;
  logo?: ContentfulImage;
}
/**
 * TextPanel
 */
const TextPanel: FC<TextPanelProps> = memo(
  ({
    text,
    title,
    titleAlignment = "",
    subtitle,
    plainText,
    ctaLabel,
    ctaLink,
    logo,
  }) => {
    return (
      <Section withSeparatorSpacing>
        <Container indentStep={3} className={styles.assetLinks}>
          {!!title && (
            <Heading size="h2" spacing className={styles[titleAlignment]}>
              {title}
            </Heading>
          )}
          {!!subtitle && (
            <Heading
              tag="h5"
              spacing="small"
              className={classNames([styles[titleAlignment], styles.subtitle])}
            >
              {subtitle}
            </Heading>
          )}
          {!!text && <RichText cfDocument={text} />}
          {!!plainText && <Text>{plainText}</Text>}
        </Container>
        <Container indentStep={3}>
          <div className={styles.container}>
            {logo && (
              <MediaWrapper className={styles.logo} cfImage={logo} natural />
            )}
            {!!ctaLabel && !!ctaLink && (
              <Button arrow label={ctaLabel} to={ctaLink} />
            )}
          </div>
        </Container>
      </Section>
    );
  }
);

export default TextPanel;

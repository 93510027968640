import React, { FC, memo } from "react";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import classnames from "classnames/bind";
import styles from "./Tag.module.scss";

const cx = classnames.bind(styles);

export interface TagProps extends React.HTMLAttributes<HTMLElement> {
  isNew?: boolean;
  isFeatured?: boolean;
  resource?: ResourceTypeEnum;
  icon?: JSX.Element;
}

const Tag: FC<TagProps> = memo(
  ({ isNew, isFeatured, resource, children, icon }) => {
    return (
      <div
        className={cx(styles.tag, {
          isNew,
          isFeatured,
          collection: resource === ResourceTypeEnum.COLLECTION,
          unit: resource === ResourceTypeEnum.UNIT,
          activity: resource === ResourceTypeEnum.ACTIVITY,
        })}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        {children}
      </div>
    );
  }
);

export { Tag };

/* eslint-disable */
import { CardFieldsDef } from "@app/types/contentfulTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { bookmarksApi } from "../api/bookmarks.api";
import { BookmarkDataDef, BookmarksFiltersDef } from "../types/bookmarks.types";

interface BookmarkState {
  bookmarks: {
    data: CardFieldsDef[];
    meta: {
      current_page: number;
      last_page: number;
      total: number;
      per_page: number;
    };
  };
  loading: boolean;
  loadingMore: boolean;
  error: boolean;
}

const initialState: BookmarkState = {
  bookmarks: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      total: 1,
      per_page: 6,
    },
  },
  loading: false,
  loadingMore: false,
  error: false,
};

export const getBookmarks = createAsyncThunk(
  "bookmarks/getBookmarks",
  async (filter?: BookmarksFiltersDef) => {
    try {
      const response = await bookmarksApi.getAllUserBookmarks(filter);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const getBookmarksAndAppend = createAsyncThunk(
  "bookmarks/getBookmarksAndAppend",
  async (filter: BookmarksFiltersDef) => {
    try {
      const response = await bookmarksApi.getAllUserBookmarks(filter);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const addResourceToBookmarks = createAsyncThunk(
  "bookmarks/addToBookmarks",
  async (data: BookmarkDataDef, { rejectWithValue }) => {
    try {
      const response = await bookmarksApi.addToUserBookmarks(data);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteResourceFromBookmarks = createAsyncThunk(
  "bookmarks/deleteResourceFromBookmarks",
  async (resourceId: string, { rejectWithValue }) => {
    try {
      const response = await bookmarksApi.deleteBookmarkById(resourceId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const BookmarksSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    clearBookmarks: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getBookmarks.pending, state => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getBookmarks.fulfilled, (state, action) => {
      state.loading = false;
      state.bookmarks.data = action.payload.data;
      state.bookmarks.meta = action.payload.meta;
      state.error = false;
    });

    builder.addCase(getBookmarksAndAppend.pending, state => {
      state.loadingMore = true;
      state.error = false;
    });

    builder.addCase(getBookmarksAndAppend.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.bookmarks.data = [...state.bookmarks.data, ...action.payload.data];
      state.bookmarks.meta = action.payload.meta;
      state.error = false;
    });

    builder.addCase(addResourceToBookmarks.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.bookmarks.data = [...state.bookmarks.data, action.payload];
    });

    builder.addCase(addResourceToBookmarks.pending, state => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(addResourceToBookmarks.rejected, state => {
      state.loading = false;
      state.error = true;
    });

    builder.addCase(deleteResourceFromBookmarks.fulfilled, (state, action) => {
      const updatedBookmarks = state.bookmarks.data.filter(
        item => item.id !== action.meta.arg
      );
      state.loading = false;
      state.error = false;
      state.bookmarks.data = updatedBookmarks;
    });

    builder.addCase(deleteResourceFromBookmarks.pending, state => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(deleteResourceFromBookmarks.rejected, state => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearBookmarks } = BookmarksSlice.actions;

export default BookmarksSlice.reducer;

import React, { FC, memo, useEffect, useState } from "react";
import cx from "classnames/bind";
import { Section, Container, Heading, ButtonNavigation } from "@app/components";
import { useTranslation } from "react-i18next";
import { DownloadAccordion } from "./components/DownloadAccordion/DownloadAccordion";
import styles from "./DocumentDownload.module.scss";

interface FileDef {
  fields: {
    file: {
      url: string;
      fileName: string;
    };
    title: string;
  };
  sys: { id: string };
}

export interface DocumentDef {
  fields: { name: string; files: FileDef[]; isMosaicLibrary: boolean };
  sys: { id: string };
}

interface DocumentDownloadProps {
  data: {
    fields: { title: string; documents: DocumentDef[] };
    sys: { id: string };
  }[];
  title: string;
}

const DocumentDownload: FC<DocumentDownloadProps> = memo(({ data, title }) => {
  const [activeResourceIndex, setActiveResourceIndex] = useState(0);
  const [activeRegion, setActiveRegion] = useState<DocumentDef[]>([]);

  const { t } = useTranslation();

  const hasBackgroundColor = !!t("uiSettings.documentDownload.backgroundColor");

  /**
   * Get component settings
   */
  useEffect(() => {
    if (data && data[activeResourceIndex].fields) {
      setActiveRegion(data[activeResourceIndex].fields.documents);
    }
  }, [activeResourceIndex, data]);

  const bindStyles = cx.bind(styles);

  return (
    <Section background={hasBackgroundColor} withSeparatorSpacing fullWidth>
      <Container indentStep={3}>
        {title && (
          <Heading tag="h2" spacing="large">
            {title}
          </Heading>
        )}
        {/* 
          BUTTONS
          Navigate between regions. 
          Triggers a new set of accordions below 
          */}
        <ButtonNavigation align="left">
          {data?.map((entry, index) => (
            <button
              key={entry.sys.id}
              className={bindStyles("button", {
                activeButton: activeResourceIndex === index,
              })}
              onClick={() => setActiveResourceIndex(index)}
            >
              {entry.fields.title}
            </button>
          ))}
        </ButtonNavigation>

        <DownloadAccordion activeRegion={activeRegion} />
      </Container>
    </Section>
  );
});

export { DocumentDownload };

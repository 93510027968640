import { RouteItemDef } from "@app/types/routeDef";
import { ContactPathsEnum } from "../constants/contact.paths";

import ContactFormScreen from "../screens/ContactFormScreen/ContactFormScreen";

const CONTACT_SCREEN: RouteItemDef = {
  id: "contact-us",
  path: ContactPathsEnum.CONTACT,
  component: ContactFormScreen,
};

const CONTACT_ROUTES = [CONTACT_SCREEN];

export default CONTACT_ROUTES;

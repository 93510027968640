import React, { FC, memo, useEffect, useState } from "react";

import { Document } from "@contentful/rich-text-types";
import { Grid, LinearProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import classnames from "classnames/bind";

import {
  MediaWrapper,
  Text,
  SplitColumns,
  SplitLeft,
  SplitRight,
  Heading,
  Button,
  Link,
} from "@app/components";
import {
  IconBoxAndText,
  FormTheme,
  FormField,
} from "@app/components/molecules/molecules";
import { AuthPathsEnum } from "@app/features/auth/auth";
import {
  useCreateUser,
  usePasswordValidation,
  useSignupConfig,
  signupApi,
} from "@app/features/Signup/signup";
import { validateEmail } from "@app/helpers/validateEmail";
import { ContentfulImage } from "@app/types/contentfulTypes";

import { RootState } from "@app/redux/rootReducer";
import { Redirect, useLocation } from "react-router-dom";
import { UserPathsEnum } from "@app/features/users/users";
import styles from "./SignUp.module.scss";
import { TermsModal } from "./Components/TermsModal/TermsModal";

const cx = classnames.bind(styles);

interface SignUpProps {
  name?: string;
  intro?: string;
  image: ContentfulImage;
  termsConditionTitle: string;
  termsConditionBody: Document;
  termsConditionButtonLabel?: string;
  loginHelp?: string;
  loginHelpLinkText: string;
  termsLinkText1: string;
  termsLinkText2: string;
  newsletterLabel: string;
  passwordHeading: string;
  passwordText: string;
}

/**
 *
 * SIGNUP COMP
 * *******************
 * IMPORTANT INFO:
 * this component uses wrappers for Material UI elements and a handheld form validation between steps
 *
 * IN OTHER COMPONENTS (password, personal info, etc) that uses forms
 * its build in FORMIK / Mui with YUP validation etc
 * for design consistency all form control elements must be wrapped in <FormTheme></FormTheme>
 * for optimization => refactor this component to use FORMIK and beware of the three steps in the flow
 */
const SignUp: FC<SignUpProps> = memo(
  ({
    name,
    intro,
    image,
    termsConditionTitle = "",
    termsConditionBody,
    termsConditionButtonLabel,
    loginHelp,
    loginHelpLinkText,
    termsLinkText1,
    termsLinkText2,
    newsletterLabel,
    passwordHeading,
    passwordText,
  }) => {
    const { t } = useTranslation();
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const [doCallApi, setDoCallApi] = useState(false);
    const [isOpenTerms, setIsOpenTerms] = useState(false);
    const EMAIL_REQUIRED = t("signup.emailRequired");

    const location = useLocation<{ prevPath: string }>();

    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    if (isAuthenticated) {
      return (
        <Redirect to={location.state?.prevPath || UserPathsEnum.DASHBOARD} />
      );
    }

    /**
     * initial population of drop down select items
     */
    const { isConfigLoading, dropdownData } = useSignupConfig();

    /**
     *
     * PASSWORD Handling
     */
    const [password, setPassword] = useState({
      firstPassword: "",
      secondPassword: "",
    });
    const [isPasswordValid, passwordMatch] = usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
    });
    const setFirstPassword = (event: React.ChangeEvent<{ value: unknown }>) => {
      setPassword({ ...password, firstPassword: event.target.value as string });
    };
    const setSecondPassword = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setPassword({
        ...password,
        secondPassword: event.target.value as string,
      });
    };

    /**
     *
     * INITIAL SETUP FOR FORM FIELDS
     * THERE ARE 3 SECTIONS AS STEPS
     * EACH SECTION IS ITS OWN <form> ELEMENT
     *
     * visible steps information. There are more "steps" behind the scene.
     */
    const steps = 3;
    const [formFields, setFormFields] = useState<any>({
      activeStep: 1,
      // step 1 fields
      firstName: {
        value: "",
        error: false,
        helperText: t("signup.firstNameRequired"),
      },
      lastName: {
        value: "",
        error: false,
        helperText: t("signup.lastNameRequired"),
      },
      phone: {
        value: "",
        error: false,
        helperText: t("signup.phoneRequired"),
      },
      email: {
        value: "",
        error: false,
        helperText: EMAIL_REQUIRED,
      },
      terms: {
        value: true,
        error: false,
        helperText: t("signup.termsRequired"),
      },
      newsletter: { value: false, error: false },

      // step 2 fields
      roles: {
        value: 0,
        error: false,
        helperText: t("signup.rolesRequired"),
      },
      educationCenter: {
        value: 0,
        error: false,
        helperText: t("signup.educationRequired"),
      },
      organisation: {
        value: "",
        error: false,
        helperText: t("signup.organisationRequired"),
      },

      // Step 3 password fields is handled in validateSection function
    });

    /**
     * Hook to send user data to creation in backend
     * is dependent on formFields and doCallApi
     */
    const { isLoading, isSuccess, isError, errorMessage } = useCreateUser(
      formFields,
      {
        shouldCallApi: doCallApi,
      }
    );

    /* Generic focus into first error field after validation 
    "form" is the current <form> element */
    const focusFirstErrorField = (form: Element) => {
      const field: HTMLInputElement | null =
        form.querySelector(".Mui-error > input");
      field && field.focus();
    };

    /**
     *
     * VALIDATE SECTIONS - IN THREE STEPS
     * FIRST STEP IS SPLIT IN EMAIL VALIDATION FIRST (BECAUSE OF ASYNC SERVER CHECK)
     * AND THEN THE REST OF THE FIELDS IN STEP 1
     * VALIDATION UPDATES THE formFields OBJECTS STATE
     */
    const validateSection = ({
      event,
      sectionStep,
      isEmailError,
      emailMessage,
      targetForm,
    }: {
      /**
       * @event
       * event is optional argument - because when validateSectionEmailFirst calls this function, it sends
       * a form element directly plus its calling event.preventDefault.
       * Apparently "something happens" to the event object during the async actions in validateSectionEmailFirst,
       * so sending the targetForm in stead of a broken event object was a workaround.
       */
      event?: React.SyntheticEvent;
      sectionStep: number;
      isEmailError?: boolean;
      emailMessage?: string;
      /* targetForm only used when validateSectionEmailFirst is calling this function */
      targetForm?: Element;
    }) => {
      window.scrollTo(0, 0);

      if (event) {
        event.preventDefault();
      }

      const form = targetForm || event?.currentTarget;

      // SECTION 1
      if (sectionStep === 1) {
        const firstNameError = !formFields.firstName.value;
        const lastNameError = !formFields.lastName.value;
        const phoneError = !formFields.phone.value;
        const termsError = !formFields.terms.value;
        let step = 1;

        if (
          !firstNameError &&
          !lastNameError &&
          !phoneError &&
          !isEmailError &&
          !termsError
        ) {
          step = 2;
        } else {
          form && focusFirstErrorField(form);
        }

        // update formFields for section 1
        setFormFields({
          ...formFields,
          activeStep: step,
          firstName: {
            ...formFields.firstName,
            error: firstNameError,
          },
          lastName: {
            ...formFields.lastName,
            error: lastNameError,
          },
          phone: {
            ...formFields.phone,
            error: phoneError,
          },
          email: {
            ...formFields.email,
            helperText: emailMessage || formFields.email.helperText,
            error: isEmailError,
          },
          terms: {
            ...formFields.terms,
            error: termsError,
          },
        });
      }

      // Section 2
      if (sectionStep === 2) {
        const rolesError = !formFields.roles.value;
        const educationCenterError = !formFields.educationCenter.value;
        const organisationError = !formFields.organisation.value;
        let step = 2;

        if (!rolesError && !educationCenterError) {
          step = 3;
        } else {
          form && focusFirstErrorField(form);
        }

        setFormFields({
          ...formFields,
          activeStep: step,
          roles: {
            ...formFields.roles,
            error: rolesError,
          },
          educationCenter: {
            ...formFields.educationCenter,
            error: educationCenterError,
          },
          organisation: {
            ...formFields.organisation,
            error: organisationError,
          },
        });
      }

      // Section 3
      // Validate password fields - custom hook usePasswordMatch is providing the validation
      if (sectionStep === 3) {
        if (passwordMatch) {
          setFormFields({
            ...formFields,
            activeStep: 4,
            password: password.secondPassword,
          });
        }
      }
    };

    /**
     * EMAIL FIRST
     * When an email is typed in input field by user has valid format
     * its checked against doublets in the database
     */
    const validateSectionEmailFirst = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      const form = event.currentTarget;
      const email = formFields.email.value;
      let isEmailError = validateEmail(email);
      let emailMessage = "";

      if (isEmailError) {
        emailMessage = EMAIL_REQUIRED;
      } else {
        // format is ok - now check for if email is existing
        setIsEmailLoading(true);

        await signupApi
          .signupFieldsValidate({ email })
          .then(() => {
            isEmailError = false;
            emailMessage = "";
          })
          .catch(err => {
            const msg =
              err.response.data.errors.email[0] || "An error has ocurred.";
            isEmailError = true;
            emailMessage = msg;
          })
          .finally(() => {
            setIsEmailLoading(false);
          });
      }

      validateSection({
        event: undefined,
        sectionStep: 1,
        isEmailError,
        emailMessage,
        targetForm: form,
      });
    };

    /**
     * Submit data after password match
     */
    useEffect(() => {
      if (formFields.activeStep === 4 && !!formFields.password) {
        setDoCallApi(true);
      }
    }, [formFields]);

    /**
     * generic update function for formFields
     */
    const updateFormState = (key: string, value: string | boolean) => {
      setFormFields({
        ...formFields,
        [key]: {
          ...formFields[key],
          value,
          error: false,
        },
      });
    };

    const navigateSteps = (newStep: number) => {
      setFormFields({
        ...formFields,
        activeStep: newStep,
      });
    };

    /**
     * HELPERS
     * Handlers and updaters
     */
    const handleChangeCheckbox = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      updateFormState(event.target.name, event.target.checked);
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
      updateFormState(event.target.name, event.target.value);
    };

    const openTermsModal = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setIsOpenTerms(true);
    };

    const closeTermsModal = () => {
      setIsOpenTerms(false);
    };

    const RenderLoginLink = () => {
      return !!loginHelp || !!loginHelpLinkText ? (
        <Text small display="block" align="right">
          {loginHelp}
          {!!loginHelpLinkText && (
            <Link to={AuthPathsEnum.LOGIN} strong>
              {loginHelpLinkText}
            </Link>
          )}
        </Text>
      ) : null;
    };

    const RenderTermsLabel = () => {
      return !!termsLinkText1 && !!termsLinkText1 ? (
        <Text small>
          {termsLinkText1}{" "}
          <Link strong clickHandler={openTermsModal} to="#terms">
            {termsLinkText2}
          </Link>
        </Text>
      ) : null;
    };

    const renderStepLabel = (currentStep: number) => {
      return (
        <Text paragraph className={styles.steps}>
          {t("signup.currentStepLabel", {
            currentStep,
            totalSteps: steps,
          })}
        </Text>
      );
    };

    return (
      <SplitColumns>
        <SplitLeft showLogo>
          <div className={cx(styles.signUp, styles.form)}>
            <div className={styles.frame}>
              <FormTheme>
                {/**
                 * Step 1 ------------------------------
                 */}
                <div
                  className={cx(styles.section, {
                    isActive: formFields.activeStep === 1,
                  })}
                >
                  <form
                    noValidate
                    onSubmit={validateSectionEmailFirst}
                    data-step="1"
                  >
                    <div className={styles.sectionHeader}>
                      {renderStepLabel(1)}
                      <Heading tag="h3">{name}</Heading>
                      {!!intro && <Text paragraph>{intro}</Text>}
                    </div>

                    <div className={styles.fieldGroup}>
                      <Grid container justify="space-between" spacing={3}>
                        {/* First name */}
                        <Grid item sm={6}>
                          <FormField
                            name="firstName"
                            label={t("signup.firstNameLabel")}
                            placeholder={t("signup.firstNamePlaceholder")}
                            value={formFields.firstName.value}
                            required
                            error={formFields.firstName.error}
                            helperText={
                              formFields.firstName.error &&
                              formFields.firstName.helperText
                            }
                            onChange={handleChangeField}
                          />
                        </Grid>

                        {/* Last name  */}
                        <Grid item sm={6}>
                          <FormField
                            name="lastName"
                            label={t("signup.lastNameLabel")}
                            placeholder={t("signup.lastNamePlaceholder")}
                            value={formFields.lastName.value}
                            required
                            error={formFields.lastName.error}
                            helperText={
                              formFields.lastName.error &&
                              formFields.lastName.helperText
                            }
                            onChange={handleChangeField}
                          />
                        </Grid>

                        {/* Phone */}
                        <Grid item xs={12}>
                          <FormField
                            name="phone"
                            label={t("signup.phoneLabel")}
                            placeholder={t("signup.phonePlaceholder")}
                            value={formFields.phone.value}
                            required
                            error={formFields.phone.error}
                            helperText={
                              formFields.phone.error &&
                              formFields.phone.helperText
                            }
                            onChange={handleChangeField}
                          />
                        </Grid>

                        {/* email */}
                        <Grid item xs={12}>
                          <FormField
                            name="email"
                            type="email"
                            label={t("signup.emailLabel")}
                            placeholder={t("signup.emailPlaceholder")}
                            value={formFields.email.value}
                            required
                            onChange={handleChangeField}
                            error={formFields.email.error}
                            helperText={
                              formFields.email.error &&
                              formFields.email.helperText
                            }
                          />
                        </Grid>

                        <Grid xs={12} item>
                          {/* terms checkbox  */}
                          <FormField
                            internalType="checkbox"
                            id="termsAccepted"
                            name="terms"
                            checked={formFields.terms.value}
                            onChange={handleChangeCheckbox}
                            label={<RenderTermsLabel />}
                          />

                          {formFields.terms.error && (
                            <Text className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
                              {formFields.terms.helperText}
                            </Text>
                          )}
                          {/* Newsletter checkbox */}
                          <FormField
                            internalType="checkbox"
                            id="newsletter"
                            name="newsletter"
                            checked={formFields.newsletter.value}
                            onChange={handleChangeCheckbox}
                            label={<Text small>{newsletterLabel}</Text>}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <Grid container spacing={3} justify="space-between">
                      {/* Section footer */}
                      <Grid item xs={12}>
                        <div className={styles.sectionFooter}>
                          <Button
                            label={t("signup.buttonContinueLabel")}
                            primary
                            className={styles.stepButton}
                            arrow
                            type="submit"
                            loading={isEmailLoading}
                          />
                          <RenderLoginLink />
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>

                {/**
                 * Step 2 ------------------------------
                 */}
                <div
                  className={cx(styles.section, {
                    isActive: formFields.activeStep === 2,
                  })}
                >
                  <form
                    onSubmit={e =>
                      validateSection({ event: e, sectionStep: 2 })
                    }
                    data-step="2"
                    noValidate
                  >
                    <div className={styles.sectionHeader}>
                      {renderStepLabel(2)}
                      <Heading tag="h3">{name}</Heading>
                      {!!intro && <Text paragraph>{intro}</Text>}
                    </div>
                    <div className={styles.fieldGroup}>
                      {!isConfigLoading && !!dropdownData && (
                        <Grid container justify="space-between" spacing={3}>
                          {/* ROLES */}
                          <Grid item xs={12}>
                            <FormField
                              internalType="select"
                              id="roles"
                              name="roles"
                              label={t("signup.rolesLabel")}
                              value={formFields.roles.value}
                              onChange={handleChangeField}
                              required
                              error={formFields.roles.error}
                              helperText={
                                formFields.roles.error &&
                                formFields.roles.helperText
                              }
                              items={dropdownData.roles}
                            />
                          </Grid>

                          {/* EDUCATION  */}
                          <Grid item xs={12}>
                            <FormField
                              internalType="select"
                              id="educationCenter"
                              name="educationCenter"
                              label={t("signup.educationLabel")}
                              value={formFields.educationCenter.value}
                              onChange={handleChangeField}
                              required
                              error={formFields.educationCenter.error}
                              helperText={
                                formFields.educationCenter.error &&
                                formFields.educationCenter.helperText
                              }
                              items={dropdownData.educationCentres}
                            />
                          </Grid>

                          {/* ORGANISATION  */}
                          <Grid item xs={12}>
                            <FormField
                              name="organisation"
                              label={t("signup.organisationLabel")}
                              placeholder={t("signup.organisationPlaceholder")}
                              value={formFields.organisation.value}
                              required
                              error={formFields.organisation.error}
                              helperText={
                                formFields.organisation.error &&
                                formFields.organisation.helperText
                              }
                              onChange={handleChangeField}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </div>

                    <Grid container justify="space-between" spacing={3}>
                      <Grid item xs={6}>
                        <Button
                          label={t("signup.buttonBackLabel")}
                          outline
                          onClick={() => navigateSteps(1)}
                        />
                      </Grid>
                      <Grid item xs={6} className={styles.sectionFooter}>
                        <Button
                          label={t("signup.buttonContinueLabel")}
                          className={styles.stepButton}
                          type="submit"
                          arrow
                        />
                      </Grid>
                    </Grid>
                    <RenderLoginLink />
                  </form>
                </div>

                {/**
                 *  Step 3 ---------------------------
                 */}
                <div
                  className={cx(styles.section, {
                    isActive: formFields.activeStep === 3,
                  })}
                >
                  <form
                    onSubmit={e =>
                      validateSection({ event: e, sectionStep: 3 })
                    }
                    data-step="3"
                    noValidate
                  >
                    <div className={styles.sectionHeader}>
                      {renderStepLabel(3)}
                      <Heading tag="h3">{passwordHeading}</Heading>
                      <Text paragraph>{passwordText}</Text>
                    </div>

                    <div className={styles.fieldGroup}>
                      <Grid container justify="space-between" spacing={3}>
                        {/* Password creation */}
                        <Grid item xs={12}>
                          <FormField
                            name="firstPwd"
                            type="password"
                            label={t("signup.passwordLabel")}
                            placeholder={t("signup.passwordPlaceholder")}
                            required
                            onChange={setFirstPassword}
                            error={
                              password.firstPassword
                                ? !isPasswordValid
                                : undefined
                            }
                            helperText={
                              password.firstPassword && !isPasswordValid
                                ? t("password.passwordRequiredFormat")
                                : undefined
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormField
                            name="secondPwd"
                            type="password"
                            label={t("signup.passwordConfirmLabel")}
                            placeholder={t("signup.passwordConfirmPlaceholder")}
                            required
                            onChange={setSecondPassword}
                            error={
                              password.secondPassword
                                ? !passwordMatch
                                : undefined
                            }
                            helperText={
                              password.secondPassword && !passwordMatch
                                ? t("password.passwordRequiredMatch")
                                : undefined
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <Grid container justify="space-between" spacing={3}>
                      <Grid item xs={4}>
                        <Button
                          label={t("signup.buttonBackLabel")}
                          outline
                          onClick={() => navigateSteps(2)}
                        />
                      </Grid>
                      <Grid item xs={8} className={styles.sectionFooter}>
                        <Button
                          label={t("signup.buttonContinueLabel")}
                          className={cx(styles.stepButton, {
                            inactiveButton: !isPasswordValid || !passwordMatch,
                          })}
                          type="submit"
                          arrow
                        />
                        <RenderLoginLink />
                      </Grid>
                    </Grid>
                  </form>
                </div>

                {/**
                 *  Step 4 ---------------------------
                 */}
                <div
                  className={cx(styles.section, {
                    isActive: formFields.activeStep === 4,
                  })}
                >
                  <div className={styles.message}>
                    {isLoading && (
                      <div style={{ width: "100%" }}>
                        <LinearProgress color="secondary" />
                      </div>
                    )}
                    {!isLoading && isSuccess && (
                      <div>
                        <IconBoxAndText description="" icon="IconBook" />
                        <Heading size="h3">{t("signup.successTitle")}</Heading>
                        <Text>
                          {t("signup.successText")}
                          <strong>{formFields.email.value}</strong>
                        </Text>
                        <br />

                        <Button
                          primary
                          arrow
                          label={t("signup.buttonReturnToAchieveLabel")}
                          to="/login"
                          className={styles.btn}
                        />
                      </div>
                    )}
                    {!isLoading && isError && (
                      <div className={styles.errorMessage}>
                        <Heading size="h6">
                          <Trans
                            i18nKey="signup.errorMessage"
                            values={{ page: "FAQ's" }}
                            components={{
                              pageLink: <Link to="/faq">value</Link>,
                            }}
                          />
                        </Heading>
                        {Object.keys(errorMessage?.data.errors).map(key => (
                          <p className={styles.errorText}>
                            {errorMessage?.data.errors[key]}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* steps end */}
              </FormTheme>
            </div>
            <TermsModal
              isOpen={isOpenTerms}
              fnHandleClose={closeTermsModal}
              title={termsConditionTitle}
              body={termsConditionBody}
              buttonLabel={termsConditionButtonLabel}
            />
          </div>
        </SplitLeft>
        <SplitRight>
          {image?.fields?.file?.url && (
            <MediaWrapper
              className={styles.rightImage}
              cfImage={image}
              fillContainer
            />
          )}
        </SplitRight>
      </SplitColumns>
    );
  }
);

export default SignUp;

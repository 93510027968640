import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _camelCase from "lodash/camelCase";
// eslint-disable-next-line no-restricted-imports
import { getEntry } from "@app/features/contentful/configs/contentful.client";
import { LocalizationDef, UiSettingsDef } from "../types/localizationDef";
import { LocalesEnum } from "../localization";

// const LOCALIZATION_KEY = "6NLfZk3ibt5CIk25c1GOIv"; // - staging key
const LOCALIZATION_KEY = "2LUmLpU68r98p11QR0Y4I"; // - production key

const currentLocale = localStorage.getItem("i18n-locale");

interface LocalizationState {
  locale: string;
  loading: boolean;
  error: boolean;
  localization: LocalizationDef;
}

const initialState: LocalizationState = {
  locale: JSON.parse(currentLocale as string) ?? "en-US",
  localization: {},
  loading: false,
  error: false,
};

export const setLocale = createAsyncThunk(
  "localization/setLocale",
  async (locale: string) => {
    if (locale) return locale;
    return LocalesEnum.ENGLISH;
  }
);

export const getLocalization = createAsyncThunk(
  "localization/getLocalization",
  async (locale: string) => {
    try {
      const data = await getEntry(LOCALIZATION_KEY, {
        include: 2,
        locale,
      })
        .then((result: any) => {
          /* this part is tied into setup in contentful (settings models) */
          const localizationTemp: LocalizationDef = {};
          if (result) {
            /* Settings - text "translations" for headings, buttons etc */
            result.fields.settingsSet.forEach((settingsSet: any) => {
              settingsSet.fields.settings.forEach((setting: any) => {
                localizationTemp[_camelCase(settingsSet.fields.name)] = {
                  ...localizationTemp[_camelCase(settingsSet.fields.name)],
                  ...{
                    [_camelCase(setting.fields.key)]: setting.fields.value,
                  },
                };
              });
              localizationTemp[_camelCase(settingsSet.fields.name)].extraInfo =
                settingsSet.fields.extraInfo;
            });

            /* Settings - optional ui settings for visual elements */
            const uiSettingsTemp: UiSettingsDef = {};

            result.fields.uiSettings.forEach((uiSettingItem: any) => {
              const { key, backgroundColor } = uiSettingItem.fields;
              uiSettingsTemp[_camelCase(key)] = {
                backgroundColor,
              };
            });

            localizationTemp.uiSettings = uiSettingsTemp;
          }
          return localizationTemp;
        })
        .catch(error => console.error(error));
      return data;
    } catch (err) {
      return err;
    }
  }
);

const LocalizationSlice = createSlice({
  name: "localization",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getLocalization.pending, state => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getLocalization.fulfilled, (state, action: any) => {
      state.loading = false;
      state.localization = action.payload;
      state.error = false;
    });

    builder.addCase(setLocale.pending, state => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(setLocale.fulfilled, (state, action: any) => {
      state.loading = false;
      state.locale = action.payload;
      state.error = false;
    });
  },
});

export default LocalizationSlice.reducer;

import React, { FC, memo } from "react";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { ContentfulImage } from "@app/types/contentfulTypes";
import Footer, {
  FooterSections,
} from "@app/components/molecules/Footer/Footer";
import { BottomLinkItemProps } from "@app/components/molecules/Footer/components/BottomLinkItem/BottomLinkItem";
import { ParntershipProps } from "@app/components/molecules/Footer/components/Partnership/Partnership";

interface FooterContentfulProps {
  entryID: string;
}

interface SectionsDataModel {
  fields?: {
    title: string;
    sectionLinks: FooterLinks[];
  };
}

interface FooterLinks {
  fields?: {
    label: string;
    link: string;
    logo?: ContentfulImage;
  };
}

interface FooterDataModel {
  bottomLinks: SectionsDataModel;
  partnership: SectionsDataModel;
  sections: SectionsDataModel[];
  facebookLink: string;
  instagramLink: string;
  twitterLink: string;
  text: string;
}

const FooterComponent: FC<FooterContentfulProps> = memo(({ entryID }) => {
  if (!entryID) return null;

  const { entry: footerData } = useSingleEntry<FooterDataModel>(entryID);

  if (!footerData) return null;

  const {
    bottomLinks,
    sections,
    facebookLink,
    instagramLink,
    twitterLink,
    partnership,
    text,
  } = footerData;

  const mapBottomLinks = (links: SectionsDataModel) => {
    const mappedSections = links.fields?.sectionLinks.map(section => {
      return {
        label: section.fields?.label,
        link: section.fields?.link,
      };
    });
    return mappedSections as BottomLinkItemProps[];
  };

  const mapSectionLinks = (sectionsList: SectionsDataModel[]) => {
    const mappedSections = sectionsList
      // Only published items will have fields
      .filter(item => !!item.fields)
      .map((section: SectionsDataModel) => {
        return {
          title: section.fields?.title,
          links: section.fields?.sectionLinks
            // Only published items will have fields
            .filter(item => !!item.fields)
            .map((item: FooterLinks) => ({
              label: item.fields?.label,
              link: item.fields?.link,
            })),
        };
      });
    return mappedSections as FooterSections[];
  };

  const mapPartnership = (item: SectionsDataModel) => {
    const logos = item.fields?.sectionLinks.map(section => {
      return {
        logo: section.fields?.logo,
        title: section.fields?.label,
      };
    });

    return {
      title: item.fields?.title,
      text,
      logos,
      socialLinks: { facebookLink, instagramLink, twitterLink },
    } as ParntershipProps;
  };

  return (
    <Footer
      bottomLinks={mapBottomLinks(bottomLinks)}
      sections={mapSectionLinks(sections)}
      partnership={mapPartnership(partnership)}
    />
  );
});

export default FooterComponent;

import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import { RichText, Text } from "@app/components";
import { RootState } from "@app/redux/rootReducer";
import { useSelector } from "react-redux";
import { Document } from "@contentful/rich-text-types";
import { Grid } from "@material-ui/core";

const PrivacySettings = memo(() => {
  const { t } = useTranslation();

  const { extraInfo } = useSelector(
    (state: RootState) => state.localization.localization.privacySettings
  );

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12} md={10}>
        <Text>{t("privacySettings.text")}</Text>
      </Grid>
      <Grid item xs={12} md={10}>
        <RichText cfDocument={extraInfo as Document} />
      </Grid>
    </Grid>
  );
});

export default PrivacySettings;

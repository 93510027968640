import React, { FC, memo } from "react";
// import { ColumnIndentSteps } from "@app/types/layoutDef";
// import { CenterColumn } from "@app/components";
import classnames from "classnames/bind";
import styles from "./Section.module.scss";

const cx = classnames.bind(styles);

export interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  background?: boolean;
  flush?: boolean;
  fullWidth?: boolean;
  withSeparatorSpacing?: boolean;
}

const Section: FC<SectionProps> = memo(
  ({
    className,
    children,
    background,
    flush,
    fullWidth,
    withSeparatorSpacing,
    ...rest
  }) => {
    return (
      <section
        className={cx(
          styles.section,
          {
            fullWidth,
            background,
            flush,
            withSeparatorSpacing,
          },
          className
        )}
        {...rest}
      >
        {children}
      </section>
    );
  }
);

export { Section };

import React, { FC, memo } from "react";
import { Document } from "@contentful/rich-text-types";
import {
  Section,
  Container,
  Heading,
  RichText,
  ItemList,
  Button,
} from "@app/components";
import { Grid } from "@material-ui/core";
import { GenericItemDef } from "@app/types/contentfulTypes";
import styles from "./Qualifications.module.scss";

export interface QualificationsProps {
  text?: Document;
  title: string;
  items?: GenericItemDef[];
  background?: boolean;
  verticalItems?: boolean;
  ctaLabel?: string;
  ctaLink?: string;
}

const Qualifications: FC<QualificationsProps> = memo(
  ({
    title,
    text,
    items = [],
    background,
    verticalItems = false,
    ctaLabel,
    ctaLink,
  }) => {
    const mapSubjects = (itemsList: GenericItemDef[]) => {
      const mappedSubjects = itemsList?.map((item: GenericItemDef) => {
        return {
          fields: {
            ...item.fields,
            text: item.fields.description,
          },
          sys: item.sys,
        };
      });
      return mappedSubjects;
    };

    return (
      <Section withSeparatorSpacing background={background} fullWidth>
        <Container indentStep={verticalItems ? 1 : 3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={verticalItems ? 12 : 9}>
              {!!title && (
                <Heading size="h2" align={verticalItems ? "center" : "left"}>
                  {title}
                </Heading>
              )}
              {text && <RichText className={styles.text} cfDocument={text} />}
            </Grid>
          </Grid>
          <div className={styles.items}>
            {items.length > 0 && (
              <ItemList
                items={mapSubjects(items)}
                listHorizontal
                itemsHorizontal={!verticalItems}
                alignLeft={items.length === 1}
              />
            )}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!!ctaLabel && !!ctaLink && (
                <div className={styles.buttonWrapper}>
                  <Button label={ctaLabel} to={ctaLink} />
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </Section>
    );
  }
);

export default Qualifications;

import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import React, { memo } from "react";
import { Link } from "@app/components";
import CardListContent, {
  CardListContentProps,
} from "../CardListContent/CardListContent";
import styles from "./MiniChildCard.module.scss";
import BookmarkBtn, { BookmarkBtnProps } from "../BookmarkBtn/BookmarkBtn";

interface MiniChildCardProps extends CardListContentProps, BookmarkBtnProps {
  itemId?: string;
}
const MiniChildCard = ({
  title,
  text,
  credits,
  cardType,
  tagIcon,
  itemId,
  onDeleteItem,
  toggleBookmarks,
  bookmarked,
  isBookmarked,
  listView,
  disableBookmarks,
}: MiniChildCardProps) => {
  return (
    <div className={styles.wrapper}>
      {!!itemId && (
        <Link
          to={`${ContentfulPathsEnum.ACTIVITY_DETAIL}/${itemId}`}
          className={styles.link}
        >
          <CardListContent
            classnames={styles.content}
            title={title}
            text={text}
            credits={credits}
            cardType={cardType}
            tagIcon={tagIcon}
            isChild
          />
        </Link>
      )}
      {itemId && (
        <BookmarkBtn
          classnames={styles.bookmarkBtn}
          onDeleteItem={onDeleteItem}
          toggleBookmarks={toggleBookmarks}
          bookmarked={bookmarked}
          isBookmarked={isBookmarked}
          id={itemId}
          listView={listView}
          disableBookmarks={disableBookmarks}
        />
      )}
    </div>
  );
};

export default memo(MiniChildCard);

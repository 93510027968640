import { Heading, Text, Tag } from "@app/components";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import React, { memo, useCallback } from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./CardListContent.module.scss";

export interface CardListContentProps {
  title: string;
  text?: string;
  credits?: string;
  cardType?: ResourceTypeEnum;
  tagIcon: JSX.Element | undefined;
  isChild?: boolean;
  classnames?: string;
}

const CardListContent = ({
  title,
  text,
  credits,
  cardType,
  tagIcon,
  isChild = false,
  classnames,
}: CardListContentProps) => {
  const { t } = useTranslation();

  const translatedResourceLabel = useCallback(
    (card: ResourceTypeEnum) => {
      if (card === ResourceTypeEnum.UNIT) {
        return t("uiLabels.unitsFilterLabel");
      }
      if (card === ResourceTypeEnum.ACTIVITY) {
        return t("uiLabels.activitiesFilterLabel");
      }
      if (card === ResourceTypeEnum.COLLECTION) {
        return t("uiLabels.collectionsFilterLabel");
      }
      return "";
    },
    [t]
  );
  return (
    <div
      className={cx(styles.wrapper, classnames, {
        [styles.rightPadding]: cardType !== "collection",
      })}
    >
      <div>
        <Heading
          size={isChild ? "body1" : "subtitle1"}
          className={styles.title}
        >
          {title}
        </Heading>
        {!!text && (
          <Text small className={styles.text}>
            {text}
          </Text>
        )}
      </div>
      <div className={styles.tags}>
        {cardType && (
          <Tag icon={tagIcon} resource={cardType}>
            {translatedResourceLabel(cardType)}
          </Tag>
        )}
        {!!credits && (
          <Text small className={styles.credits}>
            {credits}
          </Text>
        )}
      </div>
    </div>
  );
};

export default memo(CardListContent);

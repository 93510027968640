import React, { memo, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { FormTheme } from "@app/components/molecules/FormTheme/FormTheme";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { TextField } from "formik-material-ui";
import { useSelector } from "react-redux";
import { Document } from "@contentful/rich-text-types";
import { RootState } from "@app/redux/rootReducer";
import { UserDef } from "@app/types/api.types";
import { useAppDispatch } from "@app/redux/store";
import { updateUser, getUser } from "@app/features/auth/auth";
import {
  FORMFIELD_VARIANT,
  FORMFIELD_LABEL_PROPS,
} from "@app/constants/themeConst";
import { showNotification } from "@app/features/notification/notification";
import { RichText } from "@app/components/atoms/atoms";

interface FormValuesDef {
  firstName: UserDef["firstName"];
  lastName: UserDef["lastName"];
  email: UserDef["email"];
}
const PersonalInfo = memo(() => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { extraInfo } = useSelector(
    (state: RootState) => state.localization.localization.personalInformation
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialValues: FormValuesDef = {
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
  };

  const validation = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .required(t("personalInformation.firstNameErrorRequired")),
        lastName: yup
          .string()
          .required(t("personalInformation.lastNameErrorRequired")),
        email: yup
          .string()
          .email(t("personalInformation.emailErrorInvalid"))
          .required(t("personalInformation.emailErrorRequired")),
      }),
    [t]
  );

  const handleSubmit = async (values: FormValuesDef) => {
    const response = await dispatch(updateUser(values));
    if (updateUser.fulfilled.match(response)) {
      dispatch(getUser());
      dispatch(
        showNotification({
          message: t("personalInformation.successMessage"),
        })
      );
    }
    if (updateUser.rejected.match(response)) {
      dispatch(
        showNotification({
          message: t("personalInformation.errorMessage"),
        })
      );
    }
  };

  return (
    <FormTheme>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validation}
      >
        <Form noValidate>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} md={6}>
              <Field
                disabled
                component={TextField}
                id="firstName"
                name="firstName"
                label={t("personalInformation.firstNameLabel")}
                placeholder={t("personalInformation.firstNamePlaceholder")}
                variant={FORMFIELD_VARIANT}
                InputLabelProps={FORMFIELD_LABEL_PROPS}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                disabled
                component={TextField}
                id="lastName"
                name="lastName"
                label={t("personalInformation.lastNameLabel")}
                placeholder={t("personalInformation.lastNamePlaceholder")}
                variant={FORMFIELD_VARIANT}
                InputLabelProps={FORMFIELD_LABEL_PROPS}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                disabled
                component={TextField}
                id="email"
                name="email"
                label={t("personalInformation.emailLabel")}
                placeholder={t("personalInformation.emailPlaceholder")}
                variant={FORMFIELD_VARIANT}
                InputLabelProps={FORMFIELD_LABEL_PROPS}
              />
            </Grid>
            <Grid item>
              <RichText cfDocument={extraInfo as Document} />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </FormTheme>
  );
});

export default PersonalInfo;

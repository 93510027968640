import React, { FC, memo } from "react";
import classnames from "classnames/bind";
import styles from "./IconBox.module.scss";

export interface IconBoxProps extends React.HTMLAttributes<HTMLElement> {
  value?: number;
  icon?: JSX.Element;
  boxSize?: 48 | 56 | 72;
  alternative?: boolean;
  transparent?: boolean;
  isBookmarked?: boolean;
}

const IconBox: FC<IconBoxProps> = memo(
  ({
    value,
    icon,
    boxSize,
    className,
    alternative,
    transparent,
    isBookmarked,
  }) => {
    const cx = classnames.bind(styles);
    const sizeStyle = boxSize
      ? {
          width: boxSize,
          height: boxSize,
          fontSize: boxSize === 48 ? 24 : 48,
        }
      : undefined;

    return (
      <div
        className={cx(styles.iconWrapper, className, {
          alternative,
          transparent,
          isBookmarked,
        })}
        style={sizeStyle}
      >
        <div className={styles.iconBg} />
        <span className={styles.icon}>{!value && icon ? icon : value}</span>
      </div>
    );
  }
);

export default IconBox;

import { RouteItemDef } from "@app/types/routeDef";
import { ResourcesPathsEnum } from "../constants/resources.paths";

import ResourcesHubScreen from "../screens/ResourcesHubScreen/ResourcesHubScreen";
import MosaicResourcesHubScreen from "../screens/MosaicResourcesHubScreen/MosaicResourcesHubScreen";
import EnterpriseResourcesHubScreen from "../screens/EnterpriseResourcesHubScreen/EnterpriseResourcesHubScreen";

const RESOURCES_HUB_SCREEN: RouteItemDef = {
  id: "library",
  path: ResourcesPathsEnum.LIBRARY,
  component: ResourcesHubScreen,
  contentfulPageKey: "6X0fuXf0pJ6ao5fdJUU2By",
};

const MOSAIC_RESOURCES_HUB_SCREEN: RouteItemDef = {
  id: "mosaic-library",
  path: ResourcesPathsEnum.MOSAIC_LIBRARY,
  component: MosaicResourcesHubScreen,
  // contentfulPageKey: "4X08bha2uj2azGn4Gg9cZy", // staging key
  contentfulPageKey: "4Gt4BtwXplHUqbWTMxqheN", // production key
};

const ENTERPRISE_RESOURCES_HUB_SCREEN: RouteItemDef = {
  id: "enterprise-challenge-library",
  path: ResourcesPathsEnum.ENTERPRISE_LIBRARY,
  component: EnterpriseResourcesHubScreen,
  // contentfulPageKey: "1tx9rn1k7R5jk1lpHlb6Yx", // staging key
  contentfulPageKey: "aFNOoc4ROsLfAv5i0f5iH", // production key
};

const RESOURCES_ROUTES = [
  RESOURCES_HUB_SCREEN,
  MOSAIC_RESOURCES_HUB_SCREEN,
  ENTERPRISE_RESOURCES_HUB_SCREEN,
];

export default RESOURCES_ROUTES;

import { RootState } from "@app/redux/rootReducer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEntriesByContentModel } from "../configs/contentful.client";

function useEntriesByContentModel<T = any>(contentType: string) {
  const [entries, setEntries] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  const { locale } = useSelector((state: RootState) => state.localization);

  async function fetchEntries(type: string) {
    const data = await getEntriesByContentModel(type, locale);
    return data;
  }

  useEffect(() => {
    setLoading(true);
    fetchEntries(contentType)
      .then((result: any) => {
        if (result) {
          setEntries(result.items);
        }
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType]);

  return { entries, loading };
}

export default useEntriesByContentModel;

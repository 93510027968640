import React, { FC, useMemo, useCallback } from "react";
import { Container, Heading, Section } from "@app/components";
import { Grid } from "@material-ui/core";
import { Redirect, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InlineMenuList } from "@app/components/molecules/molecules";
import { setMetaTitle } from "@app/helpers/router.helpers";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import {
  AccountSettingsPathsEnum,
  UserPathsEnum,
} from "../../constants/users.paths";
import Password from "./components/Password/Password";
import EmailNotification from "./components/EmailNotification/EmailNotification";
import PrivacySettings from "./components/PrivacySettings/PrivacySettings";
import styles from "./AccountSettingsScreen.module.scss";

type PageDef = {
  id: string;
  path: string;
  component: React.MemoExoticComponent<() => JSX.Element>;
  label: string;
};

const AccountSettingsScreen: FC = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  const currentLocale = locale.substring(0, 2);

  const { t } = useTranslation();

  const getLink = useCallback(
    (link: string) => {
      return `/${currentLocale}${UserPathsEnum.ACCOUNT_SETTINGS}${link}`;
    },
    [currentLocale]
  );

  const pages = useMemo(
    () =>
      [
        {
          id: "personal-info",
          path: getLink(AccountSettingsPathsEnum.PERSONAL_INFO),
          component: PersonalInfo,
          label: t("accountSettings.personalInfo"),
        },
        {
          id: "password",
          path: getLink(AccountSettingsPathsEnum.PASSWORD),
          component: Password,
          label: t("accountSettings.password"),
        },
        {
          id: "email-notification",
          path: getLink(AccountSettingsPathsEnum.EMAIL_NOTIFICATION),
          component: EmailNotification,
          label: t("accountSettings.emailNotification"),
        },
        {
          id: "privacy-settings",
          path: getLink(AccountSettingsPathsEnum.PRIVACY_SETTINGS),
          component: PrivacySettings,
          label: t("accountSettings.privacySettings"),
        },
      ] as PageDef[],
    [getLink, t]
  );

  return (
    <Section className={styles.section}>
      <Container>
        <Heading size="h1" tag="h1" spacing="large">
          {t("accountSettings.title")}
        </Heading>

        <Grid container spacing={10}>
          <Grid item xs={12} md={3}>
            <InlineMenuList items={pages} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Switch>
              {pages.map(page => (
                <Route
                  key={page.id}
                  exact
                  path={page.path}
                  render={() => {
                    setMetaTitle(
                      `${page.label} | ${t("accountSettings.title")}`
                    );
                    const Component = page.component;
                    return <Component />;
                  }}
                />
              ))}

              <Redirect to={getLink(AccountSettingsPathsEnum.PERSONAL_INFO)} />
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default AccountSettingsScreen;

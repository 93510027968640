import { MediaWrapper, SwiperSlide, Slider } from "@app/components";
import { SwiperBreakpointsDef } from "@app/components/molecules/SwiperCarousel/SwiperCarousel";
import { ScreenSizes } from "@app/constants/breakpoints";
import { ContentfulImage } from "@app/types/contentfulTypes";
import React, { memo } from "react";
import styles from "./ImagesCarousel.module.scss";

interface ImagesCarouselProps {
  title: string;
  images: { fields: { componentName: string; image: ContentfulImage } }[];
}

const ImagesCarousel = ({ title, images = [] }: ImagesCarouselProps) => {
  const swiperBreakpoints: SwiperBreakpointsDef = {
    [ScreenSizes.XS]: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  return (
    <div className={styles.wrapper}>
      <Slider
        breakpoints={swiperBreakpoints}
        navigation={images.length > 1}
        pagination={images.length > 1}
        title={title}
      >
        {images.length > 0 &&
          images.map(image => (
            <SwiperSlide key={image.fields.image.fields.file.fileName}>
              <div className={styles.slideWrapper}>
                <MediaWrapper
                  cfImage={image.fields.image}
                  alt={image.fields.componentName}
                  natural
                />
              </div>
            </SwiperSlide>
          ))}
      </Slider>
    </div>
  );
};

export default memo(ImagesCarousel);

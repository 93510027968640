import * as React from "react";

function SvgContentPaperEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M131.99 86.742L87.5 131.25l-21.875 4.375L70 113.75l44.514-44.508a12.326 12.326 0 0117.448 0l.052.046a12.343 12.343 0 01-.023 17.454zM48.102 118.125h-35a8.75 8.75 0 01-8.75-8.75v-96.25a8.75 8.75 0 018.75-8.75h87.5a8.75 8.75 0 018.75 8.75V52.5M48.102 30.625h35M30.602 56.875h52.5M30.602 83.125h43.75"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgContentPaperEdit = React.memo(SvgContentPaperEdit);
export default MemoSvgContentPaperEdit;

import * as React from "react";

function SvgMonitor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M91.875 135.625h-43.75l4.375-26.25h35l4.375 26.25zM35 135.625h70M4.375 91.875h131.25"
          strokeWidth={8.749995}
        />
        <path d="M4.375 4.375h131.25v105H4.375z" strokeWidth={8.749995} />
      </g>
    </svg>
  );
}

const MemoSvgMonitor = React.memo(SvgMonitor);
export default MemoSvgMonitor;

import React, { memo } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { IconBox } from "@app/components/atoms/atoms";
import {
  IconDelete,
  IconRibbon,
  IconRibbonFilled,
} from "@app/components/atoms/Icon/Icon";
import styles from "./BookmarkBtn.module.scss";

export interface BookmarkBtnProps {
  onDeleteItem?: (e: React.MouseEvent<HTMLElement>, id: string) => void;
  toggleBookmarks: (e: React.MouseEvent<HTMLElement>, id: string) => void;
  isBookmarked?: boolean;
  bookmarked: boolean;
  id: string;
  listView?: boolean;
  disableBookmarks?: boolean;
  classnames?: string;
  bookmarkTitle?: string;
}

const BookmarkBtn = ({
  onDeleteItem,
  toggleBookmarks,
  isBookmarked,
  bookmarked,
  id,
  listView,
  disableBookmarks,
  classnames,
  bookmarkTitle,
}: BookmarkBtnProps) => {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  // eslint-disable-next-line no-nested-ternary
  const tooltipTitle = isAuthenticated
    ? onDeleteItem
      ? t("uiLabels.removeFromBookmarksTooltip")
      : t("uiLabels.addToBookmarksTooltip")
    : t("uiLabels.guestsBookmarksTooltip");

  const analyticsDelete = "delete-resource-bookmark-";
  const analyticsAddBookmark = "add-resource-bookmark-";
  const analyticsAlreadyAddedBookmark = "already-added-resource-bookmark-";

  let analyticsId = "";
  if (onDeleteItem) {
    analyticsId = analyticsDelete;
  } else if (isBookmarked || bookmarked) {
    analyticsId = analyticsAlreadyAddedBookmark;
  } else {
    analyticsId = analyticsAddBookmark;
  }

  return (
    <Tooltip arrow title={`${bookmarkTitle ?? ""}: ${tooltipTitle}`}>
      <button
        id={`${analyticsId}${id}`}
        className={cx(styles.iconButton, classnames, {
          [styles.listViewButton]: listView,
        })}
        aria-label={`${bookmarkTitle}: ${tooltipTitle}`}
        onClick={e =>
          onDeleteItem ? onDeleteItem(e, id) : toggleBookmarks(e, id)
        }
      >
        {onDeleteItem ? (
          <IconBox
            className={styles.iconBox}
            alternative
            icon={<IconDelete />}
          />
        ) : (
          <>
            {disableBookmarks ? null : (
              <IconBox
                className={styles.iconBox}
                isBookmarked={isBookmarked || bookmarked}
                transparent
                icon={
                  isBookmarked || bookmarked ? (
                    <IconRibbonFilled />
                  ) : (
                    <IconRibbon />
                  )
                }
              />
            )}
          </>
        )}
      </button>
    </Tooltip>
  );
};

export default memo(BookmarkBtn);

import React, { FC, memo, useEffect, useState } from "react";
import { Section, Container } from "@app/components";
import qs from "query-string";
import {
  SubjectsListDef,
  getUnits,
  getUnitsAndAppend,
  getActivities,
  getActivitiesAndAppend,
  ResourcesFiltersEnumIDs,
  getQualifications,
  getCollections,
  getCollectionsAndAppend,
} from "@app/features/resources/resources";
import { useAppDispatch } from "@app/redux/store";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { useLocation, useHistory } from "react-router-dom";
import { convertToSlug } from "@app/helpers/utilHelper";
import { ResourcesSorting, ResourcesView } from "@app/types/layoutDef";
import { useMount } from "react-use";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import ResourcesList, {
  MobileFilters,
} from "./components/ResourcesList/ResourcesList";

export interface ResourceHubProps {
  title: string;
  subjects: SubjectsListDef[];
  classNames?: string;
  showFilters: boolean; // For Mosaic Library
  additionalFilters: boolean; // For Enterprise Challenge Library
  isMosaic: boolean; // To fetch activities in Mosaic Library
}

const ResourceHub: FC<ResourceHubProps> = memo(
  ({
    title,
    subjects = [],
    classNames,
    showFilters,
    additionalFilters,
    isMosaic,
  }) => {
    const location = useLocation();

    const history = useHistory();

    const dispatch = useAppDispatch();

    const searchQuery = qs.parse(location.search);

    const { units } = useSelector((state: RootState) => state.units);

    const { activities } = useSelector((state: RootState) => state.activities);

    const [view, setView] = useState<ResourcesView>(
      (searchQuery.view as ResourcesView) ?? "grid"
    );

    const [contentTypeId, setContentTypeId] = useState(
      showFilters ? (searchQuery.contentTypeId as string) ?? "unit" : "activity"
    );

    const [sorting, setSorting] = useState<ResourcesSorting>(
      (searchQuery.sorting as ResourcesSorting) ?? "dateAsc"
    );

    const handleViewChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setView(event.target.value as ResourcesView);
    };

    const handleSortingChange = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setSorting(event.target.value as ResourcesSorting);
    };

    const [resourceTypeId, setResourceTypeId] = useState(
      (searchQuery.type as string) ?? "all"
    );

    const [subjectId, setSubjectId] = useState("");

    const getIndexOfCurrentSubject = () => {
      const query = qs.parse(location.search);

      const current = subjects.find(
        item => convertToSlug(item.name) === query.subject
      );

      return current ? subjects.indexOf(current as SubjectsListDef) : 0;
    };

    const dispatchResources = (id?: string) => {
      const filters = {
        format: view === "list" ? "full" : "card",
        subjectId: id,
        qualificationTypeId:
          resourceTypeId === ResourcesFiltersEnumIDs.ALL ? "" : resourceTypeId,
        sortOrder: sorting.indexOf("Asc") > -1 ? "asc" : "desc",
        sortBy: sorting.indexOf("date") > -1 ? "createdAt" : "title",
      };

      if (contentTypeId === ResourceTypeEnum.UNIT) dispatch(getUnits(filters));
      else if (contentTypeId === ResourceTypeEnum.ACTIVITY)
        dispatch(getActivities({ filter: filters, isMosaic }));
      else if (contentTypeId === ResourceTypeEnum.COLLECTION)
        dispatch(getCollections(filters));
    };

    const handleLoadMore = () => {
      const filters = {
        format: view === "list" ? "full" : "card",
        subjectId,
        qualificationTypeId:
          resourceTypeId === ResourcesFiltersEnumIDs.ALL ? "" : resourceTypeId,
        sortOrder: sorting.indexOf("Asc") > -1 ? "asc" : "desc",
        sortBy: sorting.indexOf("date") > -1 ? "createdAt" : "title",
        page:
          contentTypeId === ResourceTypeEnum.ACTIVITY
            ? activities.meta.current_page + 1
            : units.meta.current_page + 1,
        isMosaic: true, // or false, depending on the context
      };

      if (contentTypeId === ResourceTypeEnum.UNIT)
        dispatch(getUnitsAndAppend(filters));
      else if (contentTypeId === ResourceTypeEnum.ACTIVITY)
        dispatch(getActivitiesAndAppend({ filter: filters, isMosaic }));
      else if (contentTypeId === ResourceTypeEnum.COLLECTION)
        dispatch(getCollectionsAndAppend(filters));
    };

    const subjectFilterClickHandler = (
      index: number,
      id: string,
      name: string,
      type: string,
      resourcesView: ResourcesView
    ) => {
      setSubjectId(id);

      dispatchResources(id);

      const query = {
        subject: convertToSlug(name),
        type,
        view: resourcesView,
        contentTypeId,
        sorting,
      };

      history.replace({
        pathname: location.pathname,
        search: qs.stringify(query),
      });
    };

    const checkSelectedQueries = () => {
      if (searchQuery.subject && searchQuery.subject !== "all") {
        const subjectIndex = getIndexOfCurrentSubject();
        subjectFilterClickHandler(
          subjectIndex,
          subjects[subjectIndex].id,
          subjects[subjectIndex].name,
          resourceTypeId,
          view
        );
      } else {
        subjectFilterClickHandler(-1, "", "all", resourceTypeId, view);
      }
    };

    const clearSubject = () => {
      subjectFilterClickHandler(-1, "", "all", resourceTypeId, view);
    };

    const ClearQualifications = () => {
      setResourceTypeId("all");
    };

    const handleResourceTypeChange = (value: string) => {
      setResourceTypeId(value);
    };

    const handleContentTypeChange = (value: string) => {
      setContentTypeId(value);
    };

    useEffect(() => {
      checkSelectedQueries();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      resourceTypeId,
      contentTypeId,
      sorting,
      view,
      location.search,
      subjectId,
    ]);

    useMount(() => {
      dispatch(getQualifications());
    });

    // Handle filter change for mobile device
    const handleMobileFilterChange = (query: MobileFilters) => {
      const { subject, subjectId: id, ...mQuery } = query;

      const mobileQuery = {
        subject: convertToSlug(subject),
        ...mQuery,
      };

      setSubjectId(id);
      setResourceTypeId(query.type);
      setContentTypeId(query.contentTypeId);

      history.replace({
        pathname: location.pathname,
        search: qs.stringify(mobileQuery),
      });
    };

    return (
      <Section withSeparatorSpacing className={classNames}>
        <Container>
          <ResourcesList
            title={title}
            selectedResourceType={resourceTypeId}
            onResourceTypeChange={handleResourceTypeChange}
            onLoadMore={handleLoadMore}
            view={view}
            onViewChange={handleViewChange}
            subjects={subjects}
            selectedSubjectId={subjectId}
            onSubjectChange={subjectFilterClickHandler}
            selectedContentType={contentTypeId}
            onContentTypeChange={handleContentTypeChange}
            sorting={sorting}
            onSortingChange={handleSortingChange}
            onClearSubject={clearSubject}
            onClearQualifications={ClearQualifications}
            onMobileFilterChange={handleMobileFilterChange}
            showFilters={showFilters}
            additionalFilters={additionalFilters}
          />
        </Container>
      </Section>
    );
  }
);

export default ResourceHub;

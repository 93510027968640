import React, { memo } from "react";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { MediaWrapper, Button } from "@app/components";
import styles from "./Region.module.scss";

export interface RegionDef {
  fields: {
    name: string;
    mapImage?: ContentfulImage;
    regionPageUrl?: string;
  };
  sys: {
    id: string;
  };
}

const Region = ({ fields }: RegionDef) => {
  const { name, mapImage, regionPageUrl } = fields;

  return (
    <div className={styles.region}>
      {mapImage && (
        <MediaWrapper
          className={styles.image}
          cfImage={mapImage}
          title={name}
          natural
        />
      )}
      {!!regionPageUrl && (
        <Button
          arrow
          outline
          small
          className={styles.button}
          label={name}
          to={regionPageUrl}
          tabIndex={-1}
        />
      )}
    </div>
  );
};

export default memo(Region);

import { Heading, Button } from "@app/components";
import CustomCheckbox from "@app/components/atoms/CustomCheckbox/CustomCheckbox";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import React, { memo } from "react";
import _kebabCase from "lodash/kebabCase";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";

import { useTranslation } from "react-i18next";
import styles from "./UnitsFilters.module.scss";

interface UnitsFiltersProps {
  selectedType: string;
  onResourceTypeChange: (value: string) => void;
  onClearQualifications?: () => void;
}

const UnitsFilters = ({
  selectedType,
  onResourceTypeChange,
  onClearQualifications,
}: UnitsFiltersProps) => {
  const { t } = useTranslation();
  const { qualifications } = useSelector(
    (state: RootState) => state.qualifications
  );

  const analyticsContentType = "content-type-filter-";

  return !qualifications ? null : (
    <div className={styles.wrapper}>
      {qualifications.length > 0 && (
        <FormGroup
          role="group"
          aria-label={t("uiLabels.qualificationTypesFilterLabel")}
        >
          <div className={styles.text}>
            <Heading size="subtitle2">
              {t("uiLabels.qualificationTypesFilterLabel")}
            </Heading>

            <Button
              onClick={onClearQualifications}
              label="Clear"
              inverted
              removeBorder
              small
              xSmall
            />
          </div>
          {qualifications.map(filter => {
            const qualificationName = _kebabCase(filter.name);
            return (
              <FormControlLabel
                key={filter.id}
                id={`${analyticsContentType}${qualificationName}`}
                className={styles.checkbox}
                control={
                  <CustomCheckbox
                    name={filter.name}
                    color="primary"
                    label={`${analyticsContentType}${qualificationName}`}
                    checked={filter.id === selectedType}
                    onChange={() => onResourceTypeChange(filter.id)}
                  />
                }
                label={filter.name}
              />
            );
          })}
        </FormGroup>
      )}
    </div>
  );
};

export default memo(UnitsFilters);

import i18next, { DEFAULT_NS } from "../config/localizationConfig";
import {
  TRANSLATION_KEY,
  TRANSLATION_LOCALE_KEY,
} from "../constants/localization.keys";
import { LocalizationDef } from "../types/localizationDef";

/**
 * Stores the translation meta and data in local storage
 */
export const storeTranslation = (
  locale: string,
  translation: LocalizationDef
) => {
  localStorage.setItem(TRANSLATION_LOCALE_KEY, JSON.stringify(locale));
  localStorage.setItem(TRANSLATION_KEY, JSON.stringify(translation));
};

/**
 * Get last stored translation
 */
export const getTranslation = () => {
  const translation = localStorage.getItem(TRANSLATION_KEY);
  if (translation != null) {
    return JSON.parse(translation) as LocalizationDef;
  }
  return null;
};

/**
 * Get last stored locale
 */
export const getLocale = () => {
  const locale = localStorage.getItem(TRANSLATION_LOCALE_KEY);
  if (locale != null) {
    return JSON.parse(locale) as string;
  }
  return null;
};

/**
 * Set localization in i18next
 */
export const setLocalization = async (
  locale: string,
  localization: LocalizationDef
) => {
  if (localization) {
    i18next.addResourceBundle(locale, DEFAULT_NS, localization);

    await i18next.changeLanguage(locale);
  }
};

/**
 * Store translation and set it in i18next
 */
export const updateLocalization = async (
  locale: string,
  localization: LocalizationDef
) => {
  if (localization) {
    storeTranslation(locale, localization);
    await setLocalization(locale, localization);
  }
};

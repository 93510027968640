import { updateUser } from "@app/features/auth/auth";
import { showNotification } from "@app/features/notification/notification";
import { RootState } from "@app/redux/rootReducer";
import { useAppDispatch } from "@app/redux/store";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  FormTheme,
  SwitchToggle,
} from "@app/components/molecules/FormTheme/FormTheme";

const EmailNotification = memo(() => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const response = await dispatch(
      updateUser({ [event.target.name]: event.target.checked })
    );
    let message = "";
    if (updateUser.fulfilled.match(response)) {
      message = t("emailNotification.messageSuccess", {
        status: user?.marketing ? "Unsubscribed" : "Subscribed",
      });
    }
    if (updateUser.rejected.match(response)) {
      message = t("emailNotification.messageFailed");
    }
    dispatch(showNotification({ message }));
    setLoading(false);
  };

  const marketingStatus = user?.marketing ? "On" : "Off";

  return (
    <FormTheme>
      <SwitchToggle
        isDisabled={loading}
        name="marketing"
        isChecked={user?.marketing}
        onChange={handleChange}
        label={`${t("emailNotification.marketingNews")} is ${marketingStatus}`}
      />
    </FormTheme>
  );
});

export default EmailNotification;

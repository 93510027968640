/* eslint-disable */
import { CardFieldsDef } from "@app/types/contentfulTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resourcesApi } from "../api/resources.api";
import { CollectionsFiltersDef } from "../types/resources.types";

interface CollectionsState {
  collections: {
    data: CardFieldsDef[];
    meta: {
      current_page: number;
      last_page: number;
      total: number;
      per_page: number;
    };
  };
  loadingCollections: boolean;
  loadingMoreCollections: boolean;
  error: boolean;
}

const initialState: CollectionsState = {
  collections: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      total: 1,
      per_page: 6,
    },
  },
  loadingCollections: false,
  loadingMoreCollections: false,
  error: false,
};

export const getCollections = createAsyncThunk(
  "collections/getCollections",
  async (filter: CollectionsFiltersDef) => {
    try {
      const response = await resourcesApi.getAllCollections(filter);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const getCollectionsAndAppend = createAsyncThunk(
  "collections/getCollectionsAndAppend",
  async (filter: CollectionsFiltersDef) => {
    try {
      const response = await resourcesApi.getAllCollections(filter);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

const CollectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    clearCollections: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getCollections.pending, state => {
      state.loadingCollections = true;
      state.error = false;
    });

    builder.addCase(getCollections.fulfilled, (state, action) => {
      state.loadingCollections = false;
      state.collections.data = action.payload.data;
      state.collections.meta = action.payload.meta;
      state.error = false;
    });

    builder.addCase(getCollectionsAndAppend.pending, state => {
      state.loadingMoreCollections = true;
      state.error = false;
    });

    builder.addCase(getCollectionsAndAppend.fulfilled, (state, action) => {
      state.loadingMoreCollections = false;
      state.collections.data = [
        ...state.collections.data,
        ...action.payload.data,
      ];
      state.collections.meta = action.payload.meta;
      state.error = false;
    });
  },
});

export const { clearCollections } = CollectionsSlice.actions;

export default CollectionsSlice.reducer;

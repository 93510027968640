import React, { FC, useMemo } from "react";

import { Field, Form, Formik, FormikHelpers } from "formik";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { ApiStatusCodes } from "@app/constants/api.constants";
import { Container, Heading, Section, Button } from "@app/components";
import { FormTheme } from "@app/components/molecules/FormTheme/FormTheme";
import { mapErrorFields } from "@app/helpers/utilHelper";
import { RootState } from "@app/redux/rootReducer";
import { showNotification } from "@app/features/notification/notification";
import useMetaTitle from "@app/hooks/useMetaTitle";
import {
  FORMFIELD_VARIANT,
  FORMFIELD_LABEL_PROPS,
} from "@app/constants/themeConst";

import { contactApi } from "../../api/contact.api";
import { ContactDef } from "../../types/contact.types";
import styles from "./ContactFormScreen.module.scss";

const ContactFormScreen: FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useMetaTitle(t("contactForm.metaTitle"));

  const initialValues: ContactDef = {
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
    subject: "",
    message: "",
  };

  const validation = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .required(t("contactForm.firstNameErrorRequired")),
        lastName: yup.string().required(t("contactForm.lastNameErrorRequired")),
        email: yup
          .string()
          .email(t("contactForm.emailErrorInvalid"))
          .required(t("contactForm.emailErrorRequired")),
        subject: yup.string().required(t("contactForm.subjectErrorRequired")),
        message: yup.string().required(t("contactForm.messageErrorRequired")),
      }),
    [t]
  );

  const handleSubmit = async (
    values: ContactDef,
    actions: FormikHelpers<ContactDef>
  ) => {
    try {
      const response = await contactApi.sendContactForm(values);
      if (response.status === ApiStatusCodes.NO_CONTENT) {
        const message = t("contactForm.successMessage");
        dispatch(showNotification({ message }));
        actions.resetForm();
      }
    } catch (err: any) {
      const { errors } = err.response.data;
      if (errors) {
        actions.setErrors(mapErrorFields(errors));
      }
    }
  };

  return (
    <Section className={styles.section}>
      <Container>
        <Grid container justify="center" spacing={10}>
          <Grid item xs={12} md={6}>
            <Heading size="h1" align="center" spacing="large">
              {t("contactForm.title")}
            </Heading>
            <FormTheme>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
              >
                {({ isSubmitting }) => (
                  <Form noValidate>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          id="firstName"
                          name="firstName"
                          label={t("contactForm.firstNameLabel")}
                          placeholder={t("contactForm.firstNamePlaceholder")}
                          variant={FORMFIELD_VARIANT}
                          InputLabelProps={FORMFIELD_LABEL_PROPS}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          id="lastName"
                          name="lastName"
                          label={t("contactForm.lastNameLabel")}
                          placeholder={t("contactForm.lastNamePlaceholder")}
                          variant={FORMFIELD_VARIANT}
                          InputLabelProps={FORMFIELD_LABEL_PROPS}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          id="email"
                          name="email"
                          label={t("contactForm.emailLabel")}
                          placeholder={t("contactForm.emailPlaceholder")}
                          variant={FORMFIELD_VARIANT}
                          InputLabelProps={FORMFIELD_LABEL_PROPS}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          id="subject"
                          name="subject"
                          label={t("contactForm.subjectLabel")}
                          placeholder={t("contactForm.subjectPlaceholder")}
                          variant={FORMFIELD_VARIANT}
                          InputLabelProps={FORMFIELD_LABEL_PROPS}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          id="message"
                          name="message"
                          label={t("contactForm.messageLabel")}
                          placeholder={t("contactForm.messagePlaceholder")}
                          variant={FORMFIELD_VARIANT}
                          InputLabelProps={FORMFIELD_LABEL_PROPS}
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          className={styles.button}
                          label={t("contactForm.sendButton")}
                          primary
                          type="submit"
                          loading={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </FormTheme>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default ContactFormScreen;

import React, { FC, memo, useEffect, useState } from "react";
import { Card, CardProps } from "@material-ui/core";
import { Link, MediaWrapper } from "@app/components";
import classnames from "classnames/bind";
import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { CardFieldsDef } from "@app/types/contentfulTypes";
import {
  IconCopy,
  IconExpand,
  IconNote,
  IconPen,
} from "@app/components/atoms/Icon/Icon";

import { IconBox } from "@app/components/atoms/atoms";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@app/redux/store";
import { addResourceToBookmarks } from "@app/features/bookmarks/bookmarks";
import { showNotification } from "@app/features/notification/notification";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { useLocation } from "react-router-dom";
import styles from "./Card.module.scss";
import { MissingAccessModal } from "../molecules";
import CardGridContent from "./components/CardGridContent/CardGridContent";
import CardListContent from "./components/CardListContent/CardListContent";
import MiniChildCard from "./components/MiniChildCard/MiniChildCard";
import BookmarkBtn from "./components/BookmarkBtn/BookmarkBtn";

export interface CardDataProps extends Omit<CardProps, "title">, CardFieldsDef {
  sys?: any;
  /**
   * fields from list level (CardList molecule or CategoryList rendering):
   * @resourceType is mapped from "categoryName" in contentFul
   */
  resourceType?: ResourceTypeEnum | undefined;
  onlyTitle?: boolean;
  routeUrl?: string;
  disableBookmarks?: boolean;
  onDeleteItem?: (e: React.MouseEvent<HTMLElement>, id: string) => void;
  listView?: boolean;
  noBorder?: boolean;
}

/**
 * CardComp - local card component, is renamed to Card when exported
 * this is to avoid clash with material ui card component, that is wrapped here.
 */
const CardComp: FC<CardDataProps> = memo(
  ({
    title,
    intro,
    image,
    isNew,
    isFeatured,
    onlyTitle,
    type,
    sys,
    logo,
    credits,
    isBookmarked,
    contentType,
    disableBookmarks = false,
    onDeleteItem,
    listView,
    noBorder = false,
    activities = [],
    units = [],
  }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [cardType, setCardType] = useState<ResourceTypeEnum | undefined>();
    const [routePrefix, setRoutePrefix] = useState("");
    const [bookmarked, setBookmarked] = useState(false);
    const [showMissingAccessModal, setShowMissingAccessModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [tagIcon, setTagIcon] = useState<JSX.Element | undefined>();
    const cx = classnames.bind(styles);
    const hasChildContent =
      (units.length > 0 || activities.length > 0) && cardType !== "activity";

    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    const { bookmarks, loading: bookmarksLoading } = useSelector(
      (state: RootState) => state.bookmarks
    );

    // const useStyles = makeStyles({
    //   root: {
    //     color: MuiThemeSettings.fontColorFront,
    //   },
    // });

    // const classes = useStyles();

    const unitTypeText = type ? `${type} Unit` : undefined;

    const checkForBookmarks = () => {
      if (
        !bookmarksLoading &&
        bookmarks?.data?.length > 0 &&
        !disableBookmarks
      ) {
        const existsInBookmarks = bookmarks.data.filter(
          item => item.id === sys.id
        );
        setBookmarked(existsInBookmarks.length > 0);
      }
    };

    const addToBookmarks = async (id: string) => {
      const response = await dispatch(
        addResourceToBookmarks({ contentfulId: id })
      );
      if (addResourceToBookmarks.fulfilled.match(response)) {
        dispatch(
          showNotification({
            message: t("uiLabels.addedToBookmarks", { title }),
          })
        );
      } else {
        dispatch(
          showNotification({
            message: t("uiLabels.bookmarksErrorMessage"),
          })
        );
      }
    };

    const toggleBookmarks = async (
      e: React.MouseEvent<HTMLElement>,
      id: string
    ) => {
      e.preventDefault();
      if (isAuthenticated && !disableBookmarks) {
        if (isBookmarked || bookmarked) {
          dispatch(
            showNotification({
              message: `${title} is already added to bookmarks.`, // TODO: Add to localization
            })
          );
        } else {
          addToBookmarks(id);
        }
      } else {
        setShowMissingAccessModal(true);
      }
    };

    const currentLibrary = location.pathname.includes(
      ContentfulPathsEnum.MOSAIC_LIBRARY
    )
      ? "mosaic"
      : "achieve";

    useEffect(() => {
      checkForBookmarks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookmarks, bookmarksLoading]);

    useEffect(() => {
      setCardType(sys?.contentType?.sys?.id || contentType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sys.id]);

    useEffect(() => {
      if (cardType === ResourceTypeEnum.ACTIVITY) {
        setRoutePrefix(ContentfulPathsEnum.ACTIVITY_DETAIL);
        setTagIcon(<IconPen />);
      } else if (cardType === ResourceTypeEnum.UNIT) {
        setRoutePrefix(ContentfulPathsEnum.UNIT_DETAIL);
        setTagIcon(<IconNote />);
      } else if (cardType === ResourceTypeEnum.COLLECTION) {
        setRoutePrefix(ContentfulPathsEnum.COLLECTIONS_DETAIL);
        setTagIcon(<IconCopy />);
      }
    }, [cardType]);

    const closeModal = () => {
      setShowMissingAccessModal(false);
    };

    const resourceCard = (
      <Card
        className={cx(
          styles.card,
          // classes.root,
          {
            [styles.listView]: listView,
            [styles.border]: !noBorder,
            [styles.leftBorder]: isExpanded && listView,
            [styles.childContent]: hasChildContent,
          }
        )}
      >
        {sys.id && (
          <BookmarkBtn
            bookmarkTitle={title}
            onDeleteItem={onDeleteItem}
            toggleBookmarks={toggleBookmarks}
            bookmarked={bookmarked}
            isBookmarked={isBookmarked}
            id={sys.id}
            listView={listView}
            disableBookmarks={disableBookmarks}
          />
        )}

        {hasChildContent && listView ? (
          <button
            className={styles.expandButton}
            onClick={e => {
              e.preventDefault();
              setIsExpanded(!isExpanded);
            }}
          >
            <IconBox
              className={styles.iconBox}
              alternative
              icon={
                <IconExpand
                  className={cx(styles.expandIcon, {
                    [styles.expandedIcon]: isExpanded,
                  })}
                />
              }
            />
          </button>
        ) : null}

        {/* TODO: Replace the image null value with the correct placeholder */}
        {!listView ? (
          <>
            {!!routePrefix && !!sys.id && (
              <Link to={`${routePrefix}/${sys.id}`} className={styles.link}>
                <MediaWrapper
                  ratio={0.6}
                  cfImage={
                    image?.fields
                      ? image
                      : { fields: { file: { url: "null" } } }
                  }
                  maxWidth={530}
                />
                <CardGridContent
                  isNew={isNew}
                  isFeatured={isFeatured}
                  cardType={cardType}
                  tagIcon={tagIcon}
                  logo={logo}
                  title={title}
                  intro={intro}
                  onlyTitle={onlyTitle}
                  unitTypeText={unitTypeText}
                  credits={credits}
                />
              </Link>
            )}
          </>
        ) : (
          <>
            {!!routePrefix && !!sys.id && (
              <Link to={`${routePrefix}/${sys.id}`} className={styles.link}>
                <CardListContent
                  title={title}
                  text={intro}
                  credits={credits}
                  cardType={cardType}
                  tagIcon={tagIcon}
                />
              </Link>
            )}

            <div
              className={cx(styles.childrenCards, {
                [styles.showExpanded]: isExpanded,
              })}
            >
              {cardType === "unit" &&
                activities.length > 0 &&
                activities.map(activity => {
                  return (
                    <MiniChildCard
                      itemId={activity.id}
                      title={activity.title}
                      text={activity.intro}
                      credits={activity.credits}
                      cardType={activity.contentType as ResourceTypeEnum}
                      tagIcon={<IconPen />}
                      onDeleteItem={onDeleteItem}
                      toggleBookmarks={toggleBookmarks}
                      bookmarked={false}
                      isBookmarked={activity.isBookmarked}
                      id={sys.id}
                      listView={listView}
                      disableBookmarks={disableBookmarks}
                    />
                  );
                })}
              {cardType === "collection" &&
                units.length > 0 &&
                units.map(unit => {
                  return (
                    <MiniChildCard
                      itemId={unit.id}
                      title={unit.title}
                      text={unit.intro}
                      credits={unit.credits}
                      cardType={unit.contentType as ResourceTypeEnum}
                      tagIcon={<IconNote />}
                      onDeleteItem={onDeleteItem}
                      toggleBookmarks={toggleBookmarks}
                      bookmarked={unit.isBookmarked ?? false}
                      isBookmarked={isBookmarked}
                      id={sys.id}
                      listView={listView}
                      disableBookmarks={disableBookmarks}
                    />
                  );
                })}
            </div>
          </>
        )}
      </Card>
    );

    return (
      <>
        {resourceCard}

        <MissingAccessModal
          open={showMissingAccessModal}
          onClose={closeModal}
          currentLibrary={currentLibrary}
        />
      </>
    );
  }
);

export { CardComp as Card };

import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Jumbotron, {
  JumbotronProps,
} from "@app/components/renderings/Jumbotron/Jumbotron";
import { bookmarksAPILimit } from "@app/constants/api.constants";
import { getBookmarks } from "@app/features/bookmarks/bookmarks";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { RootState } from "@app/redux/rootReducer";
import { ThemeEnum } from "@app/constants/themeConst";
import { useAppDispatch } from "@app/redux/store";
import { useSingleEntry } from "@app/features/contentful/contentful";
import {
  getUnits,
  clearUnits,
  getActivities,
  clearActivities,
} from "@app/features/resources/resources";
import { CardFieldsDef } from "@app/types/contentfulTypes";
import { mapAPIListToContentful } from "@app/helpers/utilHelper";
import Renderings from "@app/components/renderings";
import ResourcesList from "@app/components/renderings/ResourcesList/ResourcesList";

import MetaTagsWrapper from "@app/components/molecules/MetaTagsWrapper/MetaTagsWrapper";
import styles from "./DetailScreen.module.scss";

const DetailScreen = () => {
  const [arrEntries, setArrEntries] = useState<[string, any][]>();
  const [jumbotronData, setJumbotronData] = useState<JumbotronProps>();
  const [relatedUnits, setRelatedUnits] = useState<CardFieldsDef[]>([]);
  const [relatedActivities, setRelatedActivities] = useState<CardFieldsDef[]>(
    []
  );
  const [unitsByActivity, setUnitsByActivity] = useState<CardFieldsDef[]>([]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id: resourceId } = useParams<{ id: string }>();

  const { entry, loading } = useSingleEntry(resourceId, true);

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const { units, loading: loadingUnits } = useSelector(
    (state: RootState) => state.units
  );
  const { activities, loadingActivities } = useSelector(
    (state: RootState) => state.activities
  );

  const resourceType = entry?.sys?.contentType?.sys?.id;
  const subjectId = entry?.subjects ? entry.subjects[0].sys.id : "";

  const getRelatedUnits = () => {
    clearUnits();

    if (resourceType === ResourceTypeEnum.UNIT) {
      if (subjectId !== "") {
        const filters = {
          format: "card", // Default for Lists
          subjectId,
        };
        dispatch(getUnits(filters));
      }
    } else {
      setRelatedUnits([]);
    }
  };

  useEffect(() => {
    if (resourceId !== "") {
      if (resourceType === ResourceTypeEnum.UNIT) {
        const filteredList = units.data.filter(item => item.id !== resourceId);
        setRelatedUnits(filteredList);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, loadingUnits]);

  const getRelatedActivities = () => {
    clearActivities();

    if (resourceType === ResourceTypeEnum.ACTIVITY) {
      if (subjectId !== "") {
        const filters = {
          format: "card", // Default for Lists
          subjectId,
        };
        dispatch(getActivities({ filter: filters, isMosaic: true }));
      }
    } else {
      setRelatedActivities([]);
    }
  };

  useEffect(() => {
    if (resourceId !== "") {
      if (resourceType === ResourceTypeEnum.ACTIVITY) {
        const filteredList = activities.data.filter(
          item => item.id !== resourceId
        );
        setRelatedActivities(filteredList);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, loadingActivities]);

  const getUnitsByActivity = () => {
    clearUnits();

    if (resourceType === ResourceTypeEnum.ACTIVITY) {
      if (subjectId !== "") {
        const filters = {
          format: "card", // Default for Lists
          activityId: resourceId,
        };
        dispatch(getUnits(filters));
      }
    } else {
      setUnitsByActivity([]);
    }
  };

  useEffect(() => {
    if (resourceId !== "") {
      if (resourceType === ResourceTypeEnum.ACTIVITY) {
        setUnitsByActivity(units?.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, loadingUnits]);

  useEffect(() => {
    if (isAuthenticated) dispatch(getBookmarks({ limit: bookmarksAPILimit }));
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    /**
     * Composition of fields for the JumboTron on detail pages
     */
    if (entry && !loading) {
      setJumbotronData({
        theme: ThemeEnum.DEFAULT,
        title: entry.title,
        description: entry.description,
        image: entry.image,
        resourceType: entry.sys.contentType.sys.id,
        type: entry.type,
        duration: entry.duration,
        logo: entry.logo,
        credits: entry.credits,
        isNew: entry.isNew,
        isFeatured: entry.isFeatured,
      });

      /**
       * Make an array out of the object that has
       * rendering-component-names as keys
       */
      setArrEntries(Object.entries(entry));
      getRelatedUnits();
      getRelatedActivities();
      getUnitsByActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, loading]);

  const metaTitle = `${entry?.title} | The Prince's Trust`;

  return (
    <>
      <MetaTagsWrapper
        title={metaTitle}
        description={entry?.description}
        imageURL={entry?.image?.fields.file.url}
      />
      {jumbotronData && (
        <Jumbotron
          {...jumbotronData}
          detailMode
          parentUnit={mapAPIListToContentful(unitsByActivity)[0]}
        />
      )}

      <div className={styles.wrapper}>
        {/* Loop through entries from contentful and match an entry to a rendering */}
        {arrEntries &&
          arrEntries.map(([entryName, data]) => {
            if (!entryName) return null;

            const RenderingsComponent = Renderings[entryName];

            return RenderingsComponent ? (
              <RenderingsComponent
                key={entryName}
                data={data}
                {...data.fields}
              />
            ) : null;
          })}

        {entry?.collectionUnits &&
          entry.collectionUnits.length > 0 &&
          entry.collectionUnits.map((item: any) => (
            <ResourcesList
              classname={styles.collectionUnits}
              {...item.fields}
              useSlider
              centered
            />
          ))}

        {!loading && !loadingUnits && relatedUnits.length > 0 && (
          <ResourcesList
            title={t("components.relatedUnitsTitle")}
            data={mapAPIListToContentful(relatedUnits)}
            useSlider
            centered
            classname={styles.related}
            loading={loadingUnits}
          />
        )}

        {!loading && !loadingActivities && relatedActivities.length > 0 && (
          <ResourcesList
            title={t("components.relatedActivitiesTitle")}
            data={mapAPIListToContentful(relatedActivities)}
            useSlider
            centered
            classname={styles.related}
            loading={loadingActivities}
          />
        )}
      </div>
    </>
  );
};

export default memo(DetailScreen);

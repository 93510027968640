import * as React from "react";

function SvgBookOpen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M66.838 124.07a9.333 9.333 0 006.33 0 79.683 79.683 0 0150.75-2.614 9.374 9.374 0 0011.701-9.094V28.747a9.374 9.374 0 00-6.533-8.914A80.955 80.955 0 0073.167 21a9.392 9.392 0 01-6.329 0 80.972 80.972 0 00-55.924-1.167 9.374 9.374 0 00-6.539 8.914v83.615a9.374 9.374 0 0011.702 9.094 79.7 79.7 0 0150.761 2.613zM70 21.478v103.145"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgBookOpen = React.memo(SvgBookOpen);
export default MemoSvgBookOpen;

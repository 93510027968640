import React, { FC, memo } from "react";
import { Heading, MediaWrapper, Text } from "@app/components";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  IconFacebook,
  IconInstagram,
  IconTwitter,
} from "@app/components/atoms/Icon/Icon";
import styles from "./Partnership.module.scss";
import SocialLink from "../SocialLink/SocialLink";

export interface ParntershipProps {
  title: string;
  text: string;
  logos: { logo: ContentfulImage; title: string }[];
  socialLinks: {
    facebookLink: string;
    instagramLink: string;
    twitterLink: string;
  };
}

const Partnership: FC<ParntershipProps> = memo(
  ({ title, text, logos, socialLinks }) => {
    const theme = useTheme();
    const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

    return (
      <div className={styles.wrapper}>
        {matchesDesktop && (
          <>
            {!!title && (
              <Heading size="h6" className={styles.title}>
                {title}
              </Heading>
            )}
            {logos.length > 0 &&
              logos.map(item => (
                <MediaWrapper
                  key={item.title}
                  className={styles.logo}
                  cfImage={item.logo}
                  title={item.title}
                  natural
                />
              ))}
          </>
        )}

        {!matchesDesktop && (
          <>
            {logos.length > 1 && (
              <MediaWrapper
                className={styles.logo}
                cfImage={logos[1].logo}
                natural
              />
            )}
            {!!text && <Text className={styles.text}>{text}</Text>}
          </>
        )}

        {socialLinks && (
          <ul className={styles.socialLinks}>
            {!!socialLinks.instagramLink && (
              <SocialLink
                title="Instagram"
                link={socialLinks.instagramLink}
                icon={<IconInstagram />}
              />
            )}
            {!!socialLinks.twitterLink && (
              <SocialLink
                title="Twitter"
                link={socialLinks.twitterLink}
                icon={<IconTwitter />}
              />
            )}
            {!!socialLinks.facebookLink && (
              <SocialLink
                title="Facebook"
                link={socialLinks.facebookLink}
                icon={<IconFacebook />}
              />
            )}
          </ul>
        )}
      </div>
    );
  }
);

export default Partnership;

import React, { FC, memo } from "react";
import arrow from "@app/assets/images/jumbotronArrow.png";
import styles from "./JumbotronBackground.module.scss";

const JumbotronBackground: FC = memo(() => {
  return (
    <div className={styles.section}>
      <div
        className={styles.arrow}
        style={{ backgroundImage: `url(${arrow})` }}
      />
    </div>
  );
});

export default JumbotronBackground;

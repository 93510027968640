import { RootState } from "@app/redux/rootReducer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEntry } from "../configs/contentful.client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useSingleEntry<T = any>(entryID: string, includeSystem = false) {
  // TODO: implement types from Contentful
  // https://github.com/intercom/contentful-typescript-codegen
  const [entry, setEntry] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  const { locale } = useSelector((state: RootState) => state.localization);

  async function fetchEntry(id: string) {
    const data = await getEntry(id, {
      include: 2,
      locale,
    });
    return data;
  }
  useEffect(() => {
    setLoading(true);
    fetchEntry(entryID)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result: any) => {
        if (result) {
          !includeSystem
            ? setEntry(result.fields)
            : setEntry({ ...result.fields, sys: result.sys });
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryID]);

  return { entry, loading };
}
export default useSingleEntry;

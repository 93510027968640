import React, { FC, memo } from "react";
import { Grid } from "@material-ui/core";
import { GenericItemDef } from "@app/types/contentfulTypes";
import classnames from "classnames/bind";
import { Text } from "@app/components";
import * as iconList from "@app/components/atoms/Icon/Icon";
import styles from "./ItemList.module.scss";

const cx = classnames.bind(styles);

type MediaObjectListProps = {
  items: GenericItemDef[] | undefined;
  listHorizontal?: boolean;
  itemsHorizontal?: boolean;
  numbers?: boolean;
  alignLeft?: boolean;
};

const ItemList: FC<MediaObjectListProps> = memo(
  ({
    items = [],
    listHorizontal = true,
    itemsHorizontal = false,
    numbers,
    alignLeft,
  }) => {
    return (
      <Grid
        container
        justify={!alignLeft ? "center" : undefined}
        className={cx(styles.root, {
          listHorizontal,
          listVertical: !listHorizontal,
        })}
        direction={listHorizontal ? "row" : "column"}
        spacing={4}
      >
        {items?.length > 0 &&
          items.map(
            ({ fields: { title, text, icon = "IconCircle" }, sys }, index) => {
              const Icon = (iconList as any)[icon];
              return (
                <Grid
                  item
                  key={sys.id}
                  sm={listHorizontal && (itemsHorizontal ? 6 : 4)}
                  className={cx(styles.item, {
                    itemsHorizontal,
                    itemsVertical: !itemsHorizontal,
                  })}
                >
                  <div
                    className={cx(styles.iconWrapper, {
                      numbers,
                    })}
                  >
                    <div className={styles.iconBg} />
                    <span className={styles.icon}>
                      {!numbers && icon !== "" && Icon ? <Icon /> : index + 1}
                    </span>
                  </div>
                  <div className={styles.itemText}>
                    <Text
                      tag="h3"
                      strong
                      align={itemsHorizontal ? "left" : "center"}
                      lead
                    >
                      {title}
                    </Text>
                    {text && (
                      <Text align={itemsHorizontal ? "left" : "center"}>
                        {text}
                      </Text>
                    )}
                  </div>
                </Grid>
              );
            }
          )}
      </Grid>
    );
  }
);

export { ItemList };

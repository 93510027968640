import React, { FC, memo, useState, useRef } from "react";

import { Backdrop, Paper } from "@material-ui/core";
import { useClickAway, useLocation } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames/bind";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { Link, Button } from "@app/components";
import { LoadingSpinner, Text } from "@app/components/atoms/atoms";
import { logout } from "@app/features/auth/auth";
import { RootState } from "@app/redux/rootReducer";
import { UserPathsEnum } from "@app/features/users/users";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  getLocalization,
  LocalesEnum,
  setLocale,
} from "@app/features/localization/localization";
import { PiGlobeBold } from "react-icons/pi";
import styles from "./NavigationProfile.module.scss";

const cx = classnames.bind(styles);

const NavigationProfile: FC = memo(() => {
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const location = useLocation();

  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { locale: currentLocale } = useSelector(
    (state: RootState) => state.localization
  );

  const languageSwitch = (locale: string) => {
    dispatch(setLocale(locale));
    dispatch(getLocalization(locale));
    history.push({
      pathname: `/${locale.substring(0, 2)}${location.pathname?.substring(3)}`,
      search: location.search,
    });
  };

  const logOut = async (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setLoadingLogout(true);
    try {
      await dispatch(logout());
      history.replace("/");
    } finally {
      setLoadingLogout(false);
    }
  };

  useClickAway(menuRef, () => {
    setOpenMenu(false);
  });

  return (
    <div className={styles.navButtons}>
      <PiGlobeBold className={styles.globeIcon} />
      <Button
        label={t("uiLabels.languageSwitcher")}
        outline
        small
        className={styles.langBtn}
        onClick={() =>
          languageSwitch(
            currentLocale === LocalesEnum.ENGLISH
              ? LocalesEnum.WELSH
              : LocalesEnum.ENGLISH
          )
        }
        style={{ color: "#1c1859" }}
      />
      <button
        ref={menuRef}
        className={styles.profile}
        onClick={() => setOpenMenu(!openMenu)}
        tabIndex={0}
      >
        <div className={styles.user}>
          <div className={styles.title}>{user?.firstName}</div>
          <TiArrowSortedDown className={styles.icon} />
        </div>

        <div className={cx(styles.menuWrapper, { openMenu })}>
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <Link
                className={styles.link}
                to={UserPathsEnum.DASHBOARD}
                tabIndex={openMenu || !matchesDesktop ? 0 : -1}
              >
                {t("components.navigationDashboardLabel")}
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                className={styles.link}
                to={UserPathsEnum.ACCOUNT_SETTINGS}
                tabIndex={openMenu || !matchesDesktop ? 0 : -1}
              >
                {t("components.userDropdownAccountSettings")}
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                className={styles.link}
                to={ContentfulPathsEnum.FAQ}
                tabIndex={openMenu || !matchesDesktop ? 0 : -1}
              >
                {t("components.userDropdownSupport")}
              </Link>
            </li>
            <button
              className={`${styles.menuItem} ${styles.menuButton}`}
              onClick={logOut}
              tabIndex={openMenu || !matchesDesktop ? 0 : -1}
            >
              <span className={styles.link}>
                {t("components.userDropdownSignOut")}
              </span>
            </button>
          </ul>
        </div>
      </button>
      <Backdrop className={styles.logoutBackdrop} open={loadingLogout}>
        <Paper className={styles.logoutPaper}>
          <Text>Please wait...</Text>
          <br />
          <LoadingSpinner />
        </Paper>
      </Backdrop>
    </div>
  );
});

export default NavigationProfile;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NotificationState {
  message: string;
  show: boolean;
}

const initialState: NotificationState = {
  message: "",
  show: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification(
      state,
      action: PayloadAction<Pick<NotificationState, "message">>
    ) {
      state.message = action.payload.message;
      state.show = true;
    },
    clearNotification: () => initialState,
  },
});

export const { showNotification, clearNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;

import React, { memo } from "react";
import { Heading, MediaWrapper, Text, Link } from "@app/components";
import { Grid } from "@material-ui/core";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./Partner.module.scss";

export interface PartnerProps {
  sys?: {
    id: string;
  };
  fields: {
    name: string;
    logoImage: ContentfulImage;
    subtitle?: string;
    description?: string;
    link?: string;
  };
}

const Partner = ({ fields }: PartnerProps) => {
  const imageWrapper = (
    <MediaWrapper
      className={styles.detailedLogo}
      cfImage={fields.logoImage}
      natural
    />
  );

  const textWrapper = (
    <div className={styles.textWrapper}>
      <Heading size="h3">{fields.name}</Heading>
      <Heading className={styles.subtitle} size="subtitle2">
        {fields.subtitle}
      </Heading>
      <Text className={styles.text}>{fields.description}</Text>
    </div>
  );
  return (
    <>
      <Grid xs={12} lg={4} item>
        {fields.link ? (
          <Link to={fields.link}>{imageWrapper}</Link>
        ) : (
          imageWrapper
        )}
      </Grid>
      <Grid xs={12} lg={6} item>
        {fields.link ? (
          <Link className={styles.link} to={fields.link}>
            {textWrapper}
          </Link>
        ) : (
          textWrapper
        )}
      </Grid>
    </>
  );
};

export default memo(Partner);

import React, { FC, memo, ReactElement } from "react";
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { SelectDataDef } from "@app/types/api.types";
import {
  FORMFIELD_VARIANT,
  FORMFIELD_LABEL_PROPS,
} from "@app/constants/themeConst";
import styles from "../../FormTheme.module.scss";

/**
 * FORM FIELD
 * A wrapper around material ui TextField component
 * should be used inside a FormTheme wrapper component to ensure consistent design
 */

export type TextFieldProps = {
  id?: string;
  label: string | ReactElement;
  name: string;
  placeholder?: string;
  value?: string | number | undefined | null;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  type?: string;
  internalType?: "singleLine" | "select" | "checkbox";
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // specific for select:
  items?: SelectDataDef[];
  // specific for checkbox
  checked?: boolean;
};

const FormField: FC<TextFieldProps> = memo(
  ({
    id,
    label,
    name,
    placeholder,
    value,
    required,
    error,
    helperText,
    type = "text",
    internalType = "singleLine",
    onChange,
    items,
    checked,
  }) => {
    /**
     *
     * SINGLE LINE INPUT
     * fields (also password, email etc)
     *
     * */
    if (internalType === "singleLine")
      return (
        <TextField
          id={id}
          name={name}
          type={type}
          label={label}
          placeholder={placeholder}
          value={value}
          variant={FORMFIELD_VARIANT}
          InputLabelProps={FORMFIELD_LABEL_PROPS}
          className={styles.field}
          required={required}
          error={error}
          helperText={helperText}
          onChange={onChange}
        />
      );
    /**
     *
     * Compare values fro sorting
     *
     */
    const compareValues = (a: SelectDataDef, b: SelectDataDef) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    };

    const mapItems = (itemsArr: SelectDataDef[]) => {
      const sorteditems = itemsArr?.sort(compareValues);
      const indexOfOther = sorteditems.findIndex(
        item => item.value.toString().indexOf("Other") > -1
      );
      sorteditems.push(...sorteditems.splice(indexOfOther, 1));
      return sorteditems;
    };

    /**
     *
     * SELECT
     *
     */
    if (internalType === "select")
      return (
        <div className={styles.field}>
          <TextField
            id={id}
            name={name}
            select
            label={label}
            value={value}
            placeholder={placeholder}
            variant={FORMFIELD_VARIANT}
            required={required}
            error={error}
            helperText={helperText}
            onChange={onChange}
            SelectProps={{
              MenuProps: { className: styles.dropDown },
            }}
            className={value === 0 ? "select-placeholder" : undefined}
          >
            <MenuItem value={0}>Choose</MenuItem>
            {items &&
              mapItems(items).map(({ id: itemId, value: itemValue }) => {
                return (
                  <MenuItem key={itemId} value={itemId}>
                    {itemValue}
                  </MenuItem>
                );
              })}
          </TextField>
        </div>
      );

    if (internalType === "checkbox")
      return (
        <div className={styles.field}>
          <FormControlLabel
            control={
              <Checkbox
                name={name}
                id={id}
                color="primary"
                checked={checked}
                onChange={onChange}
              />
            }
            label={label}
          />
        </div>
      );

    return null;
  }
);

export { FormField };

import React, { FC, memo } from "react";
import { Link } from "@app/components";
import logo from "@app/assets/images/logo.png";
import { useLocation } from "react-router-dom";
import styles from "./NavigationLogo.module.scss";
import SkipNavButton from "../SkipNavButton/SkipNavButton";

const NavigationLogo: FC = memo(() => {
  const location = useLocation();

  const image = <img src={logo} alt="Prince's Trust logo home" />;
  return (
    <>
      <SkipNavButton />
      <div className={styles.logo}>
        {/* TODO: set logo in contentful */}
        {location.pathname === "/" ? image : <Link to="/">{image}</Link>}
      </div>
    </>
  );
});

export default NavigationLogo;

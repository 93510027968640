import React, { memo } from "react";
import { Card } from "@material-ui/core";
import { CardDef } from "@app/types/contentfulTypes";
import { Heading, Text, MediaWrapper, Link } from "@app/components";
import format from "date-fns/format";
import styles from "./EventCard.module.scss";

const EventCard = ({ fields }: CardDef) => {
  const { title, description, image, location, dateAndTime, link } = fields;

  const cardWrapper = (
    <Card className={styles.card}>
      {image && (
        <MediaWrapper
          ratio={0.75}
          cfImage={
            image?.fields ? image : { fields: { file: { url: "null" } } }
          }
          maxWidth={530}
        />
      )}
      <div className={styles.textWrapper}>
        {!!title && (
          <Heading size="subtitle1" className={styles.title}>
            {title}
          </Heading>
        )}
        {!!description && (
          <Text className={styles.description}>{description}</Text>
        )}
        {!!dateAndTime && (
          <Text className={styles.date}>
            {format(new Date(dateAndTime), "eee, LLL d, HH:mm")}
          </Text>
        )}
        {!!location && <Text className={styles.location}>{location}</Text>}
      </div>
    </Card>
  );

  return link ? (
    <Link to={link} className={styles.link}>
      {cardWrapper}
    </Link>
  ) : (
    cardWrapper
  );
};

export default memo(EventCard);

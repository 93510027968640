import { Section, Container, Heading, Text } from "@app/components";
import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import styles from "./RegionalComponent.module.scss";
import Region, { RegionDef } from "./components/Region/Region";

export interface RegionalComponentProps {
  title: string;
  subtitle?: string;
  regions: RegionDef[];
}
const RegionalComponent = ({
  title,
  subtitle,
  regions = [],
}: RegionalComponentProps) => {
  return (
    <Section background withSeparatorSpacing fullWidth>
      <Container indentStep={3}>
        {!!title && (
          <div className={styles.titleWrapper}>
            <Heading size="h2">{title}</Heading>
          </div>
        )}
        {!!subtitle && (
          <div className={styles.subtitle}>
            <Text>{subtitle}</Text>
          </div>
        )}
        {regions.length > 0 && (
          <Grid container spacing={1} className={styles.container}>
            {regions.map(region => (
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                className={styles.itemWrapper}
                key={region.sys.id}
              >
                <Region {...region} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Section>
  );
};

export default memo(RegionalComponent);

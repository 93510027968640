import React, { FC, memo } from "react";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { Image } from "@app/components";
import classnames from "classnames/bind";
import styles from "./MediaWrapper.module.scss";

const cx = classnames.bind(styles);

interface MediaProps extends React.HTMLAttributes<HTMLImageElement> {
  /**
   * @cfImage Image object from contentful
   */
  cfImage?: ContentfulImage;
  /**
   * @src image / media src => use for non-contentful images
   */
  src?: string;
  alt?: string;
  title?: string;
  className?: string;
  innerClass?: string;
  ratio?: number;
  natural?: boolean;
  walledOnSmall?: boolean;
  activeLoad?: boolean;
  visibleByDefault?: boolean;
  maxWidth?: number;
  fillContainer?: boolean;
}

const MediaWrapper: FC<MediaProps> = memo(
  ({
    cfImage,
    alt,
    title,
    src,
    className,
    innerClass,
    ratio = 9 / 16,
    natural,
    walledOnSmall,
    activeLoad,
    visibleByDefault,
    maxWidth,
    fillContainer,
  }) => {
    const imageProps = {
      src,
      alt,
      title,
      className: innerClass,
    };

    return (
      <div
        className={cx(
          styles.media,
          {
            ratio: !natural || !fillContainer,
            walledOnSmall,
            fillContainer,
            natural,
          },
          className
        )}
      >
        <Image
          cfImage={cfImage}
          {...imageProps}
          className={cx({
            ratioImage: !natural,
          })}
          activeLoad={activeLoad}
          visibleByDefault={visibleByDefault}
          loadEffectInWrapper={!natural}
          maxWidth={maxWidth}
        />
        {!natural && (
          <span
            className={styles.ratioPadding}
            style={{ paddingBottom: `${ratio * 100}%` }}
          />
        )}
      </div>
    );
  }
);

export { MediaWrapper };

import React, { FC, memo } from "react";
import { NavLink } from "react-router-dom";
import { Text } from "@app/components";
import styles from "./InlineMenuList.module.scss";

export interface InlineMenuListProps {
  items: { path: string; label: string }[];
}

const InlineMenuList: FC<InlineMenuListProps> = memo(({ items = [] }) => {
  return (
    <div className={styles.list}>
      {items.map(item => (
        <NavLink
          key={item.path}
          to={item.path}
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          <Text strong>{item.label}</Text>
        </NavLink>
      ))}
    </div>
  );
});

export { InlineMenuList };

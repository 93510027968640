import { Heading, Tag } from "@app/components";
import CustomCheckbox from "@app/components/atoms/CustomCheckbox/CustomCheckbox";
import { IconCopy, IconNote, IconPen } from "@app/components/atoms/Icon/Icon";
import VoiceAlert from "@app/components/atoms/VoiceAlert/VoiceAlert";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TypesFilters.module.scss";

interface TypesFiltersProps {
  selectedContentType: string;
  onContentTypeChange: (value: string) => void;
}

const TypesFilters = ({
  selectedContentType,
  onContentTypeChange,
}: TypesFiltersProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <FormGroup role="group" aria-label={t("uiLabels.contentTypesLabel")}>
        <VoiceAlert />
        <Heading size="subtitle2" paragraph>
          {t("uiLabels.contentTypesLabel")}
        </Heading>

        <FormControlLabel
          id="collections-filter"
          className={styles.checkbox}
          control={
            <CustomCheckbox
              name="collections-filter"
              label="collections-filter"
              color="primary"
              checked={selectedContentType === ResourceTypeEnum.COLLECTION}
              onChange={() => onContentTypeChange(ResourceTypeEnum.COLLECTION)}
            />
          }
          label={
            <Tag icon={<IconCopy />} resource={ResourceTypeEnum.COLLECTION}>
              {t("uiLabels.collectionsFilterLabel")}
            </Tag>
          }
        />
        <FormControlLabel
          id="units-filter"
          className={styles.checkbox}
          control={
            <CustomCheckbox
              name="units-filter"
              label="units-filter"
              color="primary"
              checked={selectedContentType === ResourceTypeEnum.UNIT}
              onChange={() => onContentTypeChange(ResourceTypeEnum.UNIT)}
            />
          }
          label={
            <Tag icon={<IconNote />} resource={ResourceTypeEnum.UNIT}>
              {t("uiLabels.unitsFilterLabel")}
            </Tag>
          }
        />
        <FormControlLabel
          id="activities-filter"
          className={styles.checkbox}
          control={
            <CustomCheckbox
              name="activities-filter"
              label="activities-filter"
              color="primary"
              checked={selectedContentType === ResourceTypeEnum.ACTIVITY}
              onChange={() => onContentTypeChange(ResourceTypeEnum.ACTIVITY)}
            />
          }
          label={
            <Tag icon={<IconPen />} resource={ResourceTypeEnum.ACTIVITY}>
              {t("uiLabels.activitiesFilterLabel")}
            </Tag>
          }
        />
      </FormGroup>
    </div>
  );
};

export default memo(TypesFilters);

import React, { FC, memo, useMemo } from "react";

import { Field, Form, Formik, FormikHelpers } from "formik";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  Button,
  Container,
  FormTheme,
  Heading,
  Section,
  Text,
} from "@app/components";
import {
  FORMFIELD_VARIANT,
  FORMFIELD_LABEL_PROPS,
} from "@app/constants/themeConst";
import { forgotPassword } from "@app/features/auth/auth";
import { showNotification } from "@app/features/notification/notification";
import { useAppDispatch } from "@app/redux/store";
import { ForgotPasswordDef } from "@app/types/api.types";
import useMetaTitle from "@app/hooks/useMetaTitle";

const ForgotPasswordScreen: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useMetaTitle(t("forgotPassword.metaTitle"));

  const initialValues: ForgotPasswordDef = {
    username: "",
  };

  const validation = useMemo(
    () =>
      yup.object({
        username: yup
          .string()
          .email()
          .required(t("forgotPassword.emailRequired")),
      }),
    [t]
  );

  const handleSubmit = async (
    values: ForgotPasswordDef,
    actions: FormikHelpers<ForgotPasswordDef>
  ) => {
    const response = await dispatch(forgotPassword(values));
    let message = "";
    if (forgotPassword.fulfilled.match(response)) {
      message = t("forgotPassword.messageSuccess");
      actions.resetForm();
    } else if (forgotPassword.rejected.match(response)) {
      message = t("forgotPassword.messageFailed");
    }

    dispatch(showNotification({ message }));
  };

  return (
    <>
      <Section withSeparatorSpacing>
        <Container indentStep={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Heading>{t("forgotPassword.heading")}</Heading>
              <Text>{t("forgotPassword.description")}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTheme>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validation}
                >
                  {({ isSubmitting }) => (
                    <Form noValidate>
                      <Field
                        component={TextField}
                        type="email"
                        id="username"
                        name="username"
                        label={t("forgotPassword.emailLabel")}
                        placeholder={t("forgotPassword.emailPlaceholder")}
                        required
                        variant={FORMFIELD_VARIANT}
                        InputLabelProps={FORMFIELD_LABEL_PROPS}
                      />
                      <Button
                        type="submit"
                        label={t("forgotPassword.buttonLabel")}
                        loading={isSubmitting}
                      />
                    </Form>
                  )}
                </Formik>
              </FormTheme>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
});

export default ForgotPasswordScreen;

import React, { FC, memo } from "react";

import classnames from "classnames/bind";

import { CircularProgress } from "@material-ui/core";
import styles from "./LoadingSpinner.module.scss";

const cx = classnames.bind(styles);

export interface LoadingSpinnerProps {
  className?: string;
  isFullscreen?: boolean;
}

/**
 * Loading Spinner
 */
const LoadingSpinner: FC<LoadingSpinnerProps> = memo(
  ({ className, isFullscreen }) => {
    return (
      <div
        className={cx(styles.container, className, {
          isFullscreen,
        })}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  }
);

export default LoadingSpinner;

import React, { FC, memo } from "react";
import { Section, Container, Heading, Button } from "@app/components";
import { Grid } from "@material-ui/core";
import { IconBoxAndText } from "@app/components/molecules/molecules";
import styles from "./WhyChooseAchieve.module.scss";

// This component is called WhyChooseOurPrograms in Contentful and contains the three programs.

interface ItemsDef {
  fields: {
    icon: string;
    title: string;
    description: string;
    ctaLabel?: string;
    ctaLink?: string;
  };
}

export interface WhyChooseAchieveProps {
  title: string;
  items: ItemsDef[];
}

const WhyChooseAchieve: FC<WhyChooseAchieveProps> = memo(
  ({ title, items = [] }) => {
    return (
      <Section withSeparatorSpacing fullWidth>
        <Container indentStep={3}>
          {!!title && (
            <div className={styles.titleWrapper}>
              <Heading size="h2">{title}</Heading>
            </div>
          )}
          {items.length > 0 && (
            <Grid container spacing={5}>
              {items.map(item => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    className={styles.itemWrapper}
                    key={item.fields.title}
                  >
                    <IconBoxAndText
                      className={styles.item}
                      title={item.fields.title}
                      description={item.fields.description}
                      icon={item.fields.icon}
                    />
                    {!!item.fields.ctaLabel && !!item.fields.ctaLink && (
                      <div className={styles.buttonWrapper}>
                        <Button
                          className={styles.button}
                          label={item.fields.ctaLabel}
                          to={item.fields.ctaLink}
                          tabIndex={-1}
                        />
                      </div>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </Section>
    );
  }
);

export default WhyChooseAchieve;

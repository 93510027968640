import React, { FC, memo } from "react";
import { ListTypeKeyEnum } from "@app/constants/ListTypeKeyConst";
import GenericList from "@app/components/renderings/GenericList/GenericList";
import { useTranslation } from "react-i18next";

export interface ActivitiesProps {
  data?: never[];
}

const Activities: FC<ActivitiesProps> = memo(({ data }) => {
  const { t } = useTranslation();
  const hasBackgroundColor = !!t(
    "uiSettings.activitiesInThisUnit.backgroundColor"
  );
  return (
    <>
      {data && (
        <GenericList
          data={data}
          listTypeKey={ListTypeKeyEnum.ACTIVITIES}
          title={t("components.activitiesHeading")}
          hasBackgroundColor={hasBackgroundColor}
        />
      )}
    </>
  );
});

export default Activities;

export enum ScreenSizes {
  // LEGACY =>
  XSMALL = 320,
  SMALL = 576,
  MEDIUM = 768,
  LARGE = 1024,
  XLARGE = 1200,
  // MATERIAL UI =>
  XS = 0,
  SM = 600,
  MD = 960,
  LG = 1280,
  XL = 1920,
}

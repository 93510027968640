import React, { FC, memo, useRef, useEffect } from "react";
import { Button, RichText } from "@app/components";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Document } from "@contentful/rich-text-types";

/**
 * TERMS MODAL
 * uses Mui modal/dialog
 */

const TermsModal: FC<{
  isOpen: boolean;
  fnHandleClose: () => void;
  title: string;
  body: Document;
  buttonLabel?: string;
}> = memo(({ isOpen, fnHandleClose, title, body, buttonLabel = "close" }) => {
  const handleClose = () => {
    fnHandleClose();
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <RichText cfDocument={body} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={fnHandleClose} color="primary" label={buttonLabel} />
      </DialogActions>
    </Dialog>
  );
});

export { TermsModal };

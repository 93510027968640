import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import store from "@app/redux/store";
import "@app/features/localization/localization";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

const render = () => {
  const tagManagerArgs = {
    gtmId: "GTM-T8Z84R7",
  };

  TagManager.initialize(tagManagerArgs);

  // eslint-disable-next-line global-require
  const App = require("./App").default;

  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { memo } from "react";

const SvgExternalLink = memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.9805 0.990163C21.9432 0.99134 21.906 0.994601 21.8691 0.999928H16C15.8675 0.998054 15.7359 1.02254 15.613 1.07195C15.49 1.12136 15.3781 1.19472 15.2837 1.28777C15.1893 1.38081 15.1144 1.49169 15.0632 1.61394C15.0121 1.7362 14.9858 1.8674 14.9858 1.99993C14.9858 2.13245 15.0121 2.26366 15.0632 2.38591C15.1144 2.50817 15.1893 2.61904 15.2837 2.71209C15.3781 2.80513 15.49 2.87849 15.613 2.92791C15.7359 2.97732 15.8675 3.0018 16 2.99993H19.5859L9.29297 13.2929C9.19699 13.385 9.12037 13.4954 9.06758 13.6175C9.0148 13.7397 8.98691 13.8711 8.98556 14.0041C8.9842 14.1372 9.00941 14.2692 9.0597 14.3923C9.10999 14.5155 9.18435 14.6274 9.27843 14.7215C9.37251 14.8156 9.48441 14.8899 9.60759 14.9402C9.73077 14.9905 9.86274 15.0157 9.99578 15.0144C10.1288 15.013 10.2603 14.9851 10.3824 14.9323C10.5045 14.8796 10.6149 14.8029 10.707 14.707L21 4.41399V7.99993C20.9981 8.13244 21.0226 8.264 21.072 8.38697C21.1214 8.50994 21.1948 8.62186 21.2878 8.71623C21.3809 8.8106 21.4918 8.88554 21.614 8.93668C21.7363 8.98783 21.8675 9.01417 22 9.01417C22.1325 9.01417 22.2637 8.98783 22.386 8.93668C22.5082 8.88554 22.6191 8.8106 22.7122 8.71623C22.8052 8.62186 22.8786 8.50994 22.928 8.38697C22.9774 8.264 23.0019 8.13244 23 7.99993V2.12688C23.0199 1.98291 23.0082 1.83632 22.9657 1.69733C22.9232 1.55834 22.8509 1.43029 22.7538 1.32209C22.6568 1.21389 22.5373 1.12814 22.4038 1.07081C22.2702 1.01349 22.1258 0.985968 21.9805 0.990163ZM2 4.99993C0.906937 4.99993 0 5.90687 0 6.99993V21.9999C0 23.093 0.906937 23.9999 2 23.9999H17C18.0931 23.9999 19 23.093 19 21.9999V11.9999V9.4218L17 11.4218V13.9999V21.9999H2V6.99993H10H12H12.5781L14.5781 4.99993H12H10H2Z"
      />
    </svg>
  );
});

export default SvgExternalLink;

import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";

import { api } from "@app/api/api";

import { BookmarksEndpointsEnum } from "../constants/bookmarks.endpoints";
import { BookmarkDataDef, BookmarksFiltersDef } from "../types/bookmarks.types";

const getAllUserBookmarks = (
  filter?: BookmarksFiltersDef
): Promise<AxiosResponse> => {
  return api.get(BookmarksEndpointsEnum.USER_BOOKMARKS, { params: filter });
};

const addToUserBookmarks = (data: BookmarkDataDef): Promise<AxiosResponse> => {
  return api.post(BookmarksEndpointsEnum.USER_BOOKMARKS, data);
};

const deleteBookmarkById = (resourceId: string): Promise<AxiosResponse> => {
  const url = generatePath(BookmarksEndpointsEnum.USER_SINGLE_BOOKMARK, {
    resourceId,
  });
  return api.delete(url);
};

export const bookmarksApi = {
  getAllUserBookmarks,
  addToUserBookmarks,
  deleteBookmarkById,
};

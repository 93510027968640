import * as React from "react";

function SvgTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6406 2.7537L5.66578 3.16893L5.2461 3.11808C3.71843 2.92318 2.38383 2.26221 1.25067 1.15211L0.696681 0.6013L0.553988 1.00805C0.251813 1.91477 0.444869 2.87234 1.0744 3.51636C1.41015 3.87227 1.33461 3.92312 0.755438 3.71127C0.553988 3.64347 0.377719 3.59263 0.360931 3.61805C0.302175 3.67737 0.503625 4.44851 0.663106 4.75357C0.881344 5.17727 1.32621 5.5925 1.81305 5.83825L2.22434 6.03315L1.73751 6.04162C1.26746 6.04162 1.25067 6.0501 1.30103 6.22805C1.46891 6.77886 2.13201 7.36357 2.87066 7.61779L3.39108 7.79574L2.93781 8.06691C2.26631 8.45672 1.4773 8.67704 0.688288 8.69399C0.310569 8.70247 0 8.73636 0 8.76178C0 8.84652 1.02404 9.32107 1.61999 9.5075C3.40786 10.0583 5.53148 9.82104 7.1263 8.88042C8.25945 8.21097 9.39261 6.88055 9.92142 5.5925C10.2068 4.9061 10.4922 3.65195 10.4922 3.05029C10.4922 2.66049 10.5174 2.60964 10.9874 2.14357C11.2644 1.8724 11.5246 1.57581 11.575 1.49107C11.6589 1.33007 11.6505 1.33007 11.2224 1.47412C10.509 1.72834 10.4083 1.69445 10.7608 1.31312C11.021 1.04195 11.3316 0.550456 11.3316 0.406397C11.3316 0.380975 11.2057 0.423345 11.063 0.499612C10.9119 0.584352 10.5761 0.711462 10.3243 0.787728L9.87105 0.931787L9.45976 0.652144C9.23313 0.499612 8.91417 0.330131 8.74629 0.279287C8.31821 0.160651 7.6635 0.177599 7.27738 0.313183C6.22816 0.694514 5.56506 1.6775 5.6406 2.7537Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgTwitter = React.memo(SvgTwitter);
export default MemoSvgTwitter;

import React, { FC, memo } from "react";

import { Section, Heading, Text, MediaWrapper } from "@app/components";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./Banner.module.scss";

export interface BannerProps {
  title: string;
  description: string;
  image?: ContentfulImage;
}

const Banner: FC<BannerProps> = memo(({ title, image, description }) => {
  return (
    <Section className={styles.banner} fullWidth>
      {image?.fields && (
        <div className={styles.image}>
          <MediaWrapper cfImage={image} fillContainer />
        </div>
      )}
      <div className={styles.content}>
        <Heading size="h1" tag="span">
          {title}
        </Heading>
        <Text>{description}</Text>
      </div>
    </Section>
  );
});

export { Banner };

import * as React from "react";

const SvgRightArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.143 31.143"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M0 15.571a3.088 3.088 0 0 0 3.085 3.083l17.528-.002-4.738 4.739c-1.283 1.284-1.349 3.301-.145 4.507 1.205 1.201 3.222 1.138 4.507-.146l9.896-9.898c1.287-1.283 1.352-3.301.146-4.506-.033-.029-.068-.051-.1-.08-.041-.043-.07-.094-.113-.139l-9.764-9.762c-1.268-1.266-3.27-1.316-4.474-.111-1.205 1.205-1.153 3.208.111 4.476l4.755 4.754H3.085A3.083 3.083 0 0 0 0 15.571z" />
  </svg>
);

const Memo = React.memo(SvgRightArrow);
export default Memo;

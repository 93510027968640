import * as React from "react";

function SvgCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.680578 10.144C0.680578 13.028 1.66058 15.436 3.62058 17.396C5.58058 19.356 8.01658 20.336 10.9566 20.336C13.8966 20.336 16.3606 19.356 18.3486 17.396C20.3366 15.436 21.3166 13.028 21.3166 10.172C21.3166 7.316 20.3366 4.908 18.3486 2.948C16.3606 0.987999 13.8966 0.0079987 10.9566 0.0079987C8.01658 0.0079987 5.55258 0.987999 3.59258 2.948C1.63258 4.908 0.652578 7.316 0.652578 10.172L0.680578 10.144ZM18.4886 10.172C18.4886 12.328 17.7606 14.12 16.3326 15.604C14.9046 17.06 13.1126 17.788 10.9566 17.788C8.80058 17.788 7.00858 17.06 5.58058 15.604C4.15258 14.12 3.42458 12.328 3.42458 10.172C3.42458 8.072 4.15258 6.252 5.58058 4.768C7.00858 3.284 8.77258 2.556 10.9286 2.556C13.0566 2.556 14.8486 3.284 16.3046 4.768C17.7606 6.252 18.4886 8.072 18.4886 10.172Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgCircle = React.memo(SvgCircle);
export default MemoSvgCircle;

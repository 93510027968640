import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import {
  ForgotPasswordDef,
  ResetPasswordDef,
  UpdatePasswordDef,
  UpdateUserDef,
  UserDef,
} from "@app/types/api.types";

import { AuthEndpointsEnum } from "../constants/auth.endpoints";

const getLoginConfig = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpointsEnum.AUTH_CONFIG);
};

const logout = (): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.LOGOUT);
};

const getUser = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpointsEnum.USER);
};

const updateUser = (data: UpdateUserDef): Promise<AxiosResponse> => {
  return api.put(AuthEndpointsEnum.USER, data);
};

const updatePassword = (data: UpdatePasswordDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.USER_PASSWORD, data);
};

const forgotPassword = (data: ForgotPasswordDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.FORGOT_PASSWORD, data);
};

const resetPassword = (data: ResetPasswordDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.RESET_PASSWORD, data);
};

const refreshAccessToken = (): Promise<AxiosResponse<UserDef>> => {
  return api.post(AuthEndpointsEnum.REFRESH_TOKEN);
};

export const authApi = {
  logout,
  getUser,
  updateUser,
  refreshAccessToken,
  getLoginConfig,
  updatePassword,
  forgotPassword,
  resetPassword,
};

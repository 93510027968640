import React, { FC, memo } from "react";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { MediaWrapper, Link, Heading, RichText } from "@app/components";
import { Document } from "@contentful/rich-text-types";
import styles from "./HorizontalCard.module.scss";

export interface HorizontalCardProps {
  image: ContentfulImage;
  title: string;
  text: Document;
  ctaLink: string;
}

const HorizontalCard: FC<HorizontalCardProps> = memo(
  ({ title, text, image, ctaLink }) => {
    const cardTitle =
      title !== "" ? (
        <Heading size="subtitle1" className={styles.title}>
          {title}
        </Heading>
      ) : null;

    return (
      <div className={styles.card}>
        {image?.fields && (
          <MediaWrapper cfImage={image} ratio={0.86} className={styles.image} />
        )}
        <div className={styles.text}>
          {ctaLink && ctaLink !== "" ? (
            <Link to={ctaLink}>{cardTitle}</Link>
          ) : (
            cardTitle
          )}
          {text && <RichText cfDocument={text} />}
        </div>
      </div>
    );
  }
);

export default HorizontalCard;

import { Section, Container, Heading, Text, CardList } from "@app/components";
import { CardDef } from "@app/types/contentfulTypes";
import { Grid } from "@material-ui/core";
import React, { memo } from "react";
import { ReactComponent as ChevronRed } from "@app/assets/images/chevron-red.svg";
import styles from "./FeaturedResources.module.scss";

interface FeaturedResourcesProps {
  title: string;
  subtitle?: string;
  data?: CardDef[];
  isMosaic?: boolean;
  isEnterpriseChallenge?: boolean;
}

const FeaturedResources = ({
  title,
  subtitle,
  data = [],
  isMosaic,
  isEnterpriseChallenge,
}: FeaturedResourcesProps) => {
  return (
    <Section
      withSeparatorSpacing
      fullWidth
      className={`${styles.section} ${isMosaic ? styles.mosaic : ""} ${
        isEnterpriseChallenge ? styles.enterprise : ""
      }`}
    >
      {!isMosaic && !isEnterpriseChallenge && (
        <ChevronRed className={styles.chevron} />
      )}
      <Container>
        <Grid container justify="space-between">
          <Grid item xs={12} lg={5}>
            <Heading size="h2" className={styles.heading}>
              {title}
            </Heading>
            {!!subtitle && <Text className={styles.subtitle}>{subtitle}</Text>}
          </Grid>
          <Grid item xs={12} lg={6}>
            {data?.length > 0 && (
              <CardList
                list={data}
                useSlider
                slidesNumber={2}
                isWhiteControls
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
export default memo(FeaturedResources);

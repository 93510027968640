/**
 * These are both from content type ids in contentful
 * (on collection, unit and activity content types)
 * and from category name dropdown in the content type "Category List"
 *
 * They are matched with css rules in various components
 */
export enum ResourceTypeEnum {
  COLLECTION = "collection",
  UNIT = "unit",
  ACTIVITY = "activity",
}

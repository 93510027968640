import React, { FC, memo, ReactNode, useState, useEffect } from "react";
import { CONTENT_ANCHOR, ThemeEnum } from "@app/constants/themeConst";
import classnames from "classnames/bind";
import NavigationMain from "@app/components/molecules/NavigationMain/NavigationMain";
import FooterComponent from "@app/components/renderings/FooterComponent/FooterComponent";
import { useEntriesByContentModel } from "@app/features/contentful/contentful";
import { WebPageComponents } from "@app/types/contentfulTypes";
import useResetScroll from "@app/hooks/useResetScroll";
import styles from "./DefaultLayout.module.scss";
import SessionExpired from "../SessionExpired/SessionExpired";

const cx = classnames.bind(styles);

interface DefaultLayoutProps {
  children: ReactNode;
  theme?: ThemeEnum;
}

const DefaultLayout: FC<DefaultLayoutProps> = memo(({ children, theme }) => {
  useResetScroll();

  const { entries, loading } =
    useEntriesByContentModel<WebPageComponents[]>("footerComponent");

  const [footerContentId, setFooterContentId] = useState("");

  useEffect(() => {
    if (entries && entries.length > 0) {
      setFooterContentId(entries[0].sys.id);
    }
  }, [entries, loading]);

  return (
    <div
      className={cx(styles.container, {
        "theme-default": theme === ThemeEnum.DEFAULT,
      })}
    >
      <NavigationMain />
      <SessionExpired />

      <div className={styles.content} role="main" id={CONTENT_ANCHOR}>
        {children}
      </div>
      <FooterComponent entryID={footerContentId} />
    </div>
  );
});

export { DefaultLayout };

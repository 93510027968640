import React, { FC, memo } from "react";
import { Link } from "@app/components";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { isURL } from "@app/helpers/utilHelper";
import styles from "./NavigationLinks.module.scss";

interface NavItemDef {
  name: string;
  path: string;
  isPrivate: boolean;
}

export interface NavigationLinksProps {
  navLinks: NavItemDef[];
  onNavItemClick?: () => void;
  isMobileLinks?: boolean;
}

const NavigationLinks: FC<NavigationLinksProps> = memo(
  ({ navLinks = [], onNavItemClick, isMobileLinks }) => {
    const publicLinks = navLinks?.filter(item => !item.isPrivate);

    const privateLinks = navLinks?.filter(item => item.isPrivate);

    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    const renderLink = (item: NavItemDef) => {
      return (
        <Link
          key={item.name}
          to={item.path}
          className={styles.navItem}
          clickHandler={isMobileLinks ? onNavItemClick : () => null}
          showExternalIcon={!!(!!item.path && isURL(item.path))}
        >
          {item.name}
        </Link>
      );
    };

    return (
      <div className={styles.navbar}>
        {publicLinks.length > 0 && publicLinks.map(item => renderLink(item))}
        {isAuthenticated &&
          privateLinks.length > 0 &&
          privateLinks.map(item => renderLink(item))}
      </div>
    );
  }
);

export default NavigationLinks;

import { CardDef, CardFieldsDef } from "@app/types/contentfulTypes";
import { ApiFormErrorsDef } from "@app/types/api.types";

export const validateEnum = (enumName: any, enumValue: string) =>
  Object.values(enumName).includes(enumValue);

/**
 * Util function for creating an object with properties that mirror the output from contentful images
 * that are used in media / image components.
 * This is made primarily for making working with stories easier
 * @param url string
 */
export const mapImageSrcToUrl = (url: string) => {
  return {
    fields: {
      file: {
        url,
      },
    },
  };
};

export const mapAPIListToContentful = (listItems: CardFieldsDef[]) => {
  const mappedList = listItems.map(item => {
    const list: Partial<CardDef> = {};

    list.fields = {
      title: item.title,
      intro: item.intro,
      image: mapImageSrcToUrl(String(item.image)) ?? null,
      type: item.type,
      isNew: item.isNew,
      isFeatured: item.isFeatured,
      logo: mapImageSrcToUrl(String(item.logo)) ?? null,
      credits: item.credits,
      contentType: item.contentType,
      isBookmarked: item.isBookmarked,
      activities: item?.activities,
      units: item?.units,
    };

    list.sys = { id: item.id };

    return list;
  });

  return mappedList as CardDef[];
};

export const convertToSlug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, ""); // trim - from end of text
};

/**
 * Lookup error message keys with translation keys
 */
export function mapErrorFields(errors: ApiFormErrorsDef) {
  const modifiedErrors: Record<string, string> = {};

  Object.keys(errors).forEach(fieldName => {
    const [error] = errors[fieldName];
    // Take the first available error to show against the field
    modifiedErrors[fieldName] = error;
  });

  return modifiedErrors;
}

export const isURL = (str: string) => {
  return /http|www|\./.test(str);
};

import React, { FC, memo } from "react";
import {
  Section,
  Container,
  Heading,
  Text,
  MediaWrapper,
} from "@app/components";
import HorizontalCard, {
  HorizontalCardProps,
} from "@app/components/molecules/HorizontalCard/HorizontalCard";
import { Banner } from "@app/components/molecules/Banner/Banner";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./IntroComponent.module.scss";

export interface IntroComponentProps {
  title: string;
  description: string;
  image?: ContentfulImage;
  cards: { fields: HorizontalCardProps; sys: { id: string } }[];
  isBanner: boolean;
  hasTopPadding: boolean;
}

const IntroComponent: FC<IntroComponentProps> = memo(
  ({
    title,
    description,
    cards,
    image,
    isBanner = false,
    hasTopPadding = false,
  }) => {
    const user = useSelector((state: RootState) => state.auth.user);

    if (isBanner) {
      return <Banner title={title} image={image} description={description} />;
    }

    return (
      <Section className={styles.section} fullWidth>
        {image?.fields && <div className={styles.bottomBG} />}
        <Container indentStep={2}>
          <div
            className={`${styles.intro} ${
              hasTopPadding ? styles.topPadding : ""
            }`}
          >
            <Heading size="h1" tag="h1" align="center" className={styles.title}>
              {title} {user?.firstName}
            </Heading>
            <Text align="center">{description}</Text>
          </div>
          {image?.fields && (
            <div className={styles.imageWrapper}>
              <MediaWrapper cfImage={image} natural />
            </div>
          )}
          {cards?.length > 0 && (
            <Grid container spacing={3} className={styles.container}>
              {cards.map(card => (
                <Grid item xs={12} md={6} key={card.sys.id}>
                  <HorizontalCard
                    title={card.fields.title}
                    text={card.fields.text}
                    image={card.fields.image}
                    ctaLink={card.fields.ctaLink}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Section>
    );
  }
);

export default IntroComponent;

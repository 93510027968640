import React, { FC, memo } from "react";
import { DocumentDownload } from "@app/components";
import { useTranslation } from "react-i18next";

export interface ResourcePacksProps {
  data?: never[];
}

const ResourcePacks: FC<ResourcePacksProps> = memo(({ data }) => {
  const { t } = useTranslation();

  return data ? (
    <DocumentDownload
      data={data}
      title={t("components.resourcePacksHeading")}
    />
  ) : null;
});

export default ResourcePacks;

import React, { FC, ReactNode, memo } from "react";
import styles from "./FormTheme.module.scss";

interface FormThemeProps {
  children?: ReactNode;
}

const FormTheme: FC<FormThemeProps> = memo(({ children }) => {
  return <div className={styles.formTheme}>{children}</div>;
});

export { FormTheme };
export { FormField } from "./Components/FormField/FormField";
export { SwitchToggle } from "./Components/SwitchToggle/SwitchToggle";

import * as React from "react";

function SvgNavigation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 6H21" stroke="currentColor" strokeWidth="2" />
      <path d="M3 12H21" stroke="currentColor" strokeWidth="2" />
      <path d="M3 18H21" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}

const MemoSvgNavigation = React.memo(SvgNavigation);
export default MemoSvgNavigation;

import React, { FC, memo } from "react";
import { Section, Container, Heading } from "@app/components";
import { Grid } from "@material-ui/core";
import styles from "./Footer.module.scss";
import FooterLinks from "./components/FooterLinks/FooterLinks";
import { FooterLinkItemProps } from "./components/FooterLinkItem/FooterLinkItem";
import Partnership, {
  ParntershipProps,
} from "./components/Partnership/Partnership";
import BottomLinks from "./components/BottomLinks/BottomLinks";
import { BottomLinkItemProps } from "./components/BottomLinkItem/BottomLinkItem";

export interface FooterSections {
  title: string;
  links: FooterLinkItemProps[];
}

export interface FooterProps {
  partnership?: ParntershipProps;
  sections: FooterSections[];
  bottomLinks: BottomLinkItemProps[];
}

const Footer: FC<FooterProps> = memo(
  ({ sections = [], partnership, bottomLinks }) => {
    return (
      <Section className={styles.section} fullWidth role="contentinfo">
        <Container indentStep={3}>
          <Grid container>
            {partnership && (
              <Grid item xs={12} md={3}>
                <Partnership {...partnership} />
              </Grid>
            )}
            {sections.length > 0 &&
              sections.map(section => (
                <Grid item xs={12} md={3} key={section.title}>
                  {!!section.title && (
                    <Heading size="h6">{section.title}</Heading>
                  )}
                  {section?.links?.length > 0 && (
                    <FooterLinks links={section.links} />
                  )}
                </Grid>
              ))}
          </Grid>
        </Container>
        <Container indentStep={3}>
          <BottomLinks items={bottomLinks} />
        </Container>
        <div className={styles.chevronBG} />
      </Section>
    );
  }
);

export default Footer;

import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { OktaLoginConfigDef } from "@app/features/auth/auth";

type SignInProps = {
  config: OktaLoginConfigDef;
  onSuccess: (tokens: any) => void;
  onError: (tokens: any) => void;
};

const OktaSignInWidget = ({ onSuccess, onError, config }: SignInProps) => {
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!widgetRef.current) return;

    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    // eslint-disable-next-line consistent-return
    return () => {
      widget.remove();
    };
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;

import * as React from "react";

function SvgSingleNeutral(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M39.375 35a30.625 30.625 0 1061.25 0 30.625 30.625 0 10-61.25 0zM13.125 135.625a56.875 56.875 0 01113.75 0"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgSingleNeutral = React.memo(SvgSingleNeutral);
export default MemoSvgSingleNeutral;

/* eslint-disable */
import React from "react";
import { Helmet } from "react-helmet";

interface MetaTagsWrapper {
  title?: string;
  description?: string;
  imageURL?: string;
}

const MetaTagsWrapper = ({
  title = `The Prince's Trust`,
  description,
  imageURL,
}: MetaTagsWrapper) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {imageURL && <meta property="og:image" content={`https:${imageURL}`} />}
      </Helmet>
    </>
  );
};

export default MetaTagsWrapper;

import { createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeSettings } from "@app/constants/MaterialUiConst";

export const createBaseMuiTheme = () => {
  /**
   * Base theme for overriding material ui components
   *
   */
  // variants in typography being used
  // h1, h2, h3, body1, title .. WIP!

  const { font1, fontSizeBody } = MuiThemeSettings;

  /**
   * Create theme
   */
  const baseTheme = createMuiTheme({
    typography: {
      fontFamily: font1,
      fontSize: fontSizeBody,
    },
  });

  baseTheme.typography.h1 = {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 40 / 28,
    [baseTheme.breakpoints.up("md")]: {
      fontSize: 40,
      lineHeight: 72 / 56,
      fontWeight: 700,
    },
    [baseTheme.breakpoints.only("lg")]: {
      fontSize: 48,
      lineHeight: 72 / 56,
      fontWeight: 700,
    },
    [baseTheme.breakpoints.up(1440)]: {
      fontSize: 56,
      lineHeight: 72 / 56,
      fontWeight: 700,
    },
  };

  baseTheme.typography.h2 = {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 1.43,
    [baseTheme.breakpoints.up("md")]: {
      fontSize: 34,
      lineHeight: 1.33,
      fontWeight: 700,
    },
    [baseTheme.breakpoints.up(1440)]: {
      fontSize: 48,
      lineHeight: 1.33,
      fontWeight: 700,
    },
  };

  baseTheme.typography.h3 = {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 1.43,
  };

  baseTheme.typography.h6 = {
    fontSize: 20,
    fontWeight: 500,
  };

  baseTheme.typography.subtitle1 = {
    fontSize: 24,
    lineHeight: 1.33,
    fontWeight: 700,
  };

  // this is also mapped to "lead" prop
  // in various typography components
  baseTheme.typography.subtitle2 = {
    fontSize: 20,
    lineHeight: 32 / 20,
  };

  baseTheme.typography.body1 = {
    fontSize: fontSizeBody,
    lineHeight: 32 / 18,
    [baseTheme.breakpoints.only("lg")]: {
      lineHeight: 1.2,
    },
  };

  return baseTheme;
};

import React, { FC, memo } from "react";
import { IconNavigation, IconClose } from "@app/components/atoms/Icon/Icon";
import styles from "./NavigationMobile.module.scss";

interface NavigationMobileProps {
  menuOpen: boolean;
  toggleMenu: () => void;
}

const NavigationMobile: FC<NavigationMobileProps> = memo(
  ({ menuOpen, toggleMenu }) => {
    return (
      <button
        onClick={toggleMenu}
        className={styles.menuButton}
        aria-label="main menu"
      >
        {menuOpen ? <IconClose /> : <IconNavigation />}
      </button>
    );
  }
);

export default NavigationMobile;

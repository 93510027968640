import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./VoiceAlert.module.scss";

const VoiceAlert = memo(() => {
  const { t } = useTranslation();
  return (
    <div role="alert" className={styles.alert}>
      {t("uiLabels.resultsUpdatedAlert")}
    </div>
  );
});

export default VoiceAlert;

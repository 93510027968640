import React, { FC, memo, ReactNode } from "react";

import { Link, Text } from "@app/components";

import styles from "./BottomLinkItem.module.scss";

export interface BottomLinkItemProps {
  label: string | ReactNode;
  link?: string;
}

const BottomLinkItem: FC<BottomLinkItemProps> = memo(({ label, link }) => {
  const textComponent = <Text className={styles.label}>{label}</Text>;

  return (
    <li className={styles.bottomLink}>
      {link ? (
        <Link to={link} className={styles.link}>
          {textComponent}
        </Link>
      ) : (
        textComponent
      )}
    </li>
  );
});

export default BottomLinkItem;

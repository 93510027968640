import * as React from "react";

function SvgPenWrite(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M49.537 74.457l-6.19 24.75 24.751-6.189 37.124-37.123L86.66 37.333 49.537 74.457zM126.875 34.242l6.19 6.189a8.75 8.75 0 010 12.372L113.75 72.117"
          strokeWidth={8.749995}
        />
        <path
          d="M105.222 55.895l21.653-21.653a8.75 8.75 0 000-12.373l-6.19-6.189a8.75 8.75 0 00-12.372 0L86.66 37.333M26.25 91.87H13.125a8.75 8.75 0 00-8.75 8.75v17.5a8.75 8.75 0 008.75 8.75h113.75a8.75 8.75 0 008.75-8.75v-17.5a8.75 8.75 0 00-8.75-8.75H96.25"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgPenWrite = React.memo(SvgPenWrite);
export default MemoSvgPenWrite;

import React, { FC, memo } from "react";
import { Heading, Text } from "@app/components/atoms/atoms";
import { Section, Container } from "@app/components";
import styles from "./JumbotronStats.module.scss";

export interface JumbotronStatsProps {
  statistics: {
    fields: { title: string; statistic: string };
  }[];
}

const JumbotronStats: FC<JumbotronStatsProps> = memo(({ statistics = [] }) => {
  return statistics.length === 0 ? null : (
    <Section className={styles.section}>
      <Container indentStep={1}>
        <div className={styles.widget}>
          {statistics.map(item => (
            <div className={styles.statItem} key={item.fields.statistic}>
              <Heading size="h3">{item.fields.statistic}</Heading>
              <Text className={styles.text}>{item.fields.title}</Text>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
});

export default JumbotronStats;

import React, { FC, memo } from "react";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { MediaWrapper, Container } from "@app/components";
import { Grid } from "@material-ui/core";
import cx from "classnames";
import styles from "./JumbotronHome.module.scss";
import JumbotronBackground from "./components/JumbotronBackground/JumbotronBackground";
import JumbotronText, {
  JumbotronTextProps,
} from "./components/JumbotronText/JumbotronText";
import JumbotronStats, {
  JumbotronStatsProps,
} from "./components/JumbotronStats/JumbotronStats";

export interface JumbotronHomeProps
  extends JumbotronTextProps,
    JumbotronStatsProps {
  image: ContentfulImage;
}

const JumbotronHome: FC<JumbotronHomeProps> = memo(
  ({
    image,
    title,
    description,
    ctaLabel,
    ctaLink,
    statistics = [],
    achieveButton,
    mosaicButton,
    enterpriseChallengeButton,
  }) => {
    return (
      <div
        className={cx(
          styles.wrapper,
          {
            [styles.twoRowStats]: statistics.length > 4,
          },
          {
            [styles.wrapperOverwrite]:
              achieveButton ||
              mosaicButton ||
              enterpriseChallengeButton === true,
          }
        )}
      >
        {image.fields && (
          <MediaWrapper className={styles.image} cfImage={image} natural />
        )}
        <div
          className={cx(
            styles.content,
            {
              [styles.hasBorderBottom]: achieveButton === true,
            },
            {
              [styles.hasBorderBottomMosaic]: mosaicButton === true,
            },
            {
              [styles.hasBorderBottomEnterprise]:
                enterpriseChallengeButton === true,
            },
            {
              [styles.contentOverwrite]:
                achieveButton ||
                mosaicButton ||
                enterpriseChallengeButton === true,
            }
          )}
        >
          <JumbotronBackground />
          <div className={styles.sectionWrapper}>
            <Container indentStep={0}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={6}
                  className={styles.contentWrapper}
                >
                  <JumbotronText
                    title={title}
                    description={description}
                    ctaLabel={ctaLabel}
                    ctaLink={ctaLink}
                    achieveButton={achieveButton}
                    mosaicButton={mosaicButton}
                    enterpriseChallengeButton={enterpriseChallengeButton}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div
          className={
            achieveButton || mosaicButton || enterpriseChallengeButton
              ? styles.specialStats
              : ""
          }
        >
          <JumbotronStats statistics={statistics} />
        </div>
      </div>
    );
  }
);

export default JumbotronHome;

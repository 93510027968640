import * as React from "react";

function SvgRibbon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0V23L1.59375 21.8125L9 16.25L16.4062 21.8125L18 23V0H0ZM2 2H16V19L9.59375 14.1875L9 13.75L8.40625 14.1875L2 19V2Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgRibbon = React.memo(SvgRibbon);
export default MemoSvgRibbon;

import { RouteItemDef } from "@app/types/routeDef";
import { UserPathsEnum } from "../constants/users.paths";

import DashboardScreen from "../screens/DashboardScreen/DashboardScreen";
import AccountSettingsScreen from "../screens/AccountSettingsScreen/AccountSettingsScreen";

const DASHBOARD_SCREEN: RouteItemDef = {
  id: "dashboard",
  path: UserPathsEnum.DASHBOARD,
  component: DashboardScreen,
  contentfulPageKey: "4DKKQc4sG1wXZbIGL3qFVV",
};

const ACCOUNT_SETTINGS_SCREEN: RouteItemDef = {
  id: "account-settings",
  path: UserPathsEnum.ACCOUNT_SETTINGS,
  component: AccountSettingsScreen,
  exact: false,
};

const USER_ROUTES = [DASHBOARD_SCREEN, ACCOUNT_SETTINGS_SCREEN];

export default USER_ROUTES;

import { isAfter, fromUnixTime } from "date-fns";
import { AUTH_ACCESS_TOKEN } from "../constants/auth.keys";

/**
 * Loads token from session cookie
 */
export const getTokens = () => {
  const tokenInfo: string = localStorage.getItem(AUTH_ACCESS_TOKEN) as string;
  const oktaToken: {
    accessToken: { accessToken: string; expiresAt: number; tokenType: string };
  } = JSON.parse(tokenInfo);

  if (!oktaToken?.accessToken)
    return {
      accessToken: null,
      isTokenExpired: null,
      tokenType: null,
    };

  const {
    expiresAt,
    accessToken,
    tokenType = "Bearer",
  } = oktaToken.accessToken;

  const current = new Date();
  const expiry = new Date(fromUnixTime(expiresAt));

  return {
    accessToken,
    isTokenExpired: isAfter(current, expiry),
    tokenType,
  };
};

export const clearTokens = () => {
  localStorage.removeItem(AUTH_ACCESS_TOKEN);
};

import React, { memo } from "react";
import { MediaWrapper } from "@app/components";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./ImagesGrid.module.scss";

interface ImagesGridProps {
  images: { fields: { componentName: string; image: ContentfulImage } }[];
}

const ImagesGrid = ({ images = [] }: ImagesGridProps) => {
  return (
    <>
      <div className={styles.firstRow}>
        {images[0]?.fields && (
          <MediaWrapper
            className={styles.image}
            cfImage={images[0].fields.image}
            alt={images[0].fields.componentName}
            ratio={1.33}
          />
        )}
        {images[1]?.fields && (
          <MediaWrapper
            className={styles.image}
            cfImage={images[1].fields.image}
            alt={images[1].fields.componentName}
            ratio={1}
          />
        )}
      </div>
      <div className={styles.secondRow}>
        {images[2]?.fields && (
          <MediaWrapper
            className={styles.image}
            cfImage={images[2].fields.image}
            alt={images[2].fields.componentName}
            ratio={1.737}
          />
        )}
        {images[3]?.fields && (
          <MediaWrapper
            className={styles.image}
            cfImage={images[3].fields.image}
            alt={images[3].fields.componentName}
            ratio={1.38}
          />
        )}
      </div>
    </>
  );
};

export default memo(ImagesGrid);

import React, { memo } from "react";

import { SubjectsListDef } from "@app/features/resources/resources";
import { FormControlLabel, FormGroup } from "@material-ui/core";

import { ResourcesView } from "@app/types/layoutDef";
import _kebabCase from "lodash/kebabCase";

import qs from "query-string";
import { useLocation } from "react-router-dom";
import CustomCheckbox from "@app/components/atoms/CustomCheckbox/CustomCheckbox";
import { Heading } from "@app/components";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/atoms/atoms";
import styles from "./SubjectsFilters.module.scss";

interface SubjectsFiltersProps {
  subjects?: SubjectsListDef[];
  selectedSubjectId: string;
  onSubjectChange: (
    index: number,
    id: string,
    name: string,
    type: string,
    resourcesView: ResourcesView
  ) => void;
  onClearSubject?: () => void;
}

const SubjectsFilters = ({
  subjects = [],
  selectedSubjectId,
  onSubjectChange,
  onClearSubject,
}: SubjectsFiltersProps) => {
  const { t } = useTranslation();

  const location = useLocation();

  const searchQuery = qs.parse(location.search);

  const handleSubjectChange = (id: string, index: number, name: string) => {
    onSubjectChange(
      index,
      id,
      name,
      searchQuery.type as string,
      searchQuery.view as ResourcesView
    );
  };

  const analyticsSubjects = "subject-filter-";

  return (
    <div className={styles.wrapper}>
      {subjects?.length > 0 && (
        <FormGroup role="group" aria-label={t("uiLabels.subjectsFilterLabel")}>
          <div className={styles.text}>
            <Heading size="subtitle2">
              {t("uiLabels.subjectsFilterLabel")}
            </Heading>
            <Button
              onClick={onClearSubject}
              label="Clear"
              inverted
              removeBorder
              small
              xSmall
            />
          </div>

          {subjects?.map((subject, index) => (
            <FormControlLabel
              key={subject.id}
              id={`${analyticsSubjects}${_kebabCase(subject.name)}`}
              className={styles.checkbox}
              control={
                <CustomCheckbox
                  name={subject.name}
                  color="primary"
                  label={`${analyticsSubjects}${_kebabCase(subject.name)}`}
                  checked={subject.id === selectedSubjectId}
                  onChange={() =>
                    handleSubjectChange(subject.id, index, subject.name)
                  }
                />
              }
              label={subject.name}
            />
          ))}
        </FormGroup>
      )}
    </div>
  );
};

export default memo(SubjectsFilters);

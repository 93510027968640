import { CONTENT_ANCHOR } from "@app/constants/themeConst";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SkipNavButton.module.scss";

const SkipNavButton: FC = memo(() => {
  const { t } = useTranslation();
  return (
    <a href={`#${CONTENT_ANCHOR}`} className={styles.skipNavButton}>
      <span>{t("components.skipNavigation")}</span>
    </a>
  );
});

export default SkipNavButton;

import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "@app/features/auth/auth";
import { notificationReducer } from "@app/features/notification/notification";

import {
  subjectsReducer,
  unitsReducer,
  activitiesReducer,
  qualificationsReducer,
  collectionsReducer,
} from "@app/features/resources/resources";
import { bookmarksReducer } from "@app/features/bookmarks/bookmarks";
import { localizationReducer } from "@app/features/localization/localization";

const rootReducer = combineReducers({
  notification: notificationReducer,
  auth: authReducer,
  subjects: subjectsReducer,
  units: unitsReducer,
  activities: activitiesReducer,
  bookmarks: bookmarksReducer,
  localization: localizationReducer,
  qualifications: qualificationsReducer,
  collections: collectionsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import React, { FC, memo } from "react";
import { Heading, Text, Button } from "@app/components";
import _kebabCase from "lodash/kebabCase";
import { useTranslation } from "react-i18next";
import styles from "./JumbotronText.module.scss";

export interface JumbotronTextProps {
  title: string;
  description?: string;
  ctaLabel?: string;
  ctaLink?: string;
  achieveButton?: boolean;
  mosaicButton?: boolean;
  enterpriseChallengeButton?: boolean;
}

const JumbotronText: FC<JumbotronTextProps> = memo(
  ({
    title,
    description,
    ctaLabel,
    ctaLink,
    achieveButton,
    mosaicButton,
    enterpriseChallengeButton,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        {title && (
          <Heading size="h1" tag="h1" className={styles.title}>
            {title}
            {mosaicButton && (
              <Heading size="h1" tag="h1" className={styles.mosaicText}>
                {t("components.navigationProgramsMosaicLabel")}
              </Heading>
            )}
            {enterpriseChallengeButton ? (
              <Heading size="h1" tag="h1" className={styles.enterpriseText}>
                {t("components.navigationProgramsEnterpriseChallengeLabel")}
              </Heading>
            ) : null}
          </Heading>
        )}
        {achieveButton ? (
          <Heading size="h1" tag="h1" className={styles.achieveText}>
            {t("components.navigationProgramsAchieveLabel")}
          </Heading>
        ) : null}
        {!!description && <Text className={styles.text}>{description}</Text>}
        {!!ctaLabel && !!ctaLink && (
          <Button
            arrow
            className={styles.button}
            label={ctaLabel}
            to={ctaLink}
            tabIndex={-1}
            id={_kebabCase(ctaLink)}
          />
        )}
      </>
    );
  }
);

export default JumbotronText;

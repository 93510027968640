import React, { memo, useEffect } from "react";

import { useSelector } from "react-redux";

import {
  WebPageDataModel,
  WebPageComponents,
} from "@app/types/contentfulTypes";
import { bookmarksAPILimit } from "@app/constants/api.constants";
import { getBookmarks } from "@app/features/bookmarks/bookmarks";
import { RootState } from "@app/redux/rootReducer";
import { useAppDispatch } from "@app/redux/store";
import { useSingleEntry } from "@app/features/contentful/contentful";
import Renderings from "@app/components/renderings";
import MetaTagsWrapper from "@app/components/molecules/MetaTagsWrapper/MetaTagsWrapper";

type ContentfulTemplateProps = {
  contentfulPageKey?: string;
};

const TemplateScreen = ({
  contentfulPageKey = "",
}: ContentfulTemplateProps) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const dispatch = useAppDispatch();
  const { entry, loading } =
    useSingleEntry<WebPageDataModel>(contentfulPageKey);

  useEffect(() => {
    if (isAuthenticated) dispatch(getBookmarks({ limit: bookmarksAPILimit }));
  }, [dispatch, isAuthenticated]);

  const titleMetaTagAlternative = /introcomponent|jumbotronhome|jumbotron/gi;

  const h1Section = entry?.renderings?.filter((section: WebPageComponents) => {
    const sectionName = section?.sys?.contentType?.sys?.id;
    if (!sectionName) return false;
    return titleMetaTagAlternative.test(sectionName);
  })[0];

  const fields: any = { ...h1Section?.fields };
  return loading ? null : (
    <>
      <MetaTagsWrapper
        title={entry?.titleMetaTag || fields?.title}
        description={entry?.descriptionMetaTag}
        imageURL={entry?.openGraphImage?.fields.file.url}
      />
      {entry?.renderings?.map((section: WebPageComponents) => {
        const sectionName = section?.sys?.contentType?.sys?.id;
        if (!sectionName) return null;

        console.log("entry:", entry?.renderings);
        const RenderingsComponent = Renderings[sectionName];
        if (!RenderingsComponent) return null;
        return (
          <>
            <RenderingsComponent
              key={section.sys.id}
              entryID={section.sys.id}
              {...section.fields}
            />
          </>
        );
      })}
    </>
  );
};

export default memo(TemplateScreen);

import React, { FC, memo, useMemo } from "react";

import { Field, Form, Formik, FormikHelpers } from "formik";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import qs from "query-string";

import {
  Button,
  Container,
  FormTheme,
  Heading,
  Section,
  Text,
} from "@app/components";
import {
  FORMFIELD_VARIANT,
  FORMFIELD_LABEL_PROPS,
} from "@app/constants/themeConst";
import {
  AuthPathsEnum,
  passwordRegex,
  resetPassword,
} from "@app/features/auth/auth";
import { showNotification } from "@app/features/notification/notification";
import { useAppDispatch } from "@app/redux/store";
import { ResetPasswordDef } from "@app/types/api.types";
import { useHistory, useLocation } from "react-router-dom";
import useMetaTitle from "@app/hooks/useMetaTitle";

const ResetPasswordScreen: FC = memo(() => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  useMetaTitle(t("resetPassword.metaTitle"));

  const { recover } = qs.parse(location.search);
  // check if recover token is missing or invalid
  const invalidToken = !recover || typeof recover !== "string";

  const initialValues: ResetPasswordDef = {
    recoveryToken: recover as string,
    password: "",
  };

  const validation = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required(t("resetPassword.passwordErrorRequired"))
          .matches(passwordRegex, t("password.passwordRequiredFormat")),
      }),
    [t]
  );

  const handleSubmit = async (
    values: ResetPasswordDef,
    actions: FormikHelpers<ResetPasswordDef>
  ) => {
    const response = await dispatch(resetPassword(values));
    let message = "";
    if (resetPassword.fulfilled.match(response)) {
      message = t("resetPassword.messageSuccess");
      actions.resetForm();
      history.push(AuthPathsEnum.LOGIN);
    } else if (resetPassword.rejected.match(response)) {
      message = t("resetPassword.messageFailed");
    }

    dispatch(showNotification({ message }));
  };

  return (
    <>
      <Section withSeparatorSpacing>
        <Container indentStep={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Heading>{t("resetPassword.heading")}</Heading>
              <Text>{t("resetPassword.description")}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTheme>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validation}
                >
                  {({ isSubmitting }) => (
                    <Form noValidate>
                      {invalidToken && (
                        <Text>{t("resetPassword.invalidTokenMessage")}</Text>
                      )}
                      {!invalidToken && (
                        <>
                          <Field
                            component={TextField}
                            type="password"
                            id="password"
                            name="password"
                            label={t("resetPassword.passwordLabel")}
                            placeholder={t("resetPassword.passwordPlaceholder")}
                            variant={FORMFIELD_VARIANT}
                            InputLabelProps={FORMFIELD_LABEL_PROPS}
                          />
                          <Button
                            type="submit"
                            label={t("resetPassword.buttonLabel")}
                            loading={isSubmitting}
                          />
                        </>
                      )}
                    </Form>
                  )}
                </Formik>
              </FormTheme>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
});

export default ResetPasswordScreen;

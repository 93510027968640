import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { resourcesApi } from "../api/resources.api";
import { SubjectsDef } from "../types/resources.types";

interface SubjectsState {
  subjects: SubjectsDef[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: SubjectsState = {
  subjects: null,
  loading: false,
  error: false,
};

export const getSubjects = createAsyncThunk(
  "subjects/getSubjects",
  async () => {
    try {
      const response = await resourcesApi.getAllSubjects();
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

const subjectsSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    clearSubjects: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getSubjects.fulfilled, (state, action) => {
      state.loading = false;
      state.subjects = action.payload;
      state.error = false;
    });

    builder.addCase(getSubjects.pending, state => {
      state.loading = true;
      state.error = false;
    });
  },
});

export const { clearSubjects } = subjectsSlice.actions;

export default subjectsSlice.reducer;

import React, { FC, memo } from "react";
import { Heading, Button } from "@app/components";
import cx from "classnames";
import styles from "./EmptyStateComponent.module.scss";

export interface EmptyStateComponentProps extends Partial<HTMLDivElement> {
  title: string;
  ctaLabel: string;
  ctaLink: string;
}

const EmptyStateComponent: FC<EmptyStateComponentProps> = memo(
  ({ title, ctaLabel, ctaLink, className }) => {
    return (
      <div className={cx(styles.wrapper, className)}>
        {!!title && (
          <Heading className={styles.title} size="h3">
            {title}
          </Heading>
        )}
        {!!ctaLabel && !!ctaLink && (
          <Button
            className={styles.button}
            label={ctaLabel}
            to={ctaLink}
            tabIndex={-1}
          />
        )}
      </div>
    );
  }
);

export default EmptyStateComponent;

import React, { FC, memo } from "react";

import { Link, Text } from "@app/components";

import styles from "./FooterLinkItem.module.scss";

export interface FooterLinkItemProps {
  label: string;
  link: string;
}

const FooterLinkItem: FC<FooterLinkItemProps> = memo(({ label, link }) => {
  return (
    <li className={styles.footerLinkItem}>
      <Link to={link} className={styles.link}>
        <Text className={styles.title}>{label}</Text>
      </Link>
    </li>
  );
});

export default FooterLinkItem;

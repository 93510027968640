import React, { FC, memo } from "react";
import classnames from "classnames/bind";
// import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { Section, Container, CardList } from "@app/components";
import { ListTypeKeyEnum } from "@app/constants/ListTypeKeyConst";
import { Heading } from "@app/components/atoms/atoms";
import styles from "./GenericList.module.scss";

const cx = classnames.bind(styles);

type ListConfigDef = {
  [key: string]: {
    title: string;
    slider?: boolean;
  };
};

const listConfig: ListConfigDef = {
  [ListTypeKeyEnum.ACTIVITIES]: {
    title: "Activities in this Unit",
    slider: true,
  },
  [ListTypeKeyEnum.UNITS_BY_ACTIVITY]: {
    title: "Units this Activity supports",
    slider: true,
  },
};

export interface GenericListProps {
  data?: any[];
  listTypeKey?: ListTypeKeyEnum;
  title?: string;
  hasBackgroundColor?: boolean;
}

const GenericList: FC<GenericListProps> = memo(
  ({ data = [], listTypeKey = "", title, hasBackgroundColor }) => {
    /**
     * Get component settings
     */
    const { slider } = listConfig[listTypeKey];
    return (
      <Section background={hasBackgroundColor} withSeparatorSpacing fullWidth>
        <Container noOverflow={slider}>
          {title && (
            <div className={cx(styles.heading)}>
              <Heading size="h2" align="center">
                {title}
              </Heading>
            </div>
          )}
          <CardList list={data} useSlider={slider} />
        </Container>
      </Section>
    );
  }
);

export default GenericList;

export enum AuthEndpointsEnum {
  REFRESH_TOKEN = "auth/refresh-token",
  AUTH_CONFIG = "auth/config",
  LOGOUT = "auth/logout",
  USER = "user",
  SIGNUP_CONFIG = "user/config",
  SIGNUP_FIELDS_VALIDATE = "user/validate",
  USER_PASSWORD = "user/password",
  FORGOT_PASSWORD = "auth/request-recovery",
  RESET_PASSWORD = "auth/password-recovery",
}

import React, { FC, memo } from "react";
import { Heading, IconBox, Text, Link } from "@app/components";
import * as iconList from "@app/components/atoms/Icon/Icon";
import { IconBoxProps } from "@app/components/atoms/IconBox/IconBox";
import styles from "./IconBoxAndText.module.scss";

interface IconBoxAndTextProps extends Partial<HTMLDivElement> {
  title?: string;
  description: string;
  icon: string;
  iconBoxSize?: IconBoxProps["boxSize"];
  smallTitle?: boolean;
  link?: string;
}
const IconBoxAndText: FC<IconBoxAndTextProps> = memo(
  ({ title, description, icon, iconBoxSize, smallTitle, className, link }) => {
    const Icon = (iconList as any)[icon];
    const TitleComp = (
      <Heading size={smallTitle ? "subtitle2" : "subtitle1"} tag="h3">
        {title}
      </Heading>
    );

    return (
      <div className={className}>
        {Icon && (
          <IconBox
            className={styles.icon}
            boxSize={iconBoxSize}
            icon={<Icon />}
          />
        )}
        <div className={styles.text}>
          {!!title && (link ? <Link to={link}>{TitleComp}</Link> : TitleComp)}
          {!!description && <Text>{description}</Text>}
        </div>
      </div>
    );
  }
);

export { IconBoxAndText };

import * as React from "react";

function SvgLaptop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M122.5 83.125V26.25a8.75 8.75 0 00-8.75-8.75h-87.5a8.75 8.75 0 00-8.75 8.75v56.875zM134.872 110.197a8.75 8.75 0 01-7.997 12.303H13.125a8.75 8.75 0 01-7.998-12.303L17.5 83.125h105zM61.25 105h17.5"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgLaptop = React.memo(SvgLaptop);
export default MemoSvgLaptop;

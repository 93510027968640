import React, { FC, memo } from "react";
import { Section, Container, Heading, ItemList } from "@app/components";
import { GenericItemDef } from "@app/types/contentfulTypes";
import { useTranslation } from "react-i18next";

export interface LearningOutcomesProps {
  data?: GenericItemDef[];
}

const LearningOutcomes: FC<LearningOutcomesProps> = memo(({ data }) => {
  const { t } = useTranslation();
  const title = t("components.learningOutcomesHeading");
  return data ? (
    <Section withSeparatorSpacing>
      <Container indentStep={3}>
        {title && (
          <Heading tag="h2" size="h2" align="center" spacing="large">
            {title}
          </Heading>
        )}
        <ItemList items={data} listHorizontal itemsHorizontal={false} />
      </Container>
    </Section>
  ) : null;
});

export default LearningOutcomes;

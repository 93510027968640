import React, { FC, memo } from "react";
import { Typography } from "@material-ui/core";
import { Variants, TypographyCustomProps } from "@app/types/typographyTypes";
import classnames from "classnames/bind";
import styles from "./Heading.module.scss";

const cx = classnames.bind(styles);

export interface HeadingProps extends TypographyCustomProps {
  /**
   * @size maps value to material Ui's Variants, eg, h1, h2, h3 etc
   */
  size?: Variants;
  /**
   * @spacing - if spacing is used as boolean set a default margin-bottom,
   * otherwise use a string for string-literal css match
   */
  spacing?: boolean | "small" | "large";
}
/**
 * @size Variants
 * @spacing boolean | "small" | "large"
 */
const Heading: FC<HeadingProps> = memo(
  ({ children, size, tag = "h2", spacing, className, ...rest }) => {
    return (
      <Typography
        {...rest}
        component={tag}
        variant={size || tag}
        className={cx(
          {
            root: true,
            spacing: spacing && typeof spacing === "boolean",
            [`spacing_${spacing}`]: !!spacing && typeof spacing === "string",
          },
          className
        )}
      >
        {children}
      </Typography>
    );
  }
);

export { Heading };

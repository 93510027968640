import React, { FC, memo } from "react";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { Heading, Text, MediaWrapper, Link } from "@app/components";
import styles from "./SupportServices.module.scss";

export interface SupportServicesItem {
  fields: {
    logoImage: ContentfulImage;
    title: string;
    description: string;
    link?: string;
  };
}

const SupportServices: FC<SupportServicesItem> = memo(({ fields }) => {
  const { logoImage, title, description, link } = fields;

  const serviceItem = (
    <div className={styles.wrapper}>
      {logoImage?.fields && (
        <MediaWrapper className={styles.logo} cfImage={logoImage} natural />
      )}
      <div className={styles.textWrapper}>
        {!!title && <Heading size="subtitle2">{title}</Heading>}
        {!!description && <Text>{description}</Text>}
      </div>
    </div>
  );

  return link && link !== "" ? (
    <Link to={link}>{serviceItem}</Link>
  ) : (
    serviceItem
  );
});

export default SupportServices;

import React, { memo, useEffect, useRef } from "react";
import {
  Section,
  Container,
  Heading,
  Text,
  MediaWrapper,
} from "@app/components";
import { Grid } from "@material-ui/core";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./Video.module.scss";

interface VideoProps {
  title: string;
  subtitle: string;
  videoLink: string;
  image: ContentfulImage;
}

const Video = ({ title, subtitle, videoLink, image }: VideoProps) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const setupYouTubePlayer = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      }
    };

    window.addEventListener("beforeunload", setupYouTubePlayer);

    return () => {
      window.removeEventListener("beforeunload", setupYouTubePlayer);
    };
  }, []);

  return (
    <Section withSeparatorSpacing fullWidth>
      <Container indentStep={3}>
        <Grid container justify="center">
          <Grid item xs={12} lg={12}>
            {!!title && (
              <Heading className={styles.title} size="h2" align="center">
                {title}
              </Heading>
            )}
            {!!subtitle && <Text align="center">{subtitle}</Text>}
          </Grid>
          <Grid item xs={12}>
            {!!videoLink && (
              <div className={styles.videoWrapper}>
                <iframe
                  ref={iframeRef}
                  className={styles.iframe}
                  title={title}
                  src={videoLink}
                  loading="lazy"
                  scrolling="no"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  frameBorder="0"
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <MediaWrapper className={styles.image} cfImage={image} natural />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default memo(Video);

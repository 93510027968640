import React, { FC, memo } from "react";
import { Section, Container, Heading, Text, Button } from "@app/components";
import { Grid } from "@material-ui/core";
import cx from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import styles from "./CallToActionBanner.module.scss";

export interface CallToActionBannerProps {
  title: string;
  text: string;
  ctaLabel: string;
  ctaLink: string;
  isLightTheme?: boolean;
  hideForAuthenticated?: boolean;
  isLibraryPage?: boolean;
}

const CallToActionBanner: FC<CallToActionBannerProps> = memo(
  ({
    title,
    text,
    ctaLabel,
    ctaLink,
    isLightTheme,
    hideForAuthenticated,
    isLibraryPage,
  }) => {
    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    return isAuthenticated && hideForAuthenticated ? null : (
      <Section
        className={cx(
          styles.section,
          { [styles.light]: isLightTheme },
          { [styles.library]: isLibraryPage }
        )}
        fullWidth
      >
        <Container>
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              md={isLibraryPage ? 12 : 6}
              className={isLibraryPage ? styles.library : ""}
            >
              {!!title && (
                <Heading size="h2" align="center" className={styles.title}>
                  {title}
                </Heading>
              )}
              {!!text && (
                <Text align="center" className={styles.text}>
                  {text}
                </Text>
              )}
              {!!ctaLabel && !!ctaLink && (
                <div className={styles.buttonWrapper}>
                  <Button
                    inverted={!isLightTheme}
                    label={ctaLabel}
                    to={ctaLink}
                    arrow
                    tabIndex={-1}
                    // className={isLibraryPage ? styles.libraryButton : ""}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </Section>
    );
  }
);

export default CallToActionBanner;

import React, { FC, memo } from "react";
import Qualifications, { QualificationsProps } from "./Qualifications";

interface QualificationsContentfulProps {
  data: { fields: QualificationsProps };
}
const QualificationsContentful: FC<QualificationsContentfulProps> = memo(
  ({ data }) => {
    return <Qualifications {...data.fields} />;
  }
);

export default QualificationsContentful;

/* eslint-disable  */
import { CardFieldsDef } from "@app/types/contentfulTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resourcesApi } from "../api/resources.api";
import { ActivitiesThunkArg } from "../types/resources.types";

interface ActivitiesState {
  activities: {
    data: CardFieldsDef[];
    meta: {
      current_page: number;
      last_page: number;
      total: number;
      per_page: number;
    };
  };
  loadingActivities: boolean;
  loadingMoreActivities: boolean;
  error: boolean;
}

const initialState: ActivitiesState = {
  activities: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      total: 1,
      per_page: 6,
    },
  },
  loadingActivities: false,
  loadingMoreActivities: false,
  error: false,
};

export const getActivities = createAsyncThunk(
  "activities/getActivities",
  async (arg: ActivitiesThunkArg) => {
    const { filter, isMosaic } = arg;
    try {
      const response = await resourcesApi.getAllActivities({
        ...filter,
        isMosaic,
      });
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const getActivitiesAndAppend = createAsyncThunk(
  "activities/getActivitiesAndAppend",
  async (arg: ActivitiesThunkArg) => {
    const { filter, isMosaic } = arg;
    try {
      const response = await resourcesApi.getAllActivities({
        ...filter,
        isMosaic,
      });
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

const ActivitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    clearActivities: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getActivities.pending, state => {
      state.loadingActivities = true;
      state.error = false;
    });

    builder.addCase(getActivities.fulfilled, (state, action) => {
      state.loadingActivities = false;
      state.activities.data = action.payload.data;
      state.activities.meta = action.payload.meta;
      state.error = false;
    });

    builder.addCase(getActivitiesAndAppend.pending, state => {
      state.loadingMoreActivities = true;
      state.error = false;
    });

    builder.addCase(getActivitiesAndAppend.fulfilled, (state, action) => {
      state.loadingMoreActivities = false;
      state.activities.data = [
        ...state.activities.data,
        ...action.payload.data,
      ];
      state.activities.meta = action.payload.meta;
      state.error = false;
    });
  },
});

export const { clearActivities } = ActivitiesSlice.actions;

export default ActivitiesSlice.reducer;

import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  memo,
} from "react";
import classnames from "classnames/bind";
import { Link, useLocation } from "react-router-dom";
import chevronWhite from "@app/assets/images/PT-chevron_white.png";
import chevronDark from "@app/assets/images/PT-chevron_dark.png";
import { CircularProgress } from "@material-ui/core";
import { isURL } from "@app/helpers/utilHelper";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import styles from "./Button.module.scss";
import ExternalLink from "../ExternalLink/ExternalLink";

const cx = classnames.bind(styles);

export interface ButtonProps
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "id"
  > {
  /**
   * Add id to button or link (optional)
   */
  id?: string;
  /**
   * The label in the button
   */
  label?: string;
  /**
   * shortcut for button types
   */
  primary?: boolean;
  secondary?: boolean;
  inverted?: boolean;
  /**
   * round type of button, can be combined with primary and secondary
   */
  round?: boolean;
  /**
   * outline addon to type
   */
  outline?: boolean;
  /**
   * shortcut for sizes
   */

  small?: boolean;
  /**
   * Set to true, if the button will submit a form
   */
  xSmall?: boolean;
  /**
   * Set to true, if the button will submit a form
   */
  submit?: boolean;
  /**
   * Turn button into link (optional)
   */
  to?: string;
  /**
   * align element to the right
   */
  right?: boolean;
  /**
   * Arrow: use dark or white custom chevron png in stead of icon
   */
  arrow?: boolean;
  /**
   * Set to true, to show loading state in button
   */
  loading?: boolean;
  /**
   * Set to true, to remove border around button
   */
  removeBorder?: boolean;
  tabIndex?: number;
}

/**
 * Custom button
 */
const Button: FC<ButtonProps> = memo(
  ({
    id,
    label,
    primary = true,
    secondary,
    inverted,
    round,
    small,
    xSmall,
    submit,
    to,
    className,
    outline,
    right,
    arrow,
    loading,
    removeBorder,
    tabIndex,
    ...rest
  }) => {
    const location = useLocation();

    const { locale } = useSelector((state: RootState) => state.localization);

    const currentLocale = locale.substring(0, 2);
    /**
     * Ui rules for use of arrow
     * - Round buttons doesn't use arrows
     * - primary and secondary use white arrow...
     * - unless its "outline", then it uses dark arrow
     */
    let arrowSrc = "";
    if (arrow && !round) {
      arrowSrc = outline || inverted ? chevronDark : chevronWhite;
    }

    const button = (
      <button
        type={submit ? "submit" : "button"}
        tabIndex={tabIndex ?? 0}
        className={cx(
          styles.button,
          {
            primary,
            secondary,
            round,
            small,
            xSmall,
            outline,
            right,
            inverted,
            loading,
            removeBorder,
          },
          className
        )}
        {...(!to && !!id && { id: `${id}-button` })}
        {...rest}
      >
        {loading ? (
          <CircularProgress color="inherit" size={32} />
        ) : (
          <>
            {!!label && (
              <span className={cx(styles.buttonText, { xSmall })}>{label}</span>
            )}
            {!!arrowSrc && (
              <img src={arrowSrc} className={styles.arrow} alt="" />
            )}
          </>
        )}
      </button>
    );

    if (!to) return button;
    const linkId = id ? `${id}-link` : undefined;
    return isURL(to) ? (
      <ExternalLink className={styles.link} url={to} id={linkId}>
        {button}
      </ExternalLink>
    ) : (
      <Link
        id={linkId}
        className={styles.link}
        to={{
          pathname: `/${currentLocale}${to}`,
          state: { prevPath: location.pathname },
        }}
      >
        {button}
      </Link>
    );
  }
);

export { Button };

export const TRANSLATION_LOCALE_KEY = "i18n-locale";
export const TRANSLATION_KEY = "i18n-translations";

export enum LocalesEnum {
  ENGLISH = "en-US",
  WELSH = "cy-GB",
}
export enum LocalesForAcceptLanguageHeader {
  ENGLISH = "en_US",
  WELSH = "cy_GB",
}

import * as React from "react";

function SvgSchoolTestResults(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M30.625 118.125h-17.5a8.75 8.75 0 01-8.75-8.75v-96.25a8.75 8.75 0 018.75-8.75h62.002a8.75 8.75 0 016.184 2.56L98.064 23.69a8.75 8.75 0 012.561 6.183v27.003M118.125 102.567v26.74l-21.875 6.318-21.875-6.317v-26.74"
          strokeWidth={8.749995}
        />
        <path
          d="M135.625 97.125L96.25 82.775l-39.375 14.35 39.375 12.25 39.375-12.25zM56.875 97.125v19.542M21.875 26.25h8.75M48.125 26.25h26.25M21.875 48.125h8.75M48.125 48.125h26.25M21.875 70h8.75M21.875 91.875h8.75M48.125 70h26.25"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgSchoolTestResults = React.memo(SvgSchoolTestResults);
export default MemoSvgSchoolTestResults;

import React, { FC, memo } from "react";
import cx from "classnames";

interface ExternalLinkProps {
  children: React.ReactNode;
  id?: string;
  url: string;
  className?: string;
  title?: string;
}

const ExternalLink: FC<ExternalLinkProps> = memo(
  ({ children, id, url, className, title }) => (
    <a
      id={id}
      href={url}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      className={cx(className)}
    >
      {children}
    </a>
  )
);

export default ExternalLink;

import * as React from "react";

function SvgStopwatch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M17.5 83.125a52.5 52.5 0 10105 0 52.5 52.5 0 10-105 0zM105 43.75l10.938-10.938M113.75 30.625L118.125 35M70 30.625V4.375M83.125 4.375h-26.25M70 87.5L48.125 63.286"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgStopwatch = React.memo(SvgStopwatch);
export default MemoSvgStopwatch;

import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { CreateUserDef } from "@app/types/api.types"; // , UpdateUserDef, UserDef
import { AuthEndpointsEnum } from "@app/features/auth/auth";

const createUser = (data: CreateUserDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.USER, data);
};

const signupConfig = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpointsEnum.SIGNUP_CONFIG);
};

const signupFieldsValidate = (data: any): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.SIGNUP_FIELDS_VALIDATE, data);
};

export const signupApi = {
  createUser,
  signupConfig,
  signupFieldsValidate,
};

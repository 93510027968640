import React, { FC, memo } from "react";
import { Grid } from "@material-ui/core";
import { Heading, Text, MediaWrapper } from "@app/components";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { ReactComponent as QuoteIcon } from "@app/assets/images/quote.svg";
import styles from "./TestimonialItem.module.scss";

export interface TestimonialItemProps {
  quote: string;
  subQuote: string;
  author: string;
  occupation: string;
  logo: ContentfulImage;
  image: ContentfulImage;
}
const TestimonialItem: FC<TestimonialItemProps> = memo(
  ({ quote, subQuote, author, occupation, logo, image }) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className={styles.quoteWrapper}>
          <QuoteIcon className={styles.quoteIcon} />
          {!!quote && (
            <Heading className={styles.quote} size="h2">
              {quote}
            </Heading>
          )}
          {!!subQuote && <Text>{subQuote}</Text>}
          <div className={styles.authorSection}>
            <div className={styles.authorText}>
              {!!author && <Text className={styles.author}>{author}</Text>}
              {!!occupation && <Text small>{occupation}</Text>}
            </div>
            {logo && (
              <MediaWrapper className={styles.logo} cfImage={logo} natural />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {image && <MediaWrapper cfImage={image} ratio={0.68} />}
        </Grid>
      </Grid>
    );
  }
);

export default TestimonialItem;

import React, { FC, memo, useState } from "react";

import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { MissingAccessModal } from "@app/components/molecules/molecules";
import { RootState } from "@app/redux/rootReducer";
import { Text } from "@app/components";

import { useLocation } from "react-router-dom";
import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { DocumentDef } from "../../DocumentDownload";
import FileLink from "../FileLink/FileLink";
import styles from "./DownloadAccordion.module.scss";

interface DownloadAccordionProps {
  activeRegion: DocumentDef[];
}

const DownloadAccordion: FC<DownloadAccordionProps> = memo(
  ({ activeRegion }) => {
    const location = useLocation();
    const [showMissingAccessModal, setShowMissingAccessModal] = useState(false);
    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    const toggleModal = () => {
      setShowMissingAccessModal(prevState => !prevState);
    };

    const currentLibrary = location.pathname.includes(
      ContentfulPathsEnum.MOSAIC_LIBRARY
    )
      ? "mosaic"
      : "achieve";

    return (
      <>
        <div className={styles.resources}>
          {activeRegion.map(
            ({ fields }) =>
              fields?.files?.length > 0 && (
                <Accordion
                  key={fields.name}
                  defaultExpanded
                  className={styles.accordion}
                >
                  <AccordionSummary
                    id={fields.name}
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.accordionTitle}
                  >
                    <Text lead>{fields.name}</Text>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.documentsWrapper}>
                      {fields.files.map(item => (
                        <FileLink
                          key={item.sys.id}
                          file={item.fields.file}
                          title={item.fields.title}
                          isAuthenticated={isAuthenticated}
                          onClick={toggleModal}
                          isMosaicLibrary={fields?.isMosaicLibrary}
                        />
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
          )}
        </div>
        <MissingAccessModal
          open={showMissingAccessModal}
          onClose={toggleModal}
          currentLibrary={currentLibrary}
        />
      </>
    );
  }
);

export { DownloadAccordion };

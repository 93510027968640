import React, { memo } from "react";
import { Checkbox, CheckboxProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 4,
    width: 24,
    height: 24,
    backgroundColor: "rgba(44, 44, 44, 0.1)",

    "$root.Mui-focusVisible &": {
      outline: "-webkit-focus-ring-color auto 3px",

      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "rgba(44, 44, 44, 0.2)",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#1E1964",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#1E1964",
    },
  },
});

interface AdditionalCheckboxProps extends CheckboxProps {
  label?: string;
}

const CustomCheckbox = ({
  checked,
  onChange,
  name,
  color,
  label,
}: AdditionalCheckboxProps) => {
  const classes = useStyles();

  return (
    <Checkbox
      inputProps={{
        "aria-labelledby": label,
      }}
      className={classes.root}
      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      checked={checked}
      onChange={onChange}
      name={name}
      color={color}
      focusRipple
    />
  );
};

export default memo(CustomCheckbox);

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { LocalesEnum } from "../constants/localization.keys";

const currentLocale = localStorage.getItem("i18n-locale");

const INITIAL_LANG = JSON.parse(currentLocale as string) || LocalesEnum.ENGLISH;

export const DEFAULT_NS = "translation";

i18next.use(initReactI18next).init({
  fallbackLng: INITIAL_LANG,
  lng: INITIAL_LANG,
  supportedLngs: [LocalesEnum.ENGLISH, LocalesEnum.WELSH],
  interpolation: {
    escapeValue: false,
  },
  defaultNS: DEFAULT_NS,
  resources: {},
});

export default i18next;

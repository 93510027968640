import React, { FC, memo } from "react";
import { Grid } from "@material-ui/core";
import { CardDef } from "@app/types/contentfulTypes";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { SwiperCarousel, SwiperSlide, Card } from "@app/components/";
import { SwiperBreakpointsDef } from "@app/components/molecules/SwiperCarousel/SwiperCarousel";
import { ScreenSizes } from "@app/constants/breakpoints";
import { ResourcesView } from "@app/types/layoutDef";
import cx from "classnames";
import styles from "./CardList.module.scss";
import EventCard from "../EventCard/EventCard";

export interface CardListProps {
  slidesNumber?: number;
  list: CardDef[];
  category?: ResourceTypeEnum;
  onlyTitle?: boolean;
  useSlider?: boolean;
  disableBookmarks?: boolean;
  deleteItem?: (e: React.MouseEvent<HTMLElement>, id: string) => void;
  listViewType?: ResourcesView;
  isWhiteControls?: boolean;
  showFourInRow?: boolean;
}

const CardList: FC<CardListProps> = memo(
  ({
    list,
    category,
    onlyTitle,
    useSlider,
    disableBookmarks,
    deleteItem,
    listViewType,
    slidesNumber = 4,
    isWhiteControls = false,
    showFourInRow = false,
  }) => {
    const swiperBreakpoints: SwiperBreakpointsDef = {
      [ScreenSizes.XS]: {
        slidesPerView: 1.15,
        slidesPerGroup: 1.15,
      },
      [ScreenSizes.SM]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [ScreenSizes.MD]: {
        slidesPerView: slidesNumber,
        slidesPerGroup: slidesNumber,
      },
    };

    const RenderCard: FC<CardDef> = ({ fields, sys }) => {
      return (
        <Card
          {...fields}
          sys={sys}
          resourceType={category}
          onlyTitle={onlyTitle}
          onDeleteItem={deleteItem}
          isBookmarked={fields.isBookmarked}
          disableBookmarks={
            disableBookmarks || fields.contentType === "collection"
          }
          listView={listViewType === "list"}
          noBorder={isWhiteControls}
        />
      );
    };

    return !useSlider ? (
      <Grid container spacing={3} className={styles.cardList}>
        {list.map(({ fields, sys }, index) => {
          const { id } = sys;
          const key = `${id}_${index}`;
          return (
            fields && (
              <>
                {listViewType && listViewType === "grid" ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={showFourInRow ? 3 : 4}
                    key={key}
                  >
                    <RenderCard fields={fields} sys={sys} />
                  </Grid>
                ) : (
                  <Grid item xs={12} key={key}>
                    <RenderCard fields={fields} sys={sys} />
                  </Grid>
                )}
              </>
            )
          );
        })}
      </Grid>
    ) : (
      <div className={cx({ [styles.whiteControls]: isWhiteControls })}>
        <SwiperCarousel
          swiperBreakpoints={swiperBreakpoints}
          enableLoop={false}
          showNavArrows={list.length > slidesNumber}
          showPagination={list.length > slidesNumber}
          spaceBetween={20}
          hideExcessMode
          navBtnsClassName={styles.cardListNavBtnsWrapper}
        >
          {list &&
            list.map(({ fields, sys }, index) => {
              const isEvent = sys?.contentType?.sys?.id === "event";

              const { id } = sys;
              const key = `${id}_${index}`;
              return (
                fields && (
                  <SwiperSlide key={key}>
                    {isEvent ? (
                      <EventCard fields={fields} sys={sys} />
                    ) : (
                      <RenderCard fields={fields} sys={sys} />
                    )}
                  </SwiperSlide>
                )
              );
            })}
        </SwiperCarousel>
      </div>
    );
  }
);

export { CardList };

import * as React from "react";

function SvgMultipleUsers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M13.125 35a13.125 13.125 0 1026.25 0 13.125 13.125 0 10-26.25 0zM26.25 56.875A21.875 21.875 0 004.375 78.75v13.125h8.75l4.375 35H35M100.625 35a13.125 13.125 0 1026.25 0 13.125 13.125 0 10-26.25 0zM113.75 56.875a21.875 21.875 0 0121.875 21.875v13.125h-8.75l-4.375 35H105M52.5 21.875a17.5 17.5 0 1035 0 17.5 17.5 0 10-35 0zM100.625 78.75a30.625 30.625 0 00-61.25 0v13.125H52.5l4.375 43.75h26.25l4.375-43.75h13.125z"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgMultipleUsers = React.memo(SvgMultipleUsers);
export default MemoSvgMultipleUsers;

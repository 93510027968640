import React, { FC, memo, useEffect, useState } from "react";
import { ThemeEnum } from "@app/constants/themeConst";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { Grid } from "@material-ui/core";
import classnames from "classnames/bind";
import { ContentfulImage } from "@app/types/contentfulTypes";
import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import {
  Heading,
  Text,
  Section,
  MediaWrapper,
  Container,
  Tag,
  Link,
} from "@app/components";
import { useTranslation } from "react-i18next";
import styles from "./Jumbotron.module.scss";

const cx = classnames.bind(styles);

export interface JumbotronProps {
  /**
   * Set theme for section, which will control section backgrounds color and nested components
   */
  theme: ThemeEnum;
  /**
   * Set the main heading of the hero content
   */
  title?: string;
  /**
   * Set the description of the hero content below the main heading
   */
  description?: string;
  /**
   * Set the background image of the hero component
   */
  image?: ContentfulImage;
  /**
   * optinal LOGO image
   */
  logo?: ContentfulImage;
  /**
   * Resource type: collection, unit, activity
   */
  resourceType?: ResourceTypeEnum;
  /**
   * type, only for units (and detail pages)
   */
  type?: string[];
  /**
   * type, only for units (and detail pages)
   */
  credits?: string;
  /**
   * Duration shown on detail pages
   */
  duration?: string;
  /**
   * Detail mode - if true image in hero will be slightly bigger
   */
  detailMode?: boolean;
  isNew?: boolean;
  isFeatured?: boolean;
  parentUnit?: any;
}

const Jumbotron: FC<JumbotronProps> = memo(
  ({
    title,
    image,
    logo,
    description,
    resourceType,
    type,
    duration,
    detailMode,
    credits,
    isNew,
    isFeatured,
    parentUnit,
  }) => {
    const { t } = useTranslation();
    const [routePrefix, setRoutePrefix] = useState("");

    useEffect(() => {
      setRoutePrefix(ContentfulPathsEnum.UNIT_DETAIL);
    }, []);

    const translateResourceType = (currentType?: ResourceTypeEnum) => {
      if (currentType === ResourceTypeEnum.UNIT) {
        return t("uiLabels.unitsFilterLabel");
      }
      if (currentType === ResourceTypeEnum.ACTIVITY) {
        return t("uiLabels.activitiesFilterLabel");
      }
      if (currentType === ResourceTypeEnum.COLLECTION) {
        return t("uiLabels.collectionsFilterLabel");
      }
      return currentType;
    };

    return (
      <Section className={styles.root} fullWidth>
        <Container indentStep={3}>
          {logo?.fields && (
            <div className={styles.logo}>
              <MediaWrapper cfImage={logo} natural />
            </div>
          )}
          <Grid container className={styles.jumboGrid}>
            {(resourceType || type || credits) && (
              <Grid item xs={12} className={styles.detailInfo}>
                <div className={styles.tags}>
                  {isNew && <Tag isNew>{t("uiLabels.newLabel")}</Tag>}
                  {isFeatured && (
                    <Tag isFeatured>{t("uiLabels.featuredLabel")}</Tag>
                  )}
                  <Tag resource={resourceType}>
                    {translateResourceType(resourceType)}
                  </Tag>
                </div>
                <Text className={styles.unitType}>
                  {type && type.map(item => <span key={item}>{item} </span>)}
                  {type && <span>Unit</span>}
                </Text>
                {!!credits && <Text className={styles.credits}>{credits}</Text>}
              </Grid>
            )}

            <Grid
              item
              xs={12}
              lg={resourceType === ResourceTypeEnum.COLLECTION ? 12 : 6}
            >
              {title && (
                <Heading
                  size="h1"
                  tag="h1"
                  className={styles.heading}
                  align={
                    resourceType === ResourceTypeEnum.COLLECTION
                      ? "center"
                      : "left"
                  }
                >
                  {title}
                </Heading>
              )}
              {duration && (
                <Text faded className={styles.duration}>
                  {duration}
                </Text>
              )}
              {resourceType === ResourceTypeEnum.ACTIVITY && parentUnit && (
                <Link
                  to={`${routePrefix}/${parentUnit.sys.id}`}
                  key={parentUnit.sys.id}
                >
                  <Text faded className={styles.duration}>
                    {parentUnit.fields.title}
                  </Text>
                </Link>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              lg={resourceType === ResourceTypeEnum.COLLECTION ? 12 : 6}
            >
              {description && (
                <Text
                  className={styles.description}
                  align={
                    resourceType === ResourceTypeEnum.COLLECTION
                      ? "center"
                      : "left"
                  }
                >
                  {description}
                </Text>
              )}
            </Grid>
            <Grid
              item
              className={cx(styles.media, {
                detailMode,
              })}
            >
              <div className={styles.imageWrapper}>
                {image?.fields && (
                  <MediaWrapper
                    className={cx({ detailsImage: detailMode })}
                    cfImage={image}
                    walledOnSmall
                    ratio={0.4662}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    );
  }
);

export default Jumbotron;

import { api } from "@app/api/api";

import { ContactEndpointsEnum } from "../constants/contact.endpoints";
import { ContactDef } from "../types/contact.types";

const sendContactForm = (data: ContactDef) => {
  return api.post(ContactEndpointsEnum.CONTACT, data);
};

export const contactApi = {
  sendContactForm,
};

import React, { memo, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  getLocalization,
  LocalesEnum,
  setLocale,
} from "@app/features/localization/localization";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconGlobe } from "@app/components/atoms/Icon/Icon";
import styles from "./LanguageSwitcher.module.scss";

const LanguageSwitcher = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const { locale: currentLocale } = useSelector(
    (state: RootState) => state.localization
  );

  const [lang, setLang] = useState(currentLocale ?? LocalesEnum.ENGLISH);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setLang(value);
    dispatch(setLocale(value));
    dispatch(getLocalization(value));
    history.push({
      pathname: `/${value.substring(0, 2)}${location.pathname?.substring(3)}`,
      search: location.search,
    });
  };

  return (
    <Select
      className={styles.selectWrapper}
      variant="outlined"
      color="secondary"
      id="LanguageSwitcherSelect"
      value={lang}
      onChange={handleChange}
    >
      <MenuItem value={LocalesEnum.ENGLISH}>
        <IconGlobe className={styles.icon} />
        {t("uiLabels.languageSwitcherSelectEnglish")}
      </MenuItem>
      <MenuItem value={LocalesEnum.WELSH}>
        <IconGlobe className={styles.icon} />
        {t("uiLabels.languageSwitcherSelectWelsh")}
      </MenuItem>
    </Select>
  );
};

export default memo(LanguageSwitcher);

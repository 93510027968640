import * as React from "react";

function SvgConversationChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M135.625 78.75A30.62 30.62 0 00105 48.125H87.5a30.625 30.625 0 000 61.25h4.375l26.25 26.25v-29.307a30.55 30.55 0 0017.5-27.568zM39.375 74.375l-17.5 17.5V62.568A30.56 30.56 0 0135 4.375h17.5A30.637 30.637 0 0181.86 26.25"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgConversationChat = React.memo(SvgConversationChat);
export default MemoSvgConversationChat;

import React, { FC, memo } from "react";
import classnames from "classnames/bind";

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { INLINES, Document } from "@contentful/rich-text-types";
import { Link } from "@app/components";
import styles from "./RichText.module.scss";
import { AssetLink } from "./Components/AssetLink/AssetLink";

const cx = classnames.bind(styles);

type RichTextProps = {
  lead?: boolean;
  className?: string;
  cfDocument?: Document;
  children?: React.ReactNode;
};
/**
 * * @richText is parsed with documentToReactComponents from contentful
 * @assets are handled with sub component
 
 */
const RichText: FC<RichTextProps> = memo(
  ({ lead, className, cfDocument, children }) => {
    const options: Options = {
      renderNode: {
        [INLINES.ASSET_HYPERLINK]: node => <AssetLink asset={node} />,
        [INLINES.HYPERLINK]: (node, child) => (
          <Link to={node.data.uri} showExternalIcon>
            {child}
          </Link>
        ),
      },
    };

    const renderRichText =
      cfDocument && documentToReactComponents(cfDocument, options);

    return (
      <div
        className={cx(styles.richTextStyling, className, {
          lead,
        })}
      >
        {renderRichText || children}
      </div>
    );
  }
);

export { RichText };

import { useState, useEffect } from "react";
import { CreateUserDef, UserDef } from "@app/types/api.types";
import { signupApi } from "../api/signup.api";

// TODO: make type for formFields...
const useCreateUser = (formFields: any, { shouldCallApi = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [createdResponse, setCreatedResponse] = useState<UserDef | null>();

  // TODO: refactor into types
  const {
    educationCenter,
    email,
    firstName,
    lastName,
    newsletter,
    organisation,
    password,
    phone,
    roles,
    terms,
  } = formFields;

  const objData: CreateUserDef = {
    email: email.value,
    firstName: firstName.value,
    lastName: lastName.value,
    phone: phone.value,
    password,
    marketing: newsletter.value,
    roleId: roles.value,
    educationCentreId: educationCenter.value,
    organisation: organisation.value,
    terms: terms.value,
  };

  const createUser = (data: CreateUserDef) => {
    setIsLoading(true);
    setIsError(false);
    signupApi
      .createUser(data)
      .then(r => {
        setCreatedResponse(r.data);
        setIsSuccess(true);
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    shouldCallApi && createUser(objData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallApi, formFields]);

  return { isLoading, isSuccess, isError, errorMessage, createdResponse };
};

export default useCreateUser;

import React, { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import {
  WebPageDataModel,
  WebPageComponents,
} from "@app/types/contentfulTypes";
import { deleteResourceFromBookmarks } from "@app/features/bookmarks/bookmarks";
import { showNotification } from "@app/features/notification/notification";
import { useAppDispatch } from "@app/redux/store";
import { useSingleEntry } from "@app/features/contentful/contentful";
import Renderings from "@app/components/renderings/";
import SavedResources from "@app/components/renderings/SavedResources/SavedResources";
import useMetaTitle from "@app/hooks/useMetaTitle";

import styles from "./DashboardScreen.module.scss";

interface ContentfulTemplateProps {
  contentfulPageKey?: string;
}

const DashboardScreen: FC<ContentfulTemplateProps> = memo(
  ({ contentfulPageKey = "" }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { entry } = useSingleEntry<WebPageDataModel>(contentfulPageKey);
    useMetaTitle(entry?.titleMetaTag, !!entry);

    const deleteResource = async (
      e: React.MouseEvent<HTMLElement>,
      contentfulId: string
    ) => {
      e.preventDefault();
      const response = await dispatch(
        deleteResourceFromBookmarks(contentfulId)
      );
      if (deleteResourceFromBookmarks.fulfilled.match(response)) {
        dispatch(
          showNotification({
            message: t("uiLabels.removedFromBookmarks"),
          })
        );
      }
    };

    return (
      <div className={styles.wrapper}>
        {entry?.renderings?.map((section: WebPageComponents) => {
          const sectionName = section?.sys?.contentType?.sys?.id;
          if (!sectionName) return null;

          const RenderingsComponent = Renderings[sectionName];
          if (!RenderingsComponent) return null;
          return (
            <RenderingsComponent
              key={section.sys.id} // needed?
              entryID={section.sys.id} // needed?
              {...section.fields}
            />
          );
        })}
        <SavedResources
          className={styles.savedResources}
          deleteResource={deleteResource}
        />
      </div>
    );
  }
);

export default DashboardScreen;

import * as React from "react";

function SvgCertifiedCertificate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M39.375 109.375V122.5a13.125 13.125 0 01-13.125 13.125H87.5a13.125 13.125 0 0013.125-13.125V22.808c0-3.301 2.555-18.433 17.5-18.433a17.5 17.5 0 0117.5 17.5v17.5h-35M118.125 4.375h-52.5M15.966 52.4L11.27 86.696a4.38 4.38 0 007.402 3.885l11.953-11.958L42.583 90.58a4.38 4.38 0 007.403-3.885l-4.667-34.294"
          strokeWidth={8.749995}
        />
        <path
          d="M4.375 30.625a26.25 26.25 0 1052.5 0 26.25 26.25 0 10-52.5 0zM30.625 28.438h0"
          strokeWidth={8.749995}
        />
        <path
          d="M30.625 28.438a2.188 2.188 0 102.188 2.187 2.188 2.188 0 00-2.188-2.188"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgCertifiedCertificate = React.memo(SvgCertifiedCertificate);
export default MemoSvgCertifiedCertificate;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { resourcesApi } from "../api/resources.api";
import { QualificationsDef } from "../types/resources.types";

interface QualificationsState {
  qualifications: QualificationsDef[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: QualificationsState = {
  qualifications: null,
  loading: false,
  error: false,
};

export const getQualifications = createAsyncThunk(
  "qualifications/getQualificationTypes",
  async () => {
    try {
      const response = await resourcesApi.getQualificationTypes();
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

const qualificationsSlice = createSlice({
  name: "qualifications",
  initialState,
  reducers: {
    clearQualifications: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getQualifications.fulfilled, (state, action) => {
      state.loading = false;
      state.qualifications = action.payload;
      state.error = false;
    });

    builder.addCase(getQualifications.pending, state => {
      state.loading = true;
      state.error = false;
    });
  },
});

export const { clearQualifications } = qualificationsSlice.actions;

export default qualificationsSlice.reducer;

import React, { FC, memo } from "react";
import {
  Section,
  Container,
  Heading,
  Text,
  MediaWrapper,
} from "@app/components";
import { Grid } from "@material-ui/core";
import { ContentfulImage } from "@app/types/contentfulTypes";
import styles from "./SupportComponent.module.scss";
import SupportServices, {
  SupportServicesItem,
} from "./components/SupportServices/SupportServices";

export interface SupportComponentProps {
  title: string;
  description: string;
  subTitle: string;
  image: ContentfulImage;
  services: SupportServicesItem[];
}

const SupportComponent: FC<SupportComponentProps> = memo(
  ({ title, description, image, subTitle, services = [] }) => {
    return (
      <Section className={styles.section} withSeparatorSpacing fullWidth>
        <Container>
          <Grid container justify="space-between">
            <Grid item xs={12} md={6}>
              {!!title && (
                <Heading size="h2" className={styles.title}>
                  {title}
                </Heading>
              )}
              {!!description && (
                <Text className={styles.description}>{description}</Text>
              )}
              {services.length > 0 && (
                <div className={styles.services}>
                  {!!subTitle && (
                    <Heading size="h3" className={styles.subTitle}>
                      {subTitle}
                    </Heading>
                  )}
                  {services.map(service => (
                    <SupportServices
                      key={service?.fields.title}
                      fields={service.fields}
                    />
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              {image?.fields && <MediaWrapper cfImage={image} ratio={1.32} />}
            </Grid>
          </Grid>
        </Container>
      </Section>
    );
  }
);

export default SupportComponent;

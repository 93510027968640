import React, { FC, memo, useEffect } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import {
  WebPageDataModel,
  WebPageComponents,
} from "@app/types/contentfulTypes";
import { getSubjects } from "@app/features/resources/resources";
import { RootState } from "@app/redux/rootReducer";
import { useAppDispatch } from "@app/redux/store";
import { useSingleEntry } from "@app/features/contentful/contentful";
import Renderings from "@app/components/renderings/";
import ResourceHub from "@app/components/renderings/ResourceHub/ResourceHub";
import { bookmarksAPILimit } from "@app/constants/api.constants";
import { getBookmarks } from "@app/features/bookmarks/bookmarks";
import MetaTagsWrapper from "@app/components/molecules/MetaTagsWrapper/MetaTagsWrapper";
import styles from "./MosaicResourcesHubScreen.module.scss";

interface ContentfulTemplateProps {
  contentfulPageKey?: string;
}

const MosaicResourcesHubScreen: FC<ContentfulTemplateProps> = memo(
  ({ contentfulPageKey = "" }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { subjects } = useSelector((state: RootState) => state.subjects);
    const { entry } = useSingleEntry<WebPageDataModel>(contentfulPageKey);
    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    useMount(() => {
      dispatch(getSubjects());
    });

    useEffect(() => {
      if (isAuthenticated) dispatch(getBookmarks({ limit: bookmarksAPILimit }));
    }, [dispatch, isAuthenticated]);

    const titleMetaTagAlternative = /introcomponent|jumbotronhome|jumbotron/gi;

    const h1Section = entry?.renderings?.filter(
      (section: WebPageComponents) => {
        const sectionName = section?.sys?.contentType?.sys?.id;
        if (!sectionName) return false;
        return titleMetaTagAlternative.test(sectionName);
      }
    )[0];

    const fields: any = { ...h1Section?.fields };

    return (
      <>
        <MetaTagsWrapper
          title={entry?.titleMetaTag || fields?.title}
          description={entry?.descriptionMetaTag}
          imageURL={entry?.openGraphImage?.fields.file.url}
        />
        <div className={styles.wrapper}>
          {entry?.renderings
            ?.filter((_, index) => index <= 1)
            .map((section: WebPageComponents) => {
              const sectionName = section?.sys?.contentType?.sys?.id;
              if (!sectionName) return null;

              const RenderingsComponent = Renderings[sectionName];
              if (!RenderingsComponent) return null;
              return (
                <RenderingsComponent
                  key={section.sys.id} // needed?
                  entryID={section.sys.id} // needed?
                  {...section.fields}
                />
              );
            })}
          {subjects && subjects.length > 0 && (
            <ResourceHub
              title={t("components.resourcesHubTitle")}
              subjects={subjects}
              showFilters={false}
              additionalFilters={false}
              isMosaic
            />
          )}
          {entry?.renderings
            ?.filter((_, index) => index > 1)
            .map((section: WebPageComponents) => {
              const sectionName = section?.sys?.contentType?.sys?.id;
              if (!sectionName) return null;

              const RenderingsComponent = Renderings[sectionName];
              if (!RenderingsComponent) return null;
              return (
                <RenderingsComponent
                  key={section.sys.id} // needed?
                  entryID={section.sys.id} // needed?
                  {...section.fields}
                />
              );
            })}
        </div>
      </>
    );
  }
);

export default MosaicResourcesHubScreen;

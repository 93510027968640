import React, { FC, memo, useState } from "react";
import { Section, Container, Heading, Text } from "@app/components";
import AccordionComponent, {
  AccordionItem,
} from "@app/components/molecules/AccordionComponent/AccordionComponent";
import { useTranslation } from "react-i18next";
import styles from "./FAQ.module.scss";

export interface FAQProps {
  title: string;
  description: string;
  items: AccordionItem[];
  mosaicItem: AccordionItem[];
  enterpriseItem: AccordionItem[];
}

const FAQ: FC<FAQProps> = memo(
  ({
    title = "",
    description,
    items = [],
    mosaicItem = [],
    enterpriseItem = [],
  }) => {
    type TCategory = "All" | "Achieve" | "Mosaic" | "Enterprise Challenge";

    const [selectedProgram, setSelectedProgram] = useState<TCategory>("All");

    const { t } = useTranslation();

    const handleProgramChange = (program: TCategory) => {
      setSelectedProgram(program);
    };

    let accordionList: AccordionItem[] = [];

    if (selectedProgram === "All") {
      accordionList = [...items, ...mosaicItem, ...enterpriseItem];
    } else if (selectedProgram === "Achieve") {
      accordionList = items;
    } else if (selectedProgram === "Mosaic") {
      accordionList = mosaicItem;
    } else {
      accordionList = enterpriseItem;
    }

    return (
      <Section className={styles.section}>
        <Container indentStep={3}>
          <div className={styles.titleWrapper}>
            {!!title && (
              <Heading className={styles.title} tag="h1">
                {title}
              </Heading>
            )}
            {!!description && (
              <Text className={styles.description}>{description}</Text>
            )}
          </div>
          <div className={styles.programSelection}>
            <button
              onClick={() => handleProgramChange("All")}
              className={selectedProgram === "All" ? styles.selected : ""}
            >
              {t("components.seeAllFilter")}
            </button>
            <button
              onClick={() => handleProgramChange("Achieve")}
              className={selectedProgram === "Achieve" ? styles.selected : ""}
            >
              {t("components.achieveFilter")}
            </button>
            <button
              onClick={() => handleProgramChange("Mosaic")}
              className={selectedProgram === "Mosaic" ? styles.selected : ""}
            >
              {t("components.mosaicFilter")}
            </button>
            <button
              onClick={() => handleProgramChange("Enterprise Challenge")}
              className={
                selectedProgram === "Enterprise Challenge"
                  ? styles.selected
                  : ""
              }
            >
              {t("components.enterpriseFilter")}
            </button>
          </div>
          {(selectedProgram === "All" &&
            (items.length > 0 ||
              mosaicItem.length > 0 ||
              enterpriseItem.length > 0)) ||
          (selectedProgram === "Achieve" && items.length > 0) ||
          (selectedProgram === "Mosaic" && mosaicItem.length > 0) ||
          (selectedProgram === "Enterprise Challenge" &&
            enterpriseItem.length > 0) ? (
            <AccordionComponent accordionList={accordionList} />
          ) : (
            <Text>{t("components.faqNotSelected")}</Text>
          )}
        </Container>
      </Section>
    );
  }
);

export default FAQ;

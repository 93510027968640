import * as React from "react";

function SvgStairsAscend(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={8.75}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={23.3333}
        d="M102.8 12.6v24.6H70V70H37.2v32.8H4.4v32.8M102.8 4.4h32.8M135.6 4.4v131.2M4.4 135.6h131.2M102.8 37.2V4.4"
      />
    </svg>
  );
}

const MemoSvgStairsAscend = React.memo(SvgStairsAscend);
export default MemoSvgStairsAscend;

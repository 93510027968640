import axios, { AxiosRequestConfig } from "axios";

import { ApiStatusCodes } from "@app/constants/api.constants";
import { ENV } from "@app/constants/config";
import { AuthEndpointsEnum, getTokens } from "@app/features/auth/auth";
import { ResourcesEndpointsEnum } from "@app/features/resources/resources";
import { ContactEndpointsEnum } from "@app/features/contact/contact";
import {
  LocalesForAcceptLanguageHeader,
  TRANSLATION_LOCALE_KEY,
  LocalesEnum,
} from "@app/features/localization/localization";

/**
 * All the endpoint that do not require an access token
 */
const anonymousEndpoints = [
  AuthEndpointsEnum.USER.toString(),
  AuthEndpointsEnum.SIGNUP_CONFIG.toString(),
  AuthEndpointsEnum.SIGNUP_FIELDS_VALIDATE.toString(),
  AuthEndpointsEnum.AUTH_CONFIG.toString(),
  AuthEndpointsEnum.FORGOT_PASSWORD.toString(),
  AuthEndpointsEnum.RESET_PASSWORD.toString(),
  ResourcesEndpointsEnum.SUBJECTS.toString(),
  ResourcesEndpointsEnum.QUALIFICATION_TYPES.toString(),
  ResourcesEndpointsEnum.UNITS.toString(),
  ResourcesEndpointsEnum.COLLECTIONS.toString(),
  ResourcesEndpointsEnum.ACTIVITIES.toString(),
  ContactEndpointsEnum.CONTACT.toString(),
];

const detectCurrentLanguage = () => {
  if (window.location.pathname.includes("/cy")) {
    return LocalesForAcceptLanguageHeader.WELSH;
  }
  if (window.location.pathname.includes("/en")) {
    return LocalesForAcceptLanguageHeader.ENGLISH;
  }
  // detect the current locale from i18n localstorage
  if (localStorage.getItem(TRANSLATION_LOCALE_KEY)) {
    const currentLocale = localStorage.getItem(TRANSLATION_LOCALE_KEY);
    if (currentLocale === LocalesEnum.ENGLISH)
      return LocalesForAcceptLanguageHeader.ENGLISH;
    if (currentLocale === LocalesEnum.WELSH)
      return LocalesForAcceptLanguageHeader.WELSH;
  }

  return LocalesForAcceptLanguageHeader.ENGLISH;
};

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  request.headers["Accept-Language"] = detectCurrentLanguage();

  const isAnonymous = anonymousEndpoints.some(endpoint =>
    request.url?.startsWith(endpoint)
  );

  const { accessToken, tokenType } = getTokens();

  if (accessToken) {
    request.headers.Authorization = `${tokenType} ${accessToken}`;
    return request;
  }

  if (!accessToken && !isAnonymous) {
    console.error("UNAUTHORIZED");
    return Promise.reject(ApiStatusCodes.UNAUTHORIZED);
  }

  return request;
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

/** Add interceptor */
api.interceptors.request.use(authInterceptor);

import React, { memo } from "react";

/* "Note" is referenced as "Units" in Figma / system */

const SvgNote = memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 1.5H4.5C3.39543 1.5 2.5 2.39543 2.5 3.5V12.5C2.5 13.6046 3.39543 14.5 4.5 14.5H11.5C12.6046 14.5 13.5 13.6046 13.5 12.5V5.5M9.5 1.5V5.5H13.5M9.5 1.5L13.5 5.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  );
});

export default SvgNote;

import * as React from "react";

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 0C2.6865 0 0 2.6865 0 6C0 9.008 2.216 11.492 5.103 11.926V7.59H3.6185V6.013H5.103V4.9635C5.103 3.226 5.9495 2.4635 7.3935 2.4635C8.085 2.4635 8.451 2.515 8.624 2.538V3.9145H7.639C7.026 3.9145 6.812 4.496 6.812 5.151V6.013H8.6085L8.365 7.59H6.812V11.9385C9.7405 11.5415 12 9.0375 12 6C12 2.6865 9.3135 0 6 0Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgFacebook = React.memo(SvgFacebook);
export default MemoSvgFacebook;

import React, { memo, useState, useEffect, ReactNode } from "react";

import cx from "classnames";
import SwiperCore from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperProps } from "swiper/swiper-react";

import { ArrowForwardIos } from "@material-ui/icons";

import { Text } from "@app/components";
import styles from "./Slider.module.scss";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

interface SliderProps extends SwiperProps {
  nextClassName?: string;
  prevClassName?: string;
  paginationClassName?: string;
  navigationClassName?: string;
  darkMode?: boolean;
  title: string;
  progress?: number;
  onPaginationClick?: (swiper: any) => void;
  children: ReactNode;
}

const Slider = memo(
  ({
    navigation,
    pagination,
    nextClassName,
    prevClassName,
    paginationClassName,
    navigationClassName,
    darkMode = false,
    title,
    onPaginationClick,
    ...rest
  }: SliderProps) => {
    const [swiperElement, setSwiperElement] = useState<any>(); // TODO: Fix the SwiperCore types
    const [currentIndex, setCurrentIndex] = useState(1);
    const [slidesLength, setSlidesLength] = useState(0);

    useEffect(() => {
      if (swiperElement) {
        setSlidesLength(swiperElement?.slides?.length);
      }
    }, [swiperElement]);

    return (
      <div className={styles.container}>
        <Swiper
          onSlideChange={(swiper: any) => setCurrentIndex(swiper.realIndex + 1)}
          {...(!!navigation && {
            navigation: {
              ...(typeof navigation === "object" && navigation),
              disabledClass: styles.disabled,
              lockClass: styles.locked,
              prevEl: `.prev-${title}`, // Needs a CSS selector
              nextEl: `.next-${title}`, // Needs a CSS selector
            },
          })}
          {...(!!pagination && {
            pagination: {
              clickable: true,
              ...(typeof pagination === "object" && pagination),
              bulletActiveClass: styles.active,
              bulletClass: styles.bullet,
              clickableClass: styles.clickable,
              el: `.pagination-${title}`, // Needs a CSS selector
            },
          })}
          watchOverflow
          onSwiper={(swiper: any) => setSwiperElement(swiper)}
          {...rest}
        />
        {!!navigation && (
          <div className={cx(styles.navigationWrapper, navigationClassName)}>
            <button
              className={cx(
                styles.navigation,
                styles.previous,
                prevClassName,
                `prev-${title}`
              )}
              aria-label="Previous slide"
            >
              <ArrowForwardIos />
            </button>

            <button
              className={cx(
                styles.navigation,
                styles.next,
                nextClassName,
                `next-${title}`
              )}
              aria-label="Next slide"
            >
              <ArrowForwardIos />
            </button>
          </div>
        )}
        {!!pagination && (
          <div className={styles.counter}>
            <Text small>
              {currentIndex} of {slidesLength} pages
            </Text>
          </div>
        )}
        {!!pagination && (
          <div
            className={cx(
              styles.pagination,
              paginationClassName,
              `pagination-${title}`,
              {
                [styles.lightPagination]: darkMode,
              }
            )}
            {...(!!onPaginationClick && {
              onClick: () => onPaginationClick(swiperElement),
            })}
          />
        )}
      </div>
    );
  }
);

export { Slider };

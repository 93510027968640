import React, { FC, memo } from "react";
import cx from "classnames/bind";
import { FormControlLabel, Switch } from "@material-ui/core";

import styles from "../../FormTheme.module.scss";

enum ColorEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DEFAULT = "default",
}

type SwitchProps = {
  isDisabled: boolean;
  name: string;
  isChecked?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  label: string;
  color?: ColorEnum;
};

export const SwitchToggle: FC<SwitchProps> = memo(
  ({
    name,
    isDisabled,
    isChecked,
    onChange,
    label,
    color = ColorEnum.PRIMARY,
  }) => {
    const bindStyles = cx.bind(styles);

    return (
      <FormControlLabel
        disabled={isDisabled}
        control={
          <Switch
            name={name}
            checked={isChecked}
            onChange={onChange}
            color={color}
          />
        }
        label={label}
        className={bindStyles("field", "switch")}
      />
    );
  }
);

export default SwitchToggle;

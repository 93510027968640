import React, { FC, memo } from "react";
import { Link, Button } from "@app/components";
import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalization,
  LocalesEnum,
  setLocale,
} from "@app/features/localization/localization";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-use";
import { RootState } from "@app/redux/rootReducer";
import { PiGlobeBold } from "react-icons/pi";
import styles from "./NavigationButtons.module.scss";

const NavigationButtons: FC = memo(() => {
  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();

  const dispatch = useDispatch();

  const { locale: currentLocale } = useSelector(
    (state: RootState) => state.localization
  );

  const languageSwitch = (locale: string) => {
    dispatch(setLocale(locale));
    dispatch(getLocalization(locale));
    history.push({
      pathname: `/${locale.substring(0, 2)}${location.pathname?.substring(3)}`,
      search: location.search,
    });
  };

  return (
    <div className={styles.navButtons}>
      <PiGlobeBold className={styles.globeIcon} />
      <Button
        label={t("uiLabels.languageSwitcher")}
        outline
        small
        className={styles.langBtn}
        onClick={() =>
          languageSwitch(
            currentLocale === LocalesEnum.ENGLISH
              ? LocalesEnum.WELSH
              : LocalesEnum.ENGLISH
          )
        }
        style={{ color: "#1c1859" }}
      />

      <Link to={ContentfulPathsEnum.LOGIN} className={styles.buttonLink}>
        {t("components.navigationLoginButton")}
      </Link>

      <Button
        to={t("components.navigationSignUpButtonLink")}
        label={t("components.navigationSignUpButtonLabel")}
        small
        tabIndex={-1}
        className={styles.noWrap}
        arrow
      />
    </div>
  );
});

export default NavigationButtons;

import React, { FC, ReactNode, memo } from "react";
import classnames from "classnames/bind";
import styles from "./FullscreenLayout.module.scss";

const cx = classnames.bind(styles);

// const LogoWrapper = () => {
//   {
//     /* TODO: set logo as its own atom/molecule */
//   }
//   return (
//     <div className={styles.logo}>
//       <Link to="/">
//         <MediaWrapper src={logo} natural className={styles.logoImage} />
//       </Link>
//     </div>
//   );
// };

interface FullscreenLayoutProps {
  children: ReactNode;
}

const FullscreenLayout: FC<FullscreenLayoutProps> = memo(({ children }) => {
  return (
    <div className={cx(styles.fullscreen)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
});

export { FullscreenLayout };

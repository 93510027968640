import React, { FC, memo } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import classnames from "classnames/bind";
import { isURL } from "@app/helpers/utilHelper";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { IconExternalLink } from "@app/components/atoms/Icon/Icon";
import styles from "./Link.module.scss";
import ExternalLink from "../ExternalLink/ExternalLink";

const cx = classnames.bind(styles);

type LinkProps = {
  to: string;
  title?: string;
  className?: string;
  strong?: boolean;
  bold?: boolean;
  small?: boolean;
  showExternalIcon?: boolean;
  clickHandler?: (event: React.MouseEvent<HTMLElement>) => void;
  tabIndex?: number;
  children?: React.ReactNode; // Include children property here
};

const Link: FC<LinkProps> = memo(
  ({
    children,
    to,
    title,
    className,
    strong,
    bold,
    small,
    tabIndex,
    showExternalIcon,
    clickHandler,
  }) => {
    const location = useLocation();

    const { locale } = useSelector((state: RootState) => state.localization);

    const currentLocale = locale.substring(0, 2);

    const classes = cx(
      styles.link,
      {
        strong,
        bold,
        small,
      },
      className
    );

    return isURL(to) ? (
      <ExternalLink className={classes} url={to}>
        {children}
        {showExternalIcon && (
          <IconExternalLink className={styles.navItemIcon} />
        )}
      </ExternalLink>
    ) : (
      <RouterLink
        to={{
          pathname: `/${currentLocale}${to}`,
          state: { prevPath: location.pathname },
        }}
        className={classes}
        title={title}
        onClick={clickHandler}
        tabIndex={tabIndex}
      >
        {children}
      </RouterLink>
    );
  }
);

export { Link };

import React, { memo, useCallback } from "react";
import { Heading, Text, Tag } from "@app/components";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { CardFieldsDef } from "@app/types/contentfulTypes";
import { useTranslation } from "react-i18next";
import styles from "./CardGridContent.module.scss";

interface CardGridContentProps extends CardFieldsDef {
  cardType?: ResourceTypeEnum;
  tagIcon: JSX.Element | undefined;
  onlyTitle?: boolean;
  unitTypeText?: string;
}

const CardGridContent = ({
  cardType,
  tagIcon,
  title,
  intro,
  onlyTitle,
  credits,
}: CardGridContentProps) => {
  const { t } = useTranslation();

  const translatedResourceLabel = useCallback(
    (card: ResourceTypeEnum) => {
      if (card === ResourceTypeEnum.UNIT) {
        return t("uiLabels.unitsFilterLabel");
      }
      if (card === ResourceTypeEnum.ACTIVITY) {
        return t("uiLabels.activitiesFilterLabel");
      }
      if (card === ResourceTypeEnum.COLLECTION) {
        return t("uiLabels.collectionsFilterLabel");
      }
      return "";
    },
    [t]
  );

  return (
    <div className={styles.content}>
      <div className={styles.info}>
        <div className={styles.tags}>
          {cardType && (
            <Tag icon={tagIcon} resource={cardType}>
              {translatedResourceLabel(cardType)}
            </Tag>
          )}
        </div>
        {!!credits && (
          <Text small className={styles.credits}>
            {credits}
          </Text>
        )}
      </div>

      {!!title && (
        <Heading size="subtitle1" className={styles.cardLink}>
          {title}
        </Heading>
      )}

      {!onlyTitle && !!intro && (
        <Text small className={styles.text} paragraph>
          {intro}
        </Text>
      )}
    </div>
  );
};

export default memo(CardGridContent);

import React, { FC, memo } from "react";
import { RightArrow } from "@app/components/atoms/Icon/Icon";
import styles from "./AssetLink.module.scss";

type AssetLinkProps = {
  asset?: any;
};
/**
 * Component to handle ASSET LINKS from rich text editor in ContentFul.
 */
export const AssetLink: FC<AssetLinkProps> = memo(({ asset }) => {
  const { data, content } = asset;
  const url = data.target?.fields?.file?.url;
  const fileName = data.target?.fields?.file?.fileName;
  return (
    <>
      <RightArrow className={styles.arrow} />
      <a
        className="asset-link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        title={fileName}
      >
        {content[0].value}
      </a>
    </>
  );
});

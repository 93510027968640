import React, { FC, memo } from "react";
import classnames from "classnames/bind";
import { Section, Container, CardList, Text, Button } from "@app/components";
import { Heading, LoadingSpinner } from "@app/components/atoms/atoms";
import styles from "./ResourcesList.module.scss";

export interface ResourcesListProps {
  title?: string;
  text?: string;
  background?: boolean;
  useSlider?: boolean;
  data?: any[];
  ctaLabel?: string;
  ctaLink?: string;
  centered?: boolean;
  classname?: string;
  loading?: boolean;
  disableBookmarks?: boolean;
}

const ResourcesList: FC<ResourcesListProps> = memo(
  ({
    title,
    background,
    useSlider,
    text,
    ctaLink,
    ctaLabel,
    centered,
    data = [],
    classname,
    loading = false,
    disableBookmarks,
  }) => {
    const cx = classnames.bind(styles);
    return (
      <Section
        className={cx(styles.section, classname)}
        background={background}
        fullWidth
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Container indentStep={3}>
              {title && (
                <div className={cx(styles.heading, { centered })}>
                  <Heading size="h2">{title}</Heading>
                  <Text>{text}</Text>
                </div>
              )}
            </Container>
            <Container className={styles.container}>
              {data?.length > 0 && (
                <CardList
                  list={data}
                  useSlider={useSlider}
                  disableBookmarks={disableBookmarks}
                />
              )}
              {!!ctaLabel && !!ctaLink && (
                <div className={styles.buttonWrapper}>
                  <Button label={ctaLabel} to={ctaLink} tabIndex={-1} />
                </div>
              )}
            </Container>
          </>
        )}
      </Section>
    );
  }
);

export default ResourcesList;

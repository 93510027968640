/* eslint-disable */
import { CardFieldsDef } from "@app/types/contentfulTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resourcesApi } from "../api/resources.api";
import { UnitsFiltersDef } from "../types/resources.types";

interface UnitsState {
  units: {
    data: CardFieldsDef[];
    meta: {
      current_page: number;
      last_page: number;
      total: number;
      per_page: number;
    };
  };
  loading: boolean;
  loadingMoreUnits: boolean;
  error: boolean;
}

const initialState: UnitsState = {
  units: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      total: 1,
      per_page: 6,
    },
  },
  loading: false,
  loadingMoreUnits: false,
  error: false,
};

export const getUnits = createAsyncThunk(
  "units/getUnits",
  async (filter: UnitsFiltersDef) => {
    try {
      const response = await resourcesApi.getAllUnits(filter);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const getUnitsAndAppend = createAsyncThunk(
  "units/getUnitsAndAppend",
  async (filter: UnitsFiltersDef) => {
    try {
      const response = await resourcesApi.getAllUnits(filter);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

const UnitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    clearUnits: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getUnits.pending, state => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getUnits.fulfilled, (state, action) => {
      state.loading = false;
      state.units.data = action.payload.data;
      state.units.meta = action.payload.meta;
      state.error = false;
    });

    builder.addCase(getUnitsAndAppend.pending, state => {
      state.loadingMoreUnits = true;
      state.error = false;
    });

    builder.addCase(getUnitsAndAppend.fulfilled, (state, action) => {
      state.loadingMoreUnits = false;
      state.units.data = [...state.units.data, ...action.payload.data];
      state.units.meta = action.payload.meta;
      state.error = false;
    });
  },
});

export const { clearUnits } = UnitsSlice.actions;

export default UnitsSlice.reducer;

import * as React from "react";

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.84672 19.0001L19.0006 5.00011"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M5 5L19 19" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}

const MemoSvgClose = React.memo(SvgClose);
export default MemoSvgClose;

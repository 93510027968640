import { useState, useEffect } from "react";

import { passwordRegex } from "@app/features/auth/auth";

const usePasswordValidation = ({ firstPassword = "", secondPassword = "" }) => {
  const [isValid, setIsValid] = useState<boolean>();
  const [passwordMatch, setPasswordMatch] = useState<boolean>();

  useEffect(() => {
    setIsValid(passwordRegex.test(firstPassword));
    setPasswordMatch(!!firstPassword && firstPassword === secondPassword);
  }, [firstPassword, secondPassword]);

  return [isValid, passwordMatch];
};

export default usePasswordValidation;

import React, { FC, memo, ReactNode } from "react";
import classnames from "classnames/bind";
import logo from "@app/assets/images/logo.png";
import { MediaWrapper, Link } from "@app/components";
import styles from "./SplitColumns.module.scss";

const cx = classnames.bind(styles);

/* TODO: set logo as its own atom/molecule */
const LogoWrapper = () => {
  return (
    <div className={styles.logo}>
      <Link to="/">
        <MediaWrapper src={logo} natural className={styles.logoImage} />
      </Link>
    </div>
  );
};

/**
 * SPLIT COLUMNS
 */

interface SplitColumnsProps {
  children: ReactNode;
}

export const SplitColumns: FC<SplitColumnsProps> = memo(({ children }) => (
  <div className={styles.splitColumns}>{children}</div>
));

const Column: FC<{
  left?: boolean;
  right?: boolean;
  children?: ReactNode;
}> = memo(({ children, left, right }) => (
  <div className={cx({ left, right })}>{children}</div>
));

export const SplitLeft: FC<{ showLogo?: boolean; children?: ReactNode }> = memo(
  ({ showLogo, children }) => (
    <Column left>
      {showLogo && <LogoWrapper />}
      {children}
    </Column>
  )
);

export const SplitRight: FC<{ children?: ReactNode }> = memo(({ children }) => (
  <Column right>{children}</Column>
));

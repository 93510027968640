import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { ResourcesEndpointsEnum } from "../constants/resources.endpoints";
import {
  UnitsFiltersDef,
  ActivitiesFiltersDef,
} from "../types/resources.types";

const getAllSubjects = (): Promise<AxiosResponse> => {
  return api.get(ResourcesEndpointsEnum.SUBJECTS);
};

const getAllUnits = (filter?: UnitsFiltersDef): Promise<AxiosResponse> => {
  return api.get(ResourcesEndpointsEnum.UNITS, { params: filter });
};

const getAllCollections = (
  filter?: UnitsFiltersDef
): Promise<AxiosResponse> => {
  return api.get(ResourcesEndpointsEnum.COLLECTIONS, { params: filter });
};

const getUnitById = (id: string): Promise<AxiosResponse> => {
  const url = `${ResourcesEndpointsEnum.UNITS}/${id}`;
  return api.get(url);
};

const getAllActivities = (
  filter?: ActivitiesFiltersDef
): Promise<AxiosResponse> => {
  return api.get(ResourcesEndpointsEnum.ACTIVITIES, { params: filter });
};

const getActivityById = (id: string): Promise<AxiosResponse> => {
  const url = `${ResourcesEndpointsEnum.ACTIVITIES}/${id}`;
  return api.get(url);
};

const getQualificationTypes = (): Promise<AxiosResponse> => {
  return api.get(ResourcesEndpointsEnum.QUALIFICATION_TYPES);
};

export const resourcesApi = {
  getAllSubjects,
  getAllUnits,
  getUnitById,
  getAllActivities,
  getActivityById,
  getQualificationTypes,
  getAllCollections,
};

import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTokens, logout } from "@app/features/auth/auth";
import { useHistory, useLocation } from "react-router-dom";
import { Heading, Text, Modal, Button } from "@app/components/atoms/atoms";
import { RootState } from "@app/redux/rootReducer";
import qs from "query-string";
import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { useTranslation } from "react-i18next";
import styles from "./SessionExpired.module.scss";

const SessionExpired = () => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  const { isTokenExpired } = getTokens();

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const dispatch = useDispatch();

  const location = useLocation();

  const logOutAfterSessionExpire = async () => {
    try {
      await dispatch(logout());
      history.replace({
        pathname: "/",
        search: qs.stringify({ session: "expired" }),
      });
    } catch {
      console.log("error");
    }
  };

  const query = qs.parse(location.search);

  useEffect(() => {
    if (isTokenExpired === null && isAuthenticated) {
      logOutAfterSessionExpire();
    }

    if (query.session === "expired") {
      setShowModal(true);
      console.log(query.session);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onClear = () => {
    setShowModal(false);
    history.replace("/");
  };

  return (
    <Modal open={showModal} onClose={onClear}>
      <div className={styles.container}>
        <Heading className={styles.title} size="subtitle1">
          {t("sessionExpired.sessionExpiredPopupTitle")}
        </Heading>
        <Text small className={styles.text}>
          {t("sessionExpired.sessionExpiredPopupText")}
        </Text>

        <Button
          to={ContentfulPathsEnum.LOGIN}
          label={t("sessionExpired.sessionExpiredPopupLoginButton")}
          primary
          small
        />
        <Button
          onClick={onClear}
          label={t("sessionExpired.sessionExpiredPopupCloseButton")}
          inverted
          removeBorder
          small
        />
      </div>
    </Modal>
  );
};

export default memo(SessionExpired);

import { useSingleEntry } from "@app/features/contentful/contentful";
// import { useEffect, useRef, useState } from "react";

const dummyUnits = [
  {
    id: 10,
    title: "All units",
  },
  {
    id: 20,
    title: "Additional units",
  },
  {
    id: 30,
    title: "Qualification units",
  },
  {
    id: 40,
    title: "Activities",
  },
];
/**
 * @TEMP implementation while middleware ot present
 * TODO: replace with middleware
 */

export type FilterDef = {
  //   params: {
  entryId: string;
  subjectId?: string;
  unitId?: string | number;
  // api endpoint url?
  // token
  //   };
};
const useFetchByFilters = (params: FilterDef) => {
  /** WIP
     * trying to prevent multiple renderings

     const cacheParams = useRef<FilterDef>();
     const [doUpdate, setDoUpdate] = useState(true);
     
  useEffect(() => {
    const cache = cacheParams.current;

    if (
      cache?.subjectId !== params.subjectId ||
      cache?.unitId !== params.unitId
    ) {
      console.log("update");
      setDoUpdate(true);
    } else {
      console.log("no update");
      setDoUpdate(false);
    }
    cacheParams.current = params;
  }, [params]);
  */

  // DUMMY - Get subjects for filters in contentful
  const { entry } = useSingleEntry(params.entryId);
  // DUMMY - get cards in contentful
  const { entry: category } = useSingleEntry("44M8tIylZW8tsiou3Sqtne");

  const subjectsForFiltering: any[] | undefined = entry?.subjectsForFiltering;

  return {
    subjectsForFiltering,
    unitsForFiltering: dummyUnits,
    cardData: category?.list,
  };
};

export default useFetchByFilters;

import React, { FC, memo, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Heading, CardList, Button } from "@app/components";
import { Grid, useMediaQuery, Button as MButton } from "@material-ui/core";
import { SubjectsListDef } from "@app/features/resources/resources";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { mapAPIListToContentful } from "@app/helpers/utilHelper";
import EmptyStateComponent from "@app/components/molecules/EmptyStateComponent/EmptyStateComponent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { LoadingSpinner } from "@app/components/atoms/atoms";
import { ResourcesSorting, ResourcesView } from "@app/types/layoutDef";
import { IconRibbon } from "@app/components/atoms/Icon/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import VoiceAlert from "@app/components/atoms/VoiceAlert/VoiceAlert";
import TypesFilters from "../TypesFilters/TypesFilters";
import UnitsFilters from "../UnitsFilters/UnitsFilters";
import SubjectsFilters from "../SubjectsFilters/SubjectsFilters";
import styles from "./ResourcesList.module.scss";

export interface MobileFilters {
  subject: string;
  subjectId: string;
  type: string;
  view: ResourcesView;
  contentTypeId: string;
  sorting: string;
}

export interface ResourcesListProps {
  title: string;
  selectedResourceType: string;
  onResourceTypeChange: (value: string) => void;
  onLoadMore?: () => void;
  view?: ResourcesView;
  onViewChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  sorting?: ResourcesSorting;
  onSortingChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectedContentType: string;
  onContentTypeChange: (value: string) => void;
  subjects?: SubjectsListDef[];
  selectedSubjectId: string;
  onSubjectChange: (
    index: number,
    id: string,
    name: string,
    type: string,
    resourcesView: ResourcesView
  ) => void;
  onClearSubject?: () => void;
  onClearQualifications?: () => void;
  onMobileFilterChange: (query: MobileFilters) => void;
  showFilters: boolean;
  additionalFilters: boolean;
}

const ResourcesList: FC<ResourcesListProps> = memo(
  ({
    title,
    selectedResourceType,
    onResourceTypeChange,
    onLoadMore,
    view,
    onViewChange,
    subjects,
    selectedSubjectId,
    onSubjectChange,
    selectedContentType,
    onContentTypeChange,
    sorting,
    onSortingChange,
    onClearSubject,
    onClearQualifications,
    onMobileFilterChange,
    showFilters,
    additionalFilters,
  }) => {
    const { t } = useTranslation();

    const location = useLocation();

    const searchQuery = qs.parse(location.search);

    const mobileQuery = useRef<MobileFilters>({
      subject: (searchQuery.subject as string) || "",
      subjectId: "",
      type: (searchQuery.type as string) || "unit",
      view: (searchQuery.view as ResourcesView) || "grid",
      contentTypeId: (searchQuery.contentTypeId as string) || "",
      sorting: (searchQuery.sorting as string) || "dateAsc",
    });

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [voiceAlert, setVoiceAlert] = useState(false);

    const [subjectIdMobile, setSubjectIdMobile] = useState(selectedSubjectId);
    const [resourceTypeMobile, setResourceTypeMobile] =
      useState(selectedResourceType);
    const [contentTypeMobile, setContentTypeMobile] =
      useState(selectedContentType);

    useEffect(() => {
      setVoiceAlert(true);
    }, [onMobileFilterChange]);

    const closeFilters = () => {
      setFiltersOpen(false);
    };

    const doneFilters = () => {
      closeFilters();
      onMobileFilterChange(mobileQuery.current);
    };

    const openFilters = () => {
      setFiltersOpen(true);
    };

    const theme = useTheme();

    const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const { units, loading, loadingMoreUnits } = useSelector(
      (state: RootState) => state.units
    );

    const { collections, loadingMoreCollections, loadingCollections } =
      useSelector((state: RootState) => state.collections);

    const { activities, loadingActivities, loadingMoreActivities } =
      useSelector((state: RootState) => state.activities);

    const hasMoreUnits =
      units.meta && units.meta.current_page < units.meta.last_page;

    const hasMoreActivities =
      activities.meta &&
      activities.meta.current_page < activities.meta.last_page;

    const hasMoreCollections =
      collections.meta &&
      collections.meta.current_page < collections.meta.last_page;

    const renderLoadMoreBtn = (
      <div className={styles.loadmoreButton}>
        <Button
          loading={
            loadingMoreActivities || loadingMoreUnits || loadingMoreCollections
          }
          label={t("uiLabels.loadMoreResources")}
          onClick={onLoadMore}
        />
      </div>
    );

    const titleWrapper = showFilters ? (
      <div className={styles.title}>
        <IconRibbon className={styles.icon} />
        <Heading size="subtitle2">{t("uiLabels.savedResources")}</Heading>
      </div>
    ) : null;

    const filtersWrapper = showFilters ? (
      <>
        {matchesDesktop ? titleWrapper : null}
        {selectedContentType === ResourceTypeEnum.UNIT ||
        selectedContentType === ResourceTypeEnum.ACTIVITY ? (
          <SubjectsFilters
            subjects={subjects}
            selectedSubjectId={selectedSubjectId}
            onSubjectChange={onSubjectChange}
            onClearSubject={onClearSubject}
          />
        ) : null}
        {ResourceTypeEnum.UNIT ||
        selectedContentType === ResourceTypeEnum.ACTIVITY ? (
          <UnitsFilters
            onResourceTypeChange={onResourceTypeChange}
            selectedType={selectedResourceType}
            onClearQualifications={onClearQualifications}
          />
        ) : null}
        <TypesFilters
          selectedContentType={selectedContentType}
          onContentTypeChange={onContentTypeChange}
        />
      </>
    ) : null;

    const onSubjectChangeMobile = (index: number, id: string, name: string) => {
      mobileQuery.current.subject = name;
      mobileQuery.current.subjectId = id;
      setSubjectIdMobile(id);
    };

    const onClearSubjectMobile = () => {
      mobileQuery.current.subject = "all";
      mobileQuery.current.subjectId = "";
      setSubjectIdMobile("");
    };

    const onResourceTypeChangeMobile = (value: string) => {
      mobileQuery.current.type = value;
      setResourceTypeMobile(value);
    };

    const onClearQualificationsMobile = () => {
      mobileQuery.current.type = "all";
      setResourceTypeMobile("");
    };

    const onContentTypeChangeMobile = (value: string) => {
      mobileQuery.current.contentTypeId = value;
      setContentTypeMobile(value);

      // TODO: Should reset other filters values for desktop version too
      if (value === ResourceTypeEnum.COLLECTION) {
        onClearSubjectMobile();
        onClearQualificationsMobile();
      }

      if (value === ResourceTypeEnum.ACTIVITY) {
        onClearQualificationsMobile();
      }
    };

    const filtersWrapperMobile = showFilters ? (
      <>
        {matchesDesktop ? titleWrapper : null}
        {contentTypeMobile === ResourceTypeEnum.UNIT ||
        contentTypeMobile === ResourceTypeEnum.ACTIVITY ? (
          <SubjectsFilters
            subjects={subjects}
            selectedSubjectId={subjectIdMobile}
            onSubjectChange={onSubjectChangeMobile}
            onClearSubject={onClearSubjectMobile}
          />
        ) : null}
        {contentTypeMobile === ResourceTypeEnum.UNIT ? (
          <UnitsFilters
            onResourceTypeChange={onResourceTypeChangeMobile}
            selectedType={resourceTypeMobile}
            onClearQualifications={onClearQualificationsMobile}
          />
        ) : null}
        <TypesFilters
          selectedContentType={contentTypeMobile}
          onContentTypeChange={onContentTypeChangeMobile}
        />
      </>
    ) : null;

    const titleToRender = (() => {
      if (showFilters) {
        return title || t("components.resourceHubTitle");
      }
      if (additionalFilters) {
        return t("components.enterpriseResourceHubTitle");
      }
      return t("components.mosaicResourceHubTitle");
    })();

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div
            className={`${styles.wrapper} ${
              showFilters ? "" : styles.hideFilters
            }`}
          >
            <div className={styles.titleWrapper}>
              <Heading size="h2">{titleToRender}</Heading>

              {voiceAlert && <VoiceAlert />}
            </div>
            <div className={styles.filtersWrapper}>
              {!matchesDesktop ? (
                <>
                  {showFilters && (
                    <MButton
                      className={styles.filterBtn}
                      variant="outlined"
                      size="small"
                      disableRipple
                      onClick={openFilters}
                    >
                      {t("uiLabels.mobileFiltersLabel")}
                    </MButton>
                  )}
                  <Dialog
                    className={styles.dialog}
                    fullScreen
                    open={filtersOpen}
                    onClose={closeFilters}
                  >
                    <div className={styles.dialogTitle}>
                      <Heading size="subtitle1">
                        {t("uiLabels.mobileFiltersLabel")}
                      </Heading>
                    </div>
                    <DialogContent>
                      <DialogContentText>
                        <div className={styles.dialogFilters}>
                          {filtersWrapperMobile}
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        autoFocus
                        onClick={closeFilters}
                        outline
                        small
                        label={t("uiLabels.mobileCloseLabel")}
                      />
                      <Button
                        autoFocus
                        onClick={doneFilters}
                        primary
                        small
                        label="Done"
                      />
                    </DialogActions>
                  </Dialog>
                </>
              ) : null}
              <Select
                className={styles.selectWrapper}
                variant="outlined"
                id="ResourcesSortingSelect"
                value={sorting}
                onChange={onSortingChange}
              >
                <MenuItem value="dateAsc">
                  {t("uiLabels.sortingNewestToOldestLabel")}
                </MenuItem>
                <MenuItem value="dateDesc">
                  {t("uiLabels.sortingOldestToNewestLabel")}
                </MenuItem>
                <MenuItem value="titleAsc">
                  {t("uiLabels.sortingAlphabeticalAscendingLabel")}
                </MenuItem>
                <MenuItem value="titleDesc">
                  {t("uiLabels.sortingAlphabeticalDescendingLabel")}
                </MenuItem>
              </Select>
              <Select
                className={styles.selectWrapper}
                variant="outlined"
                id="ResourcesViewSelect"
                value={view}
                onChange={onViewChange}
              >
                <MenuItem value="grid">{t("uiLabels.gridViewLabel")}</MenuItem>
                <MenuItem value="list">{t("uiLabels.listViewLabel")}</MenuItem>
              </Select>
            </div>
          </div>
        </Grid>

        {matchesDesktop ? (
          <Grid item xs={12} lg={3}>
            {filtersWrapper}
          </Grid>
        ) : null}

        <Grid item xs={12} lg={!showFilters ? 12 : 9}>
          {selectedContentType === ResourceTypeEnum.UNIT ? (
            <>
              {loading && <LoadingSpinner />}
              {!loading &&
                (units?.data?.length > 0 ? (
                  <>
                    <CardList
                      listViewType={view}
                      list={mapAPIListToContentful(units.data)}
                    />
                    {hasMoreUnits && renderLoadMoreBtn}
                  </>
                ) : (
                  <EmptyStateComponent
                    title={t("components.libraryNoUnitsLabel")}
                    ctaLabel={t("components.libraryNoUnitsCtaLabel")}
                    ctaLink={t("components.libraryNoUnitsCtaLink")}
                  />
                ))}
            </>
          ) : null}
          {selectedContentType === ResourceTypeEnum.ACTIVITY ? (
            <>
              {loadingActivities && <LoadingSpinner />}
              {!loadingActivities &&
                (activities?.data?.length > 0 ? (
                  <>
                    <CardList
                      listViewType={view}
                      list={mapAPIListToContentful(activities.data)}
                    />
                    {hasMoreActivities && renderLoadMoreBtn}
                  </>
                ) : (
                  <EmptyStateComponent
                    title={t("components.libraryNoActivitiesLabel")}
                    ctaLabel={t("components.libraryNoActivitiesCtaLabel")}
                    ctaLink={t("components.libraryNoActivitiesCtaLink")}
                  />
                ))}
            </>
          ) : null}

          {selectedContentType === ResourceTypeEnum.COLLECTION ? (
            <>
              {loadingCollections && <LoadingSpinner />}
              {!loadingCollections &&
                (collections?.data?.length > 0 ? (
                  <>
                    <CardList
                      listViewType={view}
                      list={mapAPIListToContentful(collections.data)}
                    />
                    {hasMoreCollections && renderLoadMoreBtn}
                  </>
                ) : (
                  <EmptyStateComponent
                    title={t("components.libraryNoCollectionsLabel")}
                    ctaLabel={t("components.libraryNoCollectionsCtaLabel")}
                    ctaLink={t("components.libraryNoCollectionsCtaLink")}
                  />
                ))}
            </>
          ) : null}
        </Grid>
      </Grid>
    );
  }
);

export default ResourcesList;

import { CONTENTFUL_ROUTES } from "@app/features/contentful/contentful";
import { USER_ROUTES } from "@app/features/users/users";
import { AUTH_ROUTES } from "@app/features/auth/auth";
import { RouteItemDef } from "@app/types/routeDef";
import { RESOURCES_ROUTES } from "@app/features/resources/resources";
import { CONTACT_ROUTES } from "@app/features/contact/contact";
import NoMatch from "./components/NoMatch/NoMatch";

export const ROOT_ROUTE = "/";

const NOT_FOUND: RouteItemDef = {
  id: "not-found",
  path: "*",
  component: NoMatch,
};

export const PUBLIC_LIST = [
  ...CONTENTFUL_ROUTES,
  ...AUTH_ROUTES,
  ...RESOURCES_ROUTES,
  ...CONTACT_ROUTES,
  NOT_FOUND,
];

export const PRIVATE_LIST = [
  ...CONTENTFUL_ROUTES,
  ...RESOURCES_ROUTES,
  ...USER_ROUTES,
  ...AUTH_ROUTES,
  ...CONTACT_ROUTES,
  ...RESOURCES_ROUTES,
  NOT_FOUND,
];

import { RouteItemDef } from "@app/types/routeDef";

import { AuthPathsEnum } from "../constants/auth.paths";

import LoginScreen from "../screens/LoginScreen/LoginScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen/ForgotPasswordScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen/ResetPasswordScreen";

const LOGIN_SCREEN: RouteItemDef = {
  id: "login",
  path: AuthPathsEnum.LOGIN,
  component: LoginScreen,
};

const FORGOT_PASSWORD_SCREEN: RouteItemDef = {
  id: "forgot-password",
  path: AuthPathsEnum.FORGOT_PASSWORD,
  component: ForgotPasswordScreen,
};

const RESET_PASSWORD_SCREEN: RouteItemDef = {
  id: "reset-password",
  path: AuthPathsEnum.RESET_PASSWORD,
  component: ResetPasswordScreen,
};

const AUTH_ROUTES = [
  LOGIN_SCREEN,
  FORGOT_PASSWORD_SCREEN,
  RESET_PASSWORD_SCREEN,
];

export default AUTH_ROUTES;

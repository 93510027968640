import { useState, useEffect } from "react";
import { SignupSelectDataSetsDefs } from "@app/types/api.types";
import { signupApi } from "@app/features/Signup/signup";
// import { SelectDataSetsProps } from "../types/signup.types";

const useSignupConfig = () => {
  const [dropdownData, setDropdownData] = useState<SignupSelectDataSetsDefs>();
  const [isConfigLoading, setIsConfigLoading] = useState(false);

  const signupConfig = () => {
    setIsConfigLoading(true);
    signupApi
      .signupConfig()
      .then((result: any) => {
        result.data && setDropdownData(result.data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setIsConfigLoading(false);
      });
  };

  useEffect(() => {
    signupConfig();
  }, []);

  return { isConfigLoading, dropdownData };
};

export default useSignupConfig;

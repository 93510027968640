import React, { FC, memo } from "react";

import {
  Modal as MUIModal,
  ModalProps as MUIModalProps,
} from "@material-ui/core";
import classes from "classnames/bind";

import styles from "./Modal.module.scss";

const cx = classes.bind(styles);

export interface ModalProps extends MUIModalProps {
  centerContent?: boolean;
}

const Modal: FC<ModalProps> = memo(({ children, centerContent, ...rest }) => {
  return (
    <MUIModal {...rest}>
      <div className={cx(styles.content, { centerContent })}>{children}</div>
    </MUIModal>
  );
});

export default Modal;

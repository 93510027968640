import React, { FC, memo } from "react";

import { useTranslation } from "react-i18next";

import BottomLinkItem, {
  BottomLinkItemProps,
} from "../BottomLinkItem/BottomLinkItem";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import styles from "./BottomLinks.module.scss";

export interface BottomLinksProps {
  items: BottomLinkItemProps[];
}
const BottomLinks: FC<BottomLinksProps> = memo(({ items = [] }) => {
  const { t } = useTranslation();

  const today = new Date();

  const year = today.getFullYear();

  return (
    <div className={styles.wrapper}>
      <div className={styles.copyrights}>
        &copy; {year} {t("uiLabels.siteName")}
      </div>

      <div className={styles.bottomWrapper}>
        <ul className={styles.bottomLinks}>
          {items.length > 0 &&
            items.map(item => (
              <BottomLinkItem
                key={`${item.label}-${item.link}`}
                label={item.label}
                link={item.link}
              />
            ))}
        </ul>

        <LanguageSwitcher />
      </div>
    </div>
  );
});

export default BottomLinks;

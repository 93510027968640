import React, { FC, memo, useEffect, useState } from "react";
import qs from "query-string";
import {
  Section,
  Container,
  Heading,
  IconBox,
  ButtonNavigation,
  CardList,
} from "@app/components";
import { IconRibbon } from "@app/components/atoms/Icon/Icon";
import cx from "classnames";
import EmptyStateComponent from "@app/components/molecules/EmptyStateComponent/EmptyStateComponent";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@app/redux/store";
import {
  getBookmarks,
  clearBookmarks,
  getBookmarksAndAppend,
} from "@app/features/bookmarks/bookmarks";
import { useSelector } from "react-redux";
import { RootState } from "@app/redux/rootReducer";
import { mapAPIListToContentful } from "@app/helpers/utilHelper";
import { ResourceTypeEnum } from "@app/constants/ResourceConst";
import { Button, LoadingSpinner } from "@app/components/atoms/atoms";
import { useHistory, useLocation } from "react-router-dom";
// import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import styles from "./SavedResources.module.scss";

interface SavedResourcesProps {
  deleteResource?: (e: React.MouseEvent<HTMLElement>, id: string) => void;
  className?: string;
}

const SavedResources: FC<SavedResourcesProps> = memo(
  ({ deleteResource, className }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchQuery = qs.parse(location.search);

    const storedCustomAttributes = JSON.parse(
      localStorage.getItem("customAttributes") || "{}"
    );

    let defaultContentType = ResourceTypeEnum.UNIT;
    if (storedCustomAttributes.mosaic && !storedCustomAttributes.achieve) {
      defaultContentType = ResourceTypeEnum.ACTIVITY;
    }

    const [selectedContentType, setSelectedContentType] = useState(
      (searchQuery.type as string) ?? defaultContentType
    );

    const { bookmarks, loading, loadingMore } = useSelector(
      (state: RootState) => state.bookmarks
    );

    const hasMoreBookmarks =
      bookmarks.meta && bookmarks.meta.current_page < bookmarks.meta.last_page;

    useEffect(() => {
      dispatch(
        getBookmarks({
          contentType: selectedContentType,
        })
      );

      const query = { type: selectedContentType };

      history.replace({
        pathname: location.pathname,
        search: qs.stringify(query),
      });
    }, [dispatch, selectedContentType, history, location.pathname]);

    const toggleContentTypes = (type: string) => {
      clearBookmarks();
      setSelectedContentType(type);
    };

    const onLoadMore = () => {
      dispatch(
        getBookmarksAndAppend({
          contentType: selectedContentType,
          page: bookmarks.meta.current_page + 1,
        })
      );
    };

    let headingText = t("components.savedResourcesTitle");
    if (!storedCustomAttributes.mosaic && !storedCustomAttributes.achieve) {
      return (
        <Heading className={styles.blockedResources} size="h2">
          {t("components.blockedResources")}
        </Heading>
      );
    }
    if (storedCustomAttributes.mosaic && !storedCustomAttributes.achieve) {
      headingText = t("components.savedResourcesMosaicTitle");
    } else if (
      !storedCustomAttributes.mosaic &&
      storedCustomAttributes.achieve
    ) {
      headingText = t("components.savedResourcesAchieveTitle");
    }

    const ctaLink = storedCustomAttributes.mosaic
      ? t("components.noSavedResourcesMosaicCtaLink")
      : t("components.noSavedResourcesCtaLink");

    return (
      <Section className={cx(styles.section, className)}>
        {loading && (
          <div className={styles.loader}>
            <LoadingSpinner />
          </div>
        )}
        <Container>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>
              <IconBox
                icon={<IconRibbon />}
                boxSize={56}
                className={styles.iconBox}
              />
              <Heading size="h2">{headingText}</Heading>
            </div>
            <ButtonNavigation className={styles.subjects}>
              {!storedCustomAttributes.mosaic ||
              storedCustomAttributes.achieve ? (
                <button
                  title={t("components.dashboardUnitsLabel")}
                  onClick={() => toggleContentTypes(ResourceTypeEnum.UNIT)}
                  className={cx({
                    activeButton: selectedContentType === ResourceTypeEnum.UNIT,
                  })}
                >
                  <span>{t("components.dashboardUnitsLabel")}</span>
                </button>
              ) : null}
              <button
                title={t("components.dashboardActivitiesLabel")}
                onClick={() => toggleContentTypes(ResourceTypeEnum.ACTIVITY)}
                className={cx({
                  activeButton:
                    selectedContentType === ResourceTypeEnum.ACTIVITY,
                })}
              >
                <span>{t("components.dashboardActivitiesLabel")}</span>
              </button>
            </ButtonNavigation>
          </div>
        </Container>
        <Container>
          {bookmarks?.data?.length > 0 ? (
            <>
              <CardList
                list={mapAPIListToContentful(bookmarks.data)}
                deleteItem={deleteResource}
                listViewType="grid"
                showFourInRow
              />
              {hasMoreBookmarks && (
                <div className={styles.loadmoreButton}>
                  <Button
                    loading={loadingMore}
                    label={t("uiLabels.loadMoreResources")}
                    onClick={onLoadMore}
                  />
                </div>
              )}
            </>
          ) : (
            <EmptyStateComponent
              title={t("components.noSavedResourcesTitle", {
                resourceType: selectedContentType,
              })}
              ctaLabel={t("components.noSavedResourcesCtaLabel")}
              ctaLink={ctaLink}
            />
          )}
        </Container>
      </Section>
    );
  }
);

export default SavedResources;

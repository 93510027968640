import { useEffect, useState } from "react";
import { authApi, AuthConfigDef } from "@app/features/auth/auth";

export const useGetAuthConfig = () => {
  const [configFromService, setConfig] = useState<AuthConfigDef>();

  async function getLoginConfig() {
    const data = await authApi.getLoginConfig();
    return data;
  }

  useEffect(() => {
    getLoginConfig()
      .then(result => {
        setConfig(result.data);
      })
      .catch(error => console.log(error, error.response));
  }, []);

  return { configFromService };
};

export enum ResourcesFiltersEnum {
  ALL_UNITS = "All units",
  ADDITIONAL_UNITS = "Additional units",
  QUALIFICATION_UNITS = "Qualification units",
  ACTIVITIES = "Activities",
}

export enum ResourcesFiltersEnumIDs {
  ALL = "all",
  ADDITIONAL = "additional",
  QUALIFICATION = "qualification",
  ACTIVITIES = "activities",
}

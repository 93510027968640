export enum UserPathsEnum {
  DASHBOARD = "/dashboard",
  ACCOUNT_SETTINGS = "/account-settings",
}

export enum AccountSettingsPathsEnum {
  PERSONAL_INFO = "/personal-info",
  PASSWORD = "/password",
  EMAIL_NOTIFICATION = "/email-notification",
  PRIVACY_SETTINGS = "/privacy-settings",
}

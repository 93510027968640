import React, { memo, useMemo } from "react";
import { FormTheme } from "@app/components/molecules/FormTheme/FormTheme";
import { useAppDispatch } from "@app/redux/store";
import { updatePassword, passwordRegex } from "@app/features/auth/auth";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Grid } from "@material-ui/core";
import { Button } from "@app/components";
import {
  FORMFIELD_VARIANT,
  FORMFIELD_LABEL_PROPS,
} from "@app/constants/themeConst";
import { showNotification } from "@app/features/notification/notification";
import styles from "./Password.module.scss";

type FormPasswordDef = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const Password = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const initialValues: FormPasswordDef = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validation = useMemo(
    () =>
      yup.object({
        oldPassword: yup.string().required(t("password.oldPasswordRequired")),
        newPassword: yup
          .string()
          .required(t("password.newPasswordRequired"))
          .matches(passwordRegex, t("password.passwordRequiredFormat")),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref("newPassword")], t("password.passwordRequiredMatch"))
          .required(t("password.passwordRequiredConfirmation")),
      }),
    [t]
  );

  const handleSubmit = async (
    { oldPassword, newPassword }: FormPasswordDef,
    onSubmitProps: any
  ) => {
    const response: any = await dispatch(
      updatePassword({
        oldPassword,
        newPassword,
      })
    );
    let message = "";
    if (updatePassword.fulfilled.match(response)) {
      message = t("password.updateMessageSuccess");
      onSubmitProps.resetForm();
    }
    if (updatePassword.rejected.match(response)) {
      message = t("password.updateMessageFailed");
    }

    dispatch(
      showNotification({
        message: `${message} ${response.payload.message ?? ""}`,
      })
    );
  };

  return (
    <FormTheme>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validation}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <div>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    type="password"
                    id="oldPassword"
                    name="oldPassword"
                    label={t("password.oldPasswordLabel")}
                    placeholder={t("password.oldPasswordPlaceholder")}
                    variant={FORMFIELD_VARIANT}
                    InputLabelProps={FORMFIELD_LABEL_PROPS}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    label={t("password.newPasswordLabel")}
                    placeholder={t("password.newPasswordPlaceholder")}
                    variant={FORMFIELD_VARIANT}
                    InputLabelProps={FORMFIELD_LABEL_PROPS}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    label={t("password.confirmPasswordLabel")}
                    placeholder={t("password.confirmPasswordPlaceholder")}
                    variant={FORMFIELD_VARIANT}
                    InputLabelProps={FORMFIELD_LABEL_PROPS}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    type="submit"
                    label={t("password.buttonLabel")}
                    className={styles.button}
                    loading={isSubmitting}
                  />
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </FormTheme>
  );
});

export default Password;

import * as React from "react";

function SvgFolderEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M135.625 39.375a8.75 8.75 0 00-8.75-8.75H61.25l-10.5-14a8.75 8.75 0 00-7-3.5H13.125a8.75 8.75 0 00-8.75 8.75v96.25a8.75 8.75 0 008.75 8.75h113.75a8.75 8.75 0 008.75-8.75z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8.749995}
      />
    </svg>
  );
}

const MemoSvgFolderEmpty = React.memo(SvgFolderEmpty);
export default MemoSvgFolderEmpty;

import React, { FC, memo, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOktaAuth } from "@okta/okta-react";

import {
  useGetAuthConfig,
  OktaLoginConfigDef,
  AuthPathsEnum,
  AUTH_ACCESS_TOKEN,
} from "@app/features/auth/auth";
import { RootState } from "@app/redux/rootReducer";
import { Section } from "@app/components";
import { UserPathsEnum } from "@app/features/users/users";
import logo from "@app/assets/images/logo.png";
import useMetaTitle from "@app/hooks/useMetaTitle";

import { ContentfulPathsEnum } from "@app/constants/ContentfulPagesIDs";
import { loginOktaSuccess, loginOktaError } from "../../redux/auth.slice";
import { LoginResponseDef } from "../../types/auth-login.types";
import OktaSignInWidget from "./Components/OktaSigninWidget/OktaSigninWidget";
import styles from "./LoginScreen.module.scss";

interface CustomTokenPayload {
  ver?: number;
  jti?: string;
  iss?: string;
  aud?: string;
  iat?: number;
  exp?: number;
  cid?: string;
  uid?: string;
  scp?: string[];
  auth_time?: number;
  sub?: string;
  achieve?: boolean;
  mosaic?: boolean;
}

const LoginScreen: FC = () => {
  // useClassicEngine added as widget does not work without it; OktaLoginConfigDef does not have it in TS declarations
  const [oktaLoginConfig, setOktaLoginConfig] = useState<
    OktaLoginConfigDef & { useClassicEngine: boolean }
  >();

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation<{ prevPath: string }>();
  const { configFromService } = useGetAuthConfig();
  const { oktaAuth } = useOktaAuth();

  useMetaTitle(t("login.metaTitle"));

  useEffect(() => {
    if (configFromService) {
      const { baseUrl, clientId, issuer } = configFromService;

      setOktaLoginConfig({
        baseUrl,
        clientId,
        logo,
        logoText: "Prince Trust",
        helpLinks: {
          help: ContentfulPathsEnum.FAQ,
          forgotPassword: AuthPathsEnum.FORGOT_PASSWORD,
        },
        redirectUri: `${window.location.origin}/login/callback`,
        useClassicEngine: true,
        authParams: {
          issuer,
          pkce: false,
          responseType: ["token"],
          scopes: ["openid", "email", "profile", "customAttributes"],
        },
        language: "en",
        i18n: {
          en: {
            "primaryauth.title": t("login.title"),
            "primaryauth.username.placeholder": t("login.emailLabel"),
            "primaryauth.username.tooltip": t("login.emailLabel"),
            "error.username.required": t("login.emailErrorRequired"),
            "primaryauth.password.placeholder": t("login.passwordLabel"),
            "primaryauth.password.tooltip": t("login.passwordLabel"),
            "error.password.required": t("login.passwordErrorRequired"),
            "primaryauth.submit": t("login.buttonLoginLabel"),
            remember: t("login.rememberMeLabel"),
            needhelp: t("login.needHelp"),
            forgotpassword: t("login.forgotPassword"),
          },
        },
      });
    }
  }, [configFromService, t]);

  const onSuccess = (response: LoginResponseDef) => {
    const accessTokenString = response.accessToken.accessToken;
    const decodedToken = oktaAuth.token.decode(accessTokenString);

    const customPayload: CustomTokenPayload =
      decodedToken.payload as CustomTokenPayload;

    // Store the customAttributes in local storage

    const customAttributes = {
      achieve: customPayload.achieve,
      mosaic: customPayload.mosaic,
    };

    localStorage.setItem("customAttributes", JSON.stringify(customAttributes));

    /*
     * with @okta/okta-signin-widget ^7.. accessToken is no longer saved to LS by lib need to do manually to keep prev implementaion
     *
     */
    const storageObject = {
      accessToken: { accessToken: accessTokenString },
    };

    localStorage.setItem(AUTH_ACCESS_TOKEN, JSON.stringify(storageObject));

    dispatch(loginOktaSuccess(response, customAttributes));
  };

  const onError = (err: any) => {
    dispatch(loginOktaError(err));
  };

  return isAuthenticated ? (
    <Redirect to={location.state?.prevPath || UserPathsEnum.DASHBOARD} />
  ) : (
    <Section className={styles.section}>
      {oktaLoginConfig && (
        <OktaSignInWidget
          config={oktaLoginConfig}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
    </Section>
  );
};

export default memo(LoginScreen);

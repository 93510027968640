import * as React from "react";

function SvgRestaurantEatingSet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      width="1em"
      height="1em"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M13.125 52.5v78.75M118.107 87.5h13.143a4.375 4.375 0 004.375-4.363c.047-22.348.163-49.339-9.094-71.686a4.375 4.375 0 00-8.418 1.674V131.25M4.375 8.75v35a8.75 8.75 0 008.75 8.75h0a8.75 8.75 0 008.75-8.75v-35M96.25 48.317a41.843 41.843 0 00-56.84-4.083M35 109.182a41.872 41.872 0 0061.25 0"
          strokeWidth={8.749995}
        />
      </g>
    </svg>
  );
}

const MemoSvgRestaurantEatingSet = React.memo(SvgRestaurantEatingSet);
export default MemoSvgRestaurantEatingSet;

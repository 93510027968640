import React, { FC, memo } from "react";
import {
  Section,
  Container,
  SwiperCarousel,
  SwiperSlide,
} from "@app/components";
import { SwiperBreakpointsDef } from "@app/components/molecules/SwiperCarousel/SwiperCarousel";
import { ScreenSizes } from "@app/constants/breakpoints";
import { ReactComponent as CornerDraw } from "@app/assets/images/corner.svg";
import { ReactComponent as QuoteIcon } from "@app/assets/images/quote.svg";
import TestimonialItem, {
  TestimonialItemProps,
} from "./components/TestimonialItem/TestimonialItem";
import styles from "./Testimonials.module.scss";

export interface TestimonialsProps {
  quotes: { fields: TestimonialItemProps }[];
}

const Testimonials: FC<TestimonialsProps> = memo(({ quotes = [] }) => {
  const swiperBreakpoints: SwiperBreakpointsDef = {
    [ScreenSizes.XS]: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  return (
    <Section className={styles.section} fullWidth>
      <CornerDraw className={styles.corner} />
      <Container indentStep={3} className={styles.container}>
        {quotes.length > 0 && (
          <div className={styles.swiperWrapper}>
            <QuoteIcon className={styles.quoteIcon} />
            <SwiperCarousel
              swiperBreakpoints={swiperBreakpoints}
              enableLoop={false}
              showNavArrows={quotes.length > 1}
              showPagination={quotes.length > 1}
              spaceBetween={30}
              navBtnsClassName={styles.navBtnsWrapper}
            >
              {quotes.map(item =>
                !item.fields ? null : (
                  <SwiperSlide key={item.fields?.quote}>
                    <TestimonialItem
                      quote={item.fields?.quote}
                      subQuote={item.fields?.subQuote}
                      author={item.fields?.author}
                      occupation={item.fields?.occupation}
                      logo={item.fields?.logo}
                      image={item.fields?.image}
                    />
                  </SwiperSlide>
                )
              )}
            </SwiperCarousel>
          </div>
        )}
      </Container>
    </Section>
  );
});

export default Testimonials;

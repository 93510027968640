import React, { FC, memo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { IconExpand } from "@app/components/atoms/Icon/Icon";
import { Heading } from "@app/components";
import { RichText } from "@app/components/atoms/atoms";
import { Document } from "@contentful/rich-text-types";
import styles from "./AccordionComponent.module.scss";

export interface AccordionItem {
  fields: {
    title: string;
    richDescription?: Document;
    program?: string;
  };
  sys?: { id: string };
}
export interface AccordionComponentProps {
  accordionList: AccordionItem[];
}

const AccordionComponent: FC<AccordionComponentProps> = memo(
  ({ accordionList = [] }) => {
    return (
      <>
        {accordionList.length > 0 &&
          accordionList.map(item => (
            <Accordion
              key={item?.sys?.id}
              elevation={0}
              className={styles.wrapper}
            >
              <AccordionSummary
                expandIcon={<IconExpand className={styles.icon} />}
              >
                <Heading size="subtitle2" className={styles.title} tag="h3">
                  {item.fields.title}
                </Heading>
              </AccordionSummary>
              <AccordionDetails>
                {item?.fields?.richDescription && (
                  <RichText cfDocument={item.fields.richDescription} />
                )}
              </AccordionDetails>
            </Accordion>
          ))}
      </>
    );
  }
);

export default AccordionComponent;
